import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P86-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    titleQuestion: [
      {
        title: `2. DRAW YOUR DREAM JOB`,
      },
    ],
    // recorder: true,
    hideBtnFooter: true,
    totalInput: 1,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page86/E1/1.jpg",
          width: 950,
        },
      ],
    ],
  },
};

export default json;
