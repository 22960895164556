import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "MN3-U5-P95",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        title: "Unit 5",
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: "center",
      borderBottom: "2px solid",
      backgroundColor: "transparent",
    },
    maxLength: 1,
    // exerciseKey: 'https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page50/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative">
          <img src="https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/E1/1.jpg" style="width: 500px" />
          <div style="position: absolute;top: 2%; left: 37%;">
          <input style="border: none; border-bottom: 1px solid #000; text-align: left; width: 160px;
          background-color: transparent;" />
          </div>
        </div>
          `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "MN3-U5-P95",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        title: "Unit 6",
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: "center",
      borderBottom: "2px solid",
      backgroundColor: "transparent",
    },
    maxLength: 1,
    // exerciseKey: 'https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page50/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative">
          <img src="https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page95/E2/1.jpg" style="width: 500px" />
          <div style="position: absolute;top: 3.5%; left: 37%;">
          <input style="border: none; border-bottom: 1px solid #000; text-align: left; width: 160px;
          background-color: transparent;" />
          </div>
        </div>
          `,
        answer: [],
      },
    ],
  },
};
export default json;
