import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN3-U5-P52-E1",
    audio: "",
    video: "",
    component: D1,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_fly.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/6.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_fly.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/8.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_dance.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/9.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/11.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_sing.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/13.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_dance.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/14.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/15.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/16.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_jump.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/17.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_sing.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/19.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_dance.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/20.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/21.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/22.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_jump.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/23.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_sing.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/24.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/25.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/26.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/27.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/28.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/29.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_eat.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/30.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/31.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/32.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/33.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/34.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/35.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/36.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_eat.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/37.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/38.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/39.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/40.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/41.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/42.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/43.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 12_what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/44.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page52/E1/45.jpg",
        },
      ],
    ],
  },
};

export default json;
