import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN3-U6-P58-E1",
    audio: "https://cdn.sachso.edu.vn/mn3/Audios/Track 15.mp3",
    video:
      "https://cdn.sachso.edu.vn/mn3/Videos/Unit 6_Track 15_Sports Day!.mp4",
    component: UI,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page58/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page58/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page58/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 15.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page58/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page58/E1/5.jpg",
        },
      ],
    ],
  },
};

export default json;
