import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "MN3-U4-P96",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "",
        title: "Unit 7",
        color: "#f27523",
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: "center",
      borderBottom: "2px solid",
      backgroundColor: "transparent",
    },
    maxLength: 1,
    // exerciseKey: 'https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page50/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative">
          <img src="https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/E1/1.jpg" style="width: 500px" />
          <div style="position: absolute;top: 79%;left: 38%;">
          <input style="border: none; border-bottom: 1px solid #000; text-align: center; width:163px; height: 20px" />
          </div>
        </div>
          `,
        answer: [],
      },
    ],
  },

  2: {
    unit: "Unit 6",
    id: "MN3-U6-P96",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        title: `Unit 8`,
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: "center",
      borderBottom: "2px solid",
      backgroundColor: "transparent",
    },
    // maxLength: 1,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page50/E3/Key/answerKey.png",
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative">
        <img src="https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page96/E2/1.jpg" style="width: 700px" />
        <div style="position: absolute;top: 7%;left: 40%;">
        <input style="border: none; border-bottom: 1px solid #000; text-align: center" />
        </div>
      </div>
          `,
        answer: [],
      },
    ],
  },
};
export default json;
