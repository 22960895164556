import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P16-E1",
    audio: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4.mp3",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/9.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/11.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/13.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p17circle.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/15.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 4_p16yes.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/16.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page16/E1/17.jpg",
        },
      ],
    ],
  },
};

export default json;
