import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN-U4-P38-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: false,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page38/E1/2.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page38/E1/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page38/E1/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page38/E1/5.jpg",
        },
      ],
    ],
  },
};

export default json;
