import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB1-U6-P42-E1",
    audio:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/audio.e1.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/tieude.e1.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/lunchbox.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/sandwich.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/drink.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/banana.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/cookie.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E1/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/pear.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "SB1-U6-P42-E2",
    audio:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/audio.e2.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/tieude.e2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/lunchbox.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/sandwich.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/drink.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/banana.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/cookie.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E2/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/pear.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "SB1-U6-P42-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/1.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/pear.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/banana.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/6.jpg",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/7.jpg",
        },
      ],
      [
        // Column4
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/sandwich.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/10.jpg",
        },
      ],
      [
        // Column5
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/11.jpg",
        },
      ],
      [
        // Column6
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/13.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/drink.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/14.jpg",
        },
      ],
      [
        // Column7
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/15.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/16.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/cookie.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/17.jpg",
        },
      ],
      [
        // Column8
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/19.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/Audio/lunchbox.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page42/E3/20.jpg",
        },
      ],
    ],
  },
};

export default json;
