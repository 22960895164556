import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit Starter",
    id: "LQTAMN3-S-P4-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 2,
    questionImage: [
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/1.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 1.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/4.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/5.jpg" }],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/6.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/7.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 1_p4a.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/8.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/9.jpg",
          input: 2,
          isCorrect: true,
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 1_p4b.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page4/E1/11.jpg",
        },
      ],
    ],
  },
};

export default json;
