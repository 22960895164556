import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN3-U3-P31-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/Key/answerKey.png",
    component: DesignUnderLine,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    isAllowSubmit: false,
    totalInput: 4,
    questionImage: [
      // Row
      [
        {
          url: "img/FriendsPlus/Page31/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/3.jpg",
          input: 1,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/5.jpg",
          input: 1,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/7.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/9.jpg",
          input: 1,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/10.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E1/11.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/12.jpg",
          input: 2,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/13.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/14.jpg",
          input: 2,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/15.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/16.jpg",
          input: 2,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/17.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/18.jpg",
          input: 2,
          isCorrect: true,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/19.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E1/20.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/21.jpg",
          input: 3,
          isCorrect: true,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/22.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/23.jpg",
          input: 3,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/24.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/25.jpg",
          input: 3,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/26.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/27.jpg",
          input: 3,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/28.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E1/29.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/30.jpg",
          input: 4,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/31.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/32.jpg",
          input: 4,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/33.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/34.jpg",
          input: 4,
          isCorrect: false,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/35.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/36.jpg",
          input: 4,
          isCorrect: true,
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page31/E1/37.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E1/38.jpg",
        },
      ],
    ],
  },
};

export default json;
