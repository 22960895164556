import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN3-U5-P51-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page51/key/answerkey.png",
    component: MatchDots,
    // titleImage: "<img src='https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page19/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: `
        `,
        color: "",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "411px",
              left: "158px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "411px",
              left: "552px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "411px",
              left: "732px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "411px",
              left: "930px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "562px",
              left: "158px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "562px",
              left: "328px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "562px",
              left: "553px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "562px",
              left: "732px",
              width: 27,
              height: 27,
              borderRadius: "50%",
              border: "2px solid red",
            },
          }, //7
        ],
        answers: ["0-7", "1-4", "2-5", "3-6"],
        initialValue: [],
      },
      Layout: `
      
      <img src='https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page51/E1/1.jpg' style="width: 100%" />
      <input id='0' type= 'boxMatch' />
      <input id='1' type= 'boxMatch' />
      <input id='2' type= 'boxMatch' />
      <input id='3' type= 'boxMatch' />

      <input id='4' type= 'boxMatch' />
      <input id='5' type= 'boxMatch' />
      <input id='6' type= 'boxMatch' />
      <input id='7' type= 'boxMatch' />  
      `,
    },
  },
};

export default json;
