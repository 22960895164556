import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P9-E1",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/1.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/2.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/3.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/4.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/5.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p8what.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/6.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p9bag.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/7.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/8.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/9.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p9bag.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E1/11.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P9-E2",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E2/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E2/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p9wherebook.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E2/4.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P9-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E3/2.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E3/3.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p9here.mp3",
          audioUrl2:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p9welcome.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E3/4.jpg" },
      ],
      [
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E3/5.jpg" },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E3/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/Audios/Track 2_p9thankyou.mp3",
        },
        { url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E3/7.jpg" },
      ],
      [{ url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page9/E3/8.jpg" }],
    ],
  },
};

export default json;
