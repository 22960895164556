import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB1-U6-P45-E1",
    audio: "",
    video: "",
    component: D1,
    titleQuestion: [
      {
        title: `DRAW AND COLOR`,
      },
    ],
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page46/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page46/E1/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page46/E1/4.jpg",
        },
      ],
    ],
  },
};

export default json;
