import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Culture 3",
    id: "SB1-C-P54-E1",
    audio:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/audio.e1.mp3",
    video: "",
    component: D1,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E1/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E1/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/tieude.e1.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E1/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E1/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/ball.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/T-shirt.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/kick.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E1/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/score a goal.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Culture 3",
    id: "SB1-C-P54-E2",
    audio:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/audio.e2.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/tieude.e2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E2/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E2/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/anh1.e2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/Audio/anh2.e2.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Culture 3",
    id: "SB1-C-P54-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page54/E3/1.jpg",
        },
      ],
    ],
  },
};

export default json;
