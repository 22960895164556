import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN3-U1-P80W",
    audio: "",
    video: "",
    component: D1,
    // recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        title: ``,
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 0,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/11.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/15.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/16.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/19.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/20.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/21.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/22.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/23.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_trains.mp3",
        }, //THO
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/24.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/25.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/26.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/27.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/28.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/29.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/30.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/31.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/32.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/33.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_trains.mp3",
        }, //THO
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/34.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_trains.mp3",
        }, //THO
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/35.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/36.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/37.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/38.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/39.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/40.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/41.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/42.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/43.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_trains.mp3",
        }, //THO
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/44.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_trains.mp3",
        }, //THO
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/45.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_trains.mp3",
        }, //THO
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/46.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/47.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_cars.mp3",
        }, //HAMA
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/48.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/49.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/50.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/51.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/52.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/53.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/54.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/55.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/56.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/57.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_cars.mp3",
        }, //HAMA
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/58.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/59.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/60.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/61.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/62.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_buses.mp3",
        }, //GAU TRUC
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/63.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/64.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/65.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/66.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/67.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_cars.mp3",
        }, //HAMA
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/68.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_planes.mp3",
        }, //VOI
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/69.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/70.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/71.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/72.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_buses.mp3",
        }, //GAUTRUC
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/73.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_buses.mp3",
        }, //GAUTRC
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/74.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/75.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/76.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/77.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/78.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_planes.mp3",
        }, //VOI
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/79.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/80.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/81.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/82.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_buses.mp3",
        }, //GAUTRUC
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/83.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/84.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/85.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/86.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/87.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/88.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_planes.mp3",
        }, //VOI
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/89.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/90.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/91.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/92.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/93.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/94.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/95.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/96.jpg",
          audioUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 18_bikes.mp3",
        }, //VIT
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/97.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/98.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/99.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/100.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/101.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/102.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/103.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/104.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/105.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/106.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/107.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/108.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/109.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page80/E1/110.jpg",
        },
      ],
    ],
  },
};
export default json;
