import PaintColorType from '../../components/ExcerciseTypes/Design/PaintColorType'
const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'LQTAMN-U1-P25-E1',
    audio: '',
    video: '',
    component: PaintColorType,
    titleQuestion: [
      {
        title: `1. COLOR YOUR FAVORITE TOY`,
      },
    ],
    recorder: true,
    isAllowSubmit: true,
    totalInput: 5,
    isHiddenCheck: true,

    questionSVG: [
      [
        {
          version: '1.0',

          viewBox: '0 0 3337.000000 1533.000000',
          style: {
            width: 900,
            height: 900,
            marginTop: 9,
            stroke: 'black',
            strokeWidth: 10,
            fill: 'transparent',
          },
          g: `<g transform="translate(0,1000) scale(0.100000,-0.100000)" style="cursor: pointer; stroke: black; stroke-width: 100px; ">
          <path id="0"  style="cursor: pointer; stroke: black; stroke-width: 50px" d="M0 7665 l0 -7665 16685 0 16685 0 0 7665 0 7665 -16685 0 -16685 0 0
          -7665z m5615 6365 c3 -5 15 -10 26 -10 30 0 118 -40 147 -67 14 -13 29 -23 34
          -23 9 0 128 -119 193 -192 62 -70 149 -187 170 -227 8 -17 19 -31 24 -31 5 0
          11 -8 14 -17 7 -23 39 -75 53 -88 7 -5 20 -27 29 -47 10 -21 21 -38 26 -38 5
          0 18 -22 30 -50 12 -27 25 -50 28 -50 4 0 21 -28 37 -62 16 -35 34 -66 40 -70
          6 -4 19 -28 28 -53 10 -25 21 -45 26 -45 4 0 18 -24 30 -52 12 -29 26 -55 29
          -58 11 -7 51 -94 51 -108 0 -7 6 -15 14 -18 8 -3 25 -34 38 -69 12 -35 26 -61
          30 -59 4 3 8 0 8 -6 0 -14 40 -103 49 -110 4 -3 18 -33 30 -67 13 -35 27 -63
          31 -63 4 0 20 -34 35 -75 15 -41 31 -75 34 -75 3 0 17 -28 31 -62 13 -35 27
          -65 31 -68 3 -3 17 -33 31 -67 14 -35 28 -60 31 -56 4 3 7 0 7 -8 0 -18 52
          -152 62 -159 4 -4 17 -33 28 -65 11 -32 24 -61 29 -65 4 -3 18 -34 30 -70 13
          -36 26 -67 30 -70 4 -3 20 -41 36 -85 15 -44 31 -82 35 -85 4 -3 17 -34 29
          -70 13 -36 26 -67 30 -70 4 -3 18 -38 31 -77 14 -40 27 -73 31 -73 3 0 16 -35
          30 -77 13 -43 27 -79 31 -81 3 -2 19 -42 34 -90 14 -48 30 -89 34 -92 4 -3 18
          -40 31 -82 12 -43 26 -78 30 -78 3 0 16 -36 29 -80 13 -44 26 -80 30 -80 4 0
          11 -12 14 -27 14 -58 50 -168 57 -173 3 -3 16 -41 28 -85 12 -44 26 -82 31
          -85 5 -3 18 -41 29 -85 12 -43 25 -81 29 -84 5 -3 21 -53 37 -111 16 -58 32
          -107 36 -110 3 -3 17 -41 29 -85 12 -44 25 -82 29 -85 4 -3 17 -45 30 -95 12
          -49 25 -92 30 -95 4 -3 16 -38 26 -78 11 -39 21 -76 25 -81 6 -10 91 8 100 21
          3 4 60 20 125 35 66 15 122 30 125 33 5 6 122 34 193 46 20 3 37 9 37 13 0 4
          53 18 118 31 64 12 119 26 122 30 3 4 70 19 150 35 80 16 147 31 150 35 3 3
          61 17 130 30 69 13 127 27 130 30 3 4 73 20 155 35 83 16 152 31 155 35 3 3
          64 17 135 29 72 13 132 27 135 31 3 4 66 17 140 30 74 12 137 26 140 30 3 3
          79 19 170 35 91 15 167 31 170 35 3 4 70 17 150 30 80 13 147 26 150 30 3 4
          75 17 160 30 85 13 157 27 160 30 3 4 95 20 205 36 110 15 202 31 205 34 6 8
          319 50 367 50 17 0 33 4 35 9 5 13 335 41 491 41 121 0 136 -2 158 -21 22 -19
          24 -28 24 -115 0 -54 -5 -98 -11 -102 -5 -4 -18 -29 -28 -55 -10 -27 -24 -51
          -31 -53 -7 -3 -16 -13 -19 -22 -4 -9 -12 -26 -19 -37 -16 -26 -206 -215 -216
          -215 -4 0 -16 -9 -26 -20 -10 -11 -24 -20 -32 -20 -7 0 -16 -6 -19 -14 -3 -7
          -25 -24 -50 -36 -24 -13 -44 -27 -44 -31 0 -4 -21 -17 -47 -30 -27 -12 -50
          -26 -53 -30 -3 -4 -32 -20 -65 -35 -33 -16 -66 -34 -73 -41 -7 -7 -20 -13 -27
          -13 -17 0 -89 -32 -93 -42 -2 -4 -33 -17 -70 -29 -37 -12 -69 -25 -72 -29 -6
          -8 -111 -45 -157 -55 -18 -3 -33 -10 -33 -15 0 -4 -14 -10 -32 -14 -17 -3 -29
          -8 -27 -10 6 -5 143 17 149 24 3 4 85 19 183 35 97 15 177 30 177 34 0 4 65
          18 145 31 80 13 145 27 145 30 0 4 59 17 130 30 72 13 130 27 130 31 0 3 65
          19 145 34 79 16 142 31 140 34 -2 4 47 17 108 29 61 13 116 27 122 31 5 5 53
          19 105 31 52 13 97 26 100 29 3 3 59 19 125 35 66 17 122 33 125 37 3 3 46 17
          95 29 50 13 92 27 95 30 3 4 52 20 110 35 58 16 107 32 110 36 3 4 41 17 85
          29 44 12 82 26 85 30 3 4 41 18 85 30 44 12 82 26 85 30 3 4 45 19 94 34 50
          14 92 29 95 34 3 4 42 19 86 32 44 13 82 28 85 32 3 4 37 17 75 29 39 12 72
          24 75 28 3 4 39 17 80 30 41 14 84 30 96 37 12 7 50 22 85 33 34 12 65 25 68
          29 4 4 38 18 76 31 39 14 72 28 75 31 3 4 43 20 90 35 47 15 87 31 90 36 3 4
          34 17 70 30 36 12 67 25 70 29 3 3 34 17 70 30 36 13 67 27 70 30 7 9 142 60
          158 60 7 0 11 4 8 8 -2 4 26 18 62 32 37 13 73 30 80 37 7 7 19 13 26 13 16 0
          134 49 121 50 -5 0 24 13 65 27 41 15 81 32 87 39 7 7 36 20 65 30 29 9 56 21
          59 26 3 5 36 20 74 33 38 14 72 30 75 35 3 5 33 19 65 30 32 11 61 24 65 28 8
          10 78 42 95 42 7 0 20 7 28 15 8 8 46 26 83 41 38 15 71 30 74 34 3 3 32 17
          65 30 33 13 62 27 65 31 3 4 32 18 65 30 33 12 62 26 66 30 3 5 34 21 70 35
          35 14 66 29 69 33 3 4 33 18 68 30 34 13 62 27 62 31 0 4 27 18 60 30 32 12
          60 25 62 29 2 3 36 19 76 35 39 15 72 31 72 35 0 4 29 18 64 31 35 14 62 28
          60 32 -3 5 1 8 8 8 17 0 141 52 149 62 3 5 30 18 60 29 30 12 56 24 59 28 3 4
          32 18 65 31 33 13 66 30 72 36 7 7 42 22 78 34 36 12 65 25 65 30 0 4 27 18
          60 30 33 12 60 26 60 30 0 4 29 18 65 31 36 12 65 26 65 30 0 3 33 19 74 35
          41 15 72 32 70 36 -3 4 3 8 13 8 11 0 23 4 28 9 6 5 27 14 48 21 20 6 37 15
          37 19 0 4 32 19 70 32 39 13 70 27 70 30 0 4 33 20 73 35 39 16 74 31 77 35 3
          4 30 17 60 30 30 12 57 26 60 29 3 4 35 17 72 31 36 13 65 27 62 30 -2 4 6 10
          18 14 46 13 128 48 128 54 0 4 33 17 73 31 39 13 78 30 84 36 7 7 42 22 78 34
          36 12 65 25 65 30 0 4 25 16 56 26 31 9 61 22 67 29 7 6 38 19 70 29 32 10 63
          24 70 30 7 7 44 23 82 36 38 13 90 33 115 45 25 12 66 28 93 36 26 7 47 17 47
          21 0 4 33 17 73 30 39 12 78 29 85 35 7 7 19 13 25 13 17 0 151 43 157 51 3 3
          41 17 85 29 44 13 82 27 85 31 3 4 41 17 85 29 44 12 82 26 85 30 3 4 58 20
          122 35 64 16 115 31 113 35 -2 3 44 16 101 29 57 13 104 27 104 31 0 4 54 17
          120 30 65 13 117 27 114 29 -5 5 81 23 251 52 39 7 72 16 75 20 5 7 279 49
          323 49 14 0 27 4 29 9 2 5 86 17 188 27 266 27 803 27 1020 0 85 -10 157 -22
          160 -26 3 -4 59 -17 125 -30 66 -12 122 -26 125 -30 3 -4 48 -20 100 -35 52
          -15 97 -30 100 -34 3 -5 35 -18 73 -30 37 -13 67 -26 67 -31 0 -4 27 -18 60
          -30 33 -12 60 -26 60 -31 0 -5 25 -21 55 -35 30 -15 55 -30 55 -34 0 -4 20
          -17 45 -30 24 -12 42 -26 40 -30 -2 -4 14 -17 35 -29 22 -12 40 -26 40 -30 0
          -5 19 -21 42 -36 23 -15 51 -39 64 -54 26 -31 84 -95 125 -136 16 -16 29 -34
          29 -39 0 -4 11 -17 25 -28 14 -11 25 -26 25 -33 0 -8 6 -18 13 -22 7 -4 26
          -30 42 -58 15 -27 31 -52 35 -55 4 -3 18 -25 30 -50 12 -25 25 -47 29 -50 12
          -10 41 -68 41 -83 0 -7 8 -23 18 -35 10 -11 29 -52 42 -91 13 -39 26 -71 30
          -71 4 0 17 -38 30 -85 13 -47 27 -85 31 -85 5 0 9 -8 9 -17 1 -41 14 -45 76
          -22 32 12 68 28 79 34 11 7 41 18 68 25 26 7 50 16 53 21 6 10 -23 69 -34 69
          -4 0 -18 25 -31 55 -14 30 -27 55 -30 55 -4 0 -17 21 -31 48 -14 26 -30 52
          -36 59 -7 7 -22 33 -34 59 -13 25 -33 57 -46 71 -13 14 -24 32 -24 40 0 7 -6
          16 -14 19 -7 3 -24 25 -36 50 -13 24 -27 44 -31 44 -4 0 -20 21 -35 48 -15 26
          -36 53 -46 60 -10 7 -18 18 -18 25 0 6 -11 23 -25 37 -13 14 -38 39 -55 57
          -16 17 -30 36 -30 41 0 6 -12 21 -28 34 -15 12 -38 38 -50 55 -13 18 -26 32
          -30 31 -4 -1 -41 32 -82 73 -41 41 -89 85 -107 97 -31 22 -58 44 -108 88 -13
          11 -41 29 -62 40 -20 10 -45 28 -54 39 -43 50 47 105 173 105 71 0 141 -23
          179 -59 12 -11 36 -27 53 -35 17 -8 37 -24 44 -35 7 -12 17 -21 23 -21 6 0 35
          -24 65 -52 29 -29 67 -63 84 -76 16 -13 30 -27 30 -32 0 -5 23 -32 50 -59 28
          -28 50 -54 50 -60 0 -5 10 -18 23 -28 12 -10 33 -35 46 -55 12 -21 26 -38 30
          -38 4 0 20 -25 36 -55 15 -30 32 -55 36 -55 4 0 18 -21 30 -47 13 -27 29 -54
          36 -61 8 -8 20 -30 29 -50 8 -20 18 -39 22 -42 5 -3 19 -30 31 -60 13 -30 27
          -57 32 -60 5 -3 21 -42 36 -85 15 -44 31 -82 34 -85 4 -3 17 -38 30 -77 12
          -40 27 -73 31 -73 4 0 8 -9 8 -21 0 -18 4 -20 27 -14 15 3 30 10 33 15 3 4 37
          18 75 31 39 12 72 26 75 30 3 5 35 18 70 29 35 11 67 24 70 28 3 4 46 20 95
          36 50 16 92 32 95 35 3 4 39 18 80 30 41 13 77 27 80 31 3 4 37 17 77 29 39
          12 73 25 75 29 2 4 46 20 98 36 52 15 97 31 100 35 3 4 39 18 80 31 41 13 77
          27 80 31 3 3 48 19 100 34 52 15 97 31 100 35 3 4 39 18 80 30 41 12 77 26 80
          30 3 4 39 17 80 29 41 11 76 25 78 31 2 5 9 10 15 10 17 0 181 52 187 60 3 3
          41 17 85 30 44 12 82 26 85 29 3 4 41 18 85 31 44 13 82 27 85 31 3 3 48 19
          100 34 52 16 97 32 100 36 3 4 39 16 80 28 41 12 86 28 100 35 14 7 55 21 93
          31 37 10 67 21 67 25 0 4 46 20 103 36 56 15 104 31 107 35 3 3 39 16 80 28
          41 13 77 26 80 30 3 4 43 18 90 31 47 13 87 27 90 30 3 4 50 19 105 34 55 16
          102 32 105 36 3 4 44 17 93 30 48 13 87 26 87 30 0 4 10 10 23 13 64 15 183
          50 192 56 5 5 44 18 85 31 41 12 77 25 80 29 3 5 43 18 90 31 47 12 87 25 90
          29 3 4 51 20 107 36 56 16 100 32 98 35 -2 4 35 17 83 29 48 13 89 26 92 30 6
          8 139 51 158 51 7 0 12 4 12 8 0 5 46 21 102 36 57 15 105 31 108 35 3 4 45
          18 95 30 49 12 92 26 95 30 6 10 215 51 258 51 17 0 32 5 32 10 0 12 147 14
          154 1 3 -4 24 -11 47 -15 55 -9 110 -64 128 -129 25 -87 -4 -454 -37 -477 -5
          -3 -16 -36 -26 -75 -10 -38 -24 -79 -31 -90 -6 -11 -22 -50 -36 -87 -13 -36
          -27 -64 -31 -62 -4 3 -8 -1 -8 -10 0 -17 -38 -108 -49 -116 -4 -3 -17 -30 -30
          -60 -13 -30 -28 -59 -35 -65 -6 -5 -23 -34 -36 -62 -14 -29 -28 -53 -32 -53
          -4 0 -14 -15 -22 -32 -7 -18 -25 -46 -40 -63 -14 -16 -33 -45 -41 -62 -9 -18
          -20 -33 -26 -33 -5 0 -13 -9 -17 -19 -7 -19 -44 -68 -88 -116 -12 -13 -31 -36
          -42 -52 -22 -31 -209 -218 -258 -258 -18 -14 -37 -25 -43 -25 -6 0 -11 -4 -11
          -10 0 -5 -12 -16 -27 -24 -16 -8 -39 -25 -53 -37 l-25 -24 33 -30 c56 -50 255
          -252 282 -285 14 -17 37 -45 52 -61 14 -16 36 -45 48 -64 12 -19 25 -35 28
          -35 4 0 18 -23 31 -50 14 -28 28 -50 33 -50 4 0 17 -19 29 -42 12 -24 25 -45
          30 -48 4 -3 20 -33 35 -65 14 -33 29 -62 33 -65 4 -3 18 -30 30 -60 13 -30 27
          -57 31 -60 3 -3 17 -36 31 -75 13 -38 26 -72 30 -75 6 -5 42 -120 55 -175 4
          -16 11 -32 15 -35 8 -6 49 -198 49 -231 0 -10 4 -19 9 -19 34 0 51 -660 22
          -862 -7 -54 -17 -98 -22 -98 -5 0 -9 -9 -9 -21 0 -28 -42 -213 -50 -219 -4 -3
          -19 -45 -35 -95 -16 -49 -32 -92 -36 -95 -3 -3 -14 -21 -24 -40 -23 -46 -73
          -90 -102 -90 -13 0 -23 4 -23 10 0 15 23 60 30 60 4 0 10 10 13 23 8 36 41
          121 52 133 6 6 19 44 29 85 11 41 23 76 27 79 12 8 49 226 56 328 3 45 10 82
          14 82 5 0 9 74 9 165 0 91 -4 165 -9 165 -4 0 -11 37 -14 83 -7 100 -46 350
          -56 357 -4 3 -18 46 -31 95 -12 50 -26 92 -30 95 -4 3 -17 39 -29 80 -13 41
          -26 77 -30 80 -4 3 -20 39 -36 80 -16 41 -31 77 -35 80 -4 3 -18 29 -30 58
          -12 28 -26 52 -30 52 -3 0 -17 23 -30 50 -13 28 -27 50 -31 50 -4 0 -16 18
          -28 41 -12 22 -30 50 -41 61 -11 12 -20 27 -20 32 0 10 -27 44 -88 112 -11 12
          -28 35 -40 51 -24 33 -145 157 -223 226 -88 79 -93 83 -124 103 -16 10 -34 24
          -38 31 -10 17 -52 17 -69 0 -7 -7 -48 -24 -90 -37 -43 -13 -78 -27 -78 -30 0
          -4 -34 -17 -75 -30 -83 -26 -94 -43 -41 -65 19 -8 40 -20 48 -28 7 -7 31 -24
          53 -37 22 -13 49 -32 59 -42 11 -10 25 -18 30 -18 10 0 44 -27 112 -88 12 -11
          36 -29 53 -41 74 -53 280 -279 326 -358 11 -18 23 -33 26 -33 4 0 18 -21 31
          -47 13 -27 28 -51 34 -55 6 -4 19 -28 28 -53 10 -25 21 -45 26 -45 4 0 21 -40
          36 -89 16 -49 33 -87 38 -84 5 3 7 0 4 -8 -3 -8 -11 -15 -20 -17 -10 -2 -20
          14 -32 53 -10 30 -21 55 -25 55 -4 0 -15 25 -25 55 -9 30 -23 60 -29 67 -7 7
          -23 34 -36 61 -12 26 -26 47 -30 47 -4 0 -19 23 -34 50 -15 28 -35 59 -45 69
          -9 10 -33 38 -52 61 -56 66 -178 190 -189 190 -5 0 -21 14 -34 30 -14 17 -28
          30 -32 30 -8 0 -62 39 -93 68 -14 12 -31 22 -38 22 -7 0 -17 6 -21 14 -4 7
          -34 26 -65 40 -32 15 -58 31 -58 36 0 4 -28 18 -62 31 -35 13 -69 29 -76 36
          -17 17 -47 17 -64 0 -7 -7 -39 -24 -70 -37 -32 -13 -58 -27 -58 -31 0 -4 -29
          -19 -65 -34 -36 -16 -65 -31 -65 -35 0 -4 -9 -10 -20 -13 -33 -8 -23 -24 20
          -31 22 -3 40 -11 40 -16 0 -6 6 -10 14 -10 21 0 139 -39 146 -48 3 -5 33 -19
          68 -32 35 -13 69 -28 75 -34 7 -6 35 -22 62 -35 28 -13 54 -30 58 -38 4 -7 14
          -13 21 -13 7 0 24 -11 38 -24 14 -12 32 -26 39 -30 36 -18 62 -35 79 -52 10
          -11 40 -37 65 -59 26 -22 67 -60 91 -85 24 -25 57 -59 74 -75 16 -17 30 -35
          30 -42 0 -6 8 -18 18 -25 18 -14 50 -62 59 -90 3 -10 9 -18 15 -18 5 0 18 -21
          29 -47 11 -26 24 -50 28 -53 11 -7 61 -134 61 -154 0 -9 4 -16 9 -16 20 0 45
          -185 44 -330 0 -143 -23 -301 -44 -308 -18 -7 -9 -44 16 -64 14 -11 25 -26 25
          -34 0 -8 7 -17 15 -21 9 -3 25 -23 36 -44 11 -22 23 -39 27 -39 4 0 20 -28 36
          -62 16 -35 32 -65 36 -68 12 -10 30 -87 30 -128 0 -23 -6 -54 -13 -69 -20 -44
          -79 -99 -126 -117 -69 -26 -74 -37 -40 -74 17 -18 33 -32 37 -32 11 0 252
          -240 252 -250 0 -5 10 -17 23 -27 12 -10 36 -38 52 -63 17 -25 33 -46 37 -48
          4 -2 17 -21 29 -42 30 -52 44 -56 64 -14 9 19 19 34 24 34 4 0 21 28 37 63 16
          34 32 64 35 67 10 8 49 101 49 116 0 8 4 14 9 14 5 0 19 33 32 72 12 40 25 75
          29 78 8 5 50 169 50 194 0 9 4 16 8 16 9 0 24 84 44 240 21 164 15 555 -11
          780 -13 107 -27 197 -32 198 -5 2 -9 12 -9 23 0 29 -42 233 -50 239 -3 3 -16
          50 -29 105 -12 55 -26 104 -31 110 -5 6 -19 50 -31 98 -12 48 -25 87 -30 87
          -5 0 -9 5 -9 10 0 6 6 10 13 10 23 0 97 -94 97 -124 0 -8 6 -21 14 -28 7 -8
          24 -43 37 -79 12 -35 26 -66 30 -69 8 -6 59 -179 59 -202 0 -9 4 -18 10 -20 5
          -1 20 -52 32 -113 12 -60 25 -112 29 -115 4 -3 14 -61 24 -130 22 -163 31
          -553 16 -702 -17 -164 -33 -268 -43 -268 -4 0 -8 -7 -8 -15 0 -31 -44 -195
          -52 -195 -4 0 -8 -6 -8 -13 0 -20 -50 -171 -58 -177 -5 -3 -19 -33 -31 -67
          -13 -35 -27 -63 -31 -63 -3 0 -17 -24 -31 -52 -13 -29 -29 -59 -36 -66 -7 -7
          -20 -28 -29 -47 -9 -19 -20 -35 -25 -35 -5 0 -9 -5 -9 -10 0 -6 -12 -28 -26
          -50 -25 -38 -26 -41 -11 -72 8 -18 20 -50 27 -71 6 -20 15 -37 20 -37 4 0 18
          -36 31 -80 12 -44 26 -80 30 -80 4 0 15 -44 26 -97 26 -141 26 -436 -1 -548
          -11 -44 -23 -82 -26 -85 -4 -3 -18 -35 -30 -72 -12 -38 -26 -68 -29 -68 -4 0
          -15 -23 -26 -51 -11 -28 -27 -58 -35 -68 -8 -9 -21 -27 -27 -39 -7 -12 -19
          -32 -25 -44 -7 -12 -23 -31 -35 -41 -13 -10 -23 -23 -23 -29 0 -13 -4 -17
          -158 -173 -65 -66 -138 -136 -161 -155 -24 -19 -51 -42 -60 -50 -25 -22 -79
          -60 -85 -60 -3 0 -16 -10 -30 -22 -54 -49 -81 -69 -125 -91 -25 -12 -56 -32
          -69 -45 -14 -12 -31 -22 -38 -22 -7 0 -17 -6 -21 -13 -4 -8 -30 -25 -58 -38
          -27 -13 -58 -31 -67 -39 -9 -8 -39 -24 -65 -34 -26 -11 -56 -27 -66 -36 -10
          -10 -35 -23 -57 -30 -22 -7 -40 -16 -40 -20 0 -4 -30 -20 -67 -35 -38 -16 -70
          -32 -73 -36 -3 -3 -30 -17 -60 -30 -30 -12 -57 -26 -60 -29 -3 -3 -32 -17 -65
          -30 -33 -13 -66 -30 -73 -37 -7 -7 -19 -13 -25 -13 -14 0 -120 -42 -127 -51
          -3 -3 -36 -16 -75 -30 -38 -13 -75 -28 -81 -34 -6 -6 -43 -19 -82 -29 -40 -10
          -70 -21 -68 -25 3 -4 -43 -20 -102 -36 -59 -16 -109 -32 -112 -36 -3 -5 -46
          -18 -95 -30 -50 -13 -92 -26 -95 -30 -3 -4 -48 -18 -100 -30 -52 -12 -97 -26
          -100 -29 -3 -4 -61 -20 -130 -35 -69 -15 -127 -31 -130 -35 -3 -4 -57 -18
          -120 -30 -63 -13 -122 -27 -130 -31 -17 -10 -269 -59 -300 -59 -11 0 -20 -4
          -20 -8 0 -5 -69 -19 -152 -32 -84 -13 -155 -27 -158 -31 -5 -7 -289 -49 -332
          -49 -14 0 -28 -4 -30 -9 -4 -10 -235 -37 -458 -53 -85 -7 -158 -17 -162 -22
          -10 -16 -880 -15 -899 0 -8 6 -51 14 -97 18 -100 8 -323 45 -337 56 -5 4 -50
          18 -100 30 -49 12 -92 26 -95 30 -3 4 -40 18 -82 31 -43 12 -75 25 -73 28 3 3
          -34 17 -81 33 -48 15 -90 31 -93 36 -3 5 -36 20 -73 33 -37 13 -65 25 -62 28
          2 2 -31 18 -73 34 -43 17 -80 33 -83 37 -3 3 -32 17 -65 30 -33 13 -62 27 -65
          30 -7 10 -47 30 -58 30 -5 0 -18 -39 -28 -87 -10 -49 -21 -90 -25 -93 -4 -3
          -19 -57 -34 -120 -15 -63 -31 -117 -36 -120 -4 -3 -11 -19 -14 -35 -12 -49
          -38 -130 -44 -135 -4 -3 -17 -36 -30 -75 -13 -38 -26 -72 -30 -75 -4 -3 -20
          -38 -35 -78 -16 -39 -32 -72 -35 -72 -4 0 -17 -23 -31 -52 -14 -29 -35 -63
          -47 -76 -13 -14 -23 -31 -23 -38 0 -7 -9 -18 -20 -24 -11 -6 -20 -17 -20 -24
          0 -18 -197 -219 -238 -242 -17 -10 -32 -22 -32 -25 0 -4 -21 -18 -47 -31 -27
          -13 -50 -26 -53 -30 -8 -10 -99 -48 -115 -48 -7 0 -15 -4 -17 -10 -5 -16 -115
          -32 -218 -33 -89 0 -209 17 -220 32 -3 4 -33 18 -67 31 -35 14 -63 28 -63 32
          0 3 -17 17 -37 29 -43 27 -163 143 -163 158 0 6 -11 18 -25 27 -14 9 -25 22
          -25 28 0 6 -14 27 -31 46 -17 18 -40 51 -51 72 -11 21 -23 38 -27 38 -4 0 -18
          23 -31 50 -13 28 -26 50 -30 50 -3 0 -19 29 -35 65 -16 36 -32 65 -35 65 -4 0
          -17 32 -30 70 -13 39 -26 70 -30 70 -4 0 -17 33 -30 73 -12 39 -26 74 -29 77
          -8 6 -51 152 -51 173 0 9 -4 17 -10 19 -5 2 -21 64 -35 138 -15 74 -31 144
          -36 155 -10 23 -49 310 -49 365 0 21 -5 40 -12 42 -9 3 -13 95 -16 354 -2 262
          -6 349 -15 347 -7 -1 -72 -7 -144 -13 -73 -7 -133 -16 -133 -20 0 -4 -36 -11
          -80 -14 -44 -4 -80 -10 -80 -12 0 -11 22 -45 45 -70 13 -14 27 -35 30 -45 4
          -11 10 -19 15 -19 5 0 19 -21 32 -47 13 -27 28 -52 35 -58 6 -5 21 -31 33 -57
          12 -27 26 -48 30 -48 4 0 15 -25 25 -55 9 -31 22 -61 29 -68 7 -7 23 -43 36
          -82 14 -38 28 -73 33 -76 4 -3 19 -48 34 -100 15 -52 30 -96 34 -99 4 -3 18
          -50 30 -105 13 -55 26 -102 29 -105 8 -6 50 -216 50 -248 0 -13 4 -21 8 -18 9
          6 48 -292 58 -444 4 -52 11 -101 17 -108 16 -20 14 -735 -1 -740 -7 -2 -12
          -24 -12 -50 -1 -93 -48 -483 -60 -492 -3 -3 -16 -63 -29 -135 -12 -71 -26
          -132 -30 -135 -4 -3 -18 -51 -31 -107 -13 -57 -27 -103 -31 -103 -4 0 -10 -15
          -14 -32 -11 -55 -47 -173 -54 -178 -3 -3 -17 -36 -30 -75 -13 -38 -27 -72 -31
          -75 -4 -3 -17 -34 -30 -70 -13 -36 -26 -67 -31 -70 -4 -3 -19 -36 -33 -74 -15
          -38 -32 -74 -39 -80 -7 -6 -22 -31 -33 -56 -10 -25 -27 -54 -36 -64 -10 -11
          -18 -26 -18 -33 0 -7 -8 -21 -17 -31 -10 -10 -27 -36 -38 -57 -11 -21 -25 -43
          -31 -49 -6 -7 -23 -31 -38 -54 -29 -47 -48 -71 -88 -115 -16 -17 -28 -33 -28
          -36 -1 -25 -346 -381 -369 -381 -5 0 -18 -10 -28 -23 -10 -13 -38 -37 -63 -54
          -25 -17 -54 -40 -64 -52 -11 -11 -25 -21 -32 -21 -7 0 -18 -9 -24 -20 -6 -11
          -16 -20 -23 -20 -7 0 -27 -14 -45 -30 -19 -16 -53 -39 -78 -50 -24 -12 -44
          -25 -44 -29 0 -4 -22 -18 -50 -31 -27 -13 -50 -27 -50 -31 0 -4 -17 -14 -39
          -22 -21 -9 -45 -22 -52 -29 -8 -8 -45 -27 -84 -43 -38 -16 -72 -33 -75 -37 -3
          -3 -28 -16 -55 -28 -27 -12 -52 -24 -55 -28 -3 -4 -43 -20 -90 -36 -47 -16
          -87 -32 -90 -35 -3 -4 -41 -18 -85 -31 -44 -13 -82 -28 -85 -32 -3 -4 -51 -17
          -105 -29 -55 -12 -102 -25 -105 -29 -3 -5 -79 -20 -170 -35 -91 -15 -174 -31
          -185 -36 -41 -17 -268 -32 -505 -33 -245 0 -512 18 -518 35 -2 5 -14 9 -27 9
          -36 0 -299 50 -305 58 -3 4 -48 18 -100 31 -52 13 -97 27 -100 31 -3 4 -36 17
          -75 31 -38 13 -72 27 -75 30 -3 4 -34 19 -70 34 -36 15 -67 31 -70 35 -3 4
          -29 18 -57 31 -29 13 -53 27 -53 31 0 4 -23 19 -51 34 -29 14 -61 34 -72 45
          -12 10 -24 19 -29 19 -4 0 -16 8 -26 18 -10 10 -34 28 -55 41 -20 12 -37 27
          -37 32 0 4 -12 15 -27 23 -16 8 -44 29 -63 46 -19 17 -52 45 -72 63 -62 53
          -259 249 -325 323 -34 38 -80 89 -102 114 -22 25 -41 48 -41 51 0 3 -12 20
          -27 37 -16 17 -41 47 -58 67 -16 20 -41 49 -54 63 -12 15 -29 39 -37 54 -8 15
          -20 31 -26 35 -15 9 -46 59 -55 86 -3 9 -9 17 -14 17 -5 0 -18 19 -30 43 -12
          23 -25 44 -29 47 -4 3 -18 25 -30 50 -12 25 -26 47 -30 50 -4 3 -20 34 -35 70
          -15 36 -31 67 -35 70 -4 3 -18 33 -30 67 -12 35 -25 63 -29 63 -3 0 -15 28
          -26 61 -10 34 -24 67 -30 73 -6 6 -23 51 -38 101 -15 49 -31 92 -36 95 -4 3
          -19 46 -31 95 -13 50 -27 93 -32 96 -10 6 -38 188 -38 245 0 54 10 104 21 104
          5 0 9 -9 9 -20 0 -11 4 -20 9 -20 5 0 19 -42 31 -92 12 -51 26 -95 30 -98 4
          -3 20 -48 35 -100 16 -52 33 -97 37 -100 4 -3 17 -35 28 -70 11 -35 24 -67 28
          -70 5 -3 19 -33 31 -67 13 -35 26 -63 30 -63 3 0 17 -28 31 -61 13 -34 29 -67
          35 -73 6 -6 21 -37 34 -69 13 -31 26 -57 30 -57 4 0 18 -25 31 -55 13 -30 28
          -55 32 -55 4 0 17 -22 29 -50 12 -27 25 -50 29 -50 4 0 19 -22 34 -50 15 -27
          31 -50 35 -50 5 0 11 -8 14 -17 8 -25 38 -71 65 -101 12 -14 22 -28 22 -33 0
          -4 7 -15 16 -24 17 -17 64 -83 64 -90 0 -3 34 -40 86 -95 13 -14 24 -28 24
          -31 0 -10 106 -123 225 -239 160 -157 167 -163 202 -187 17 -13 46 -36 64 -53
          19 -16 38 -30 44 -30 6 0 16 -9 23 -20 7 -11 19 -20 27 -20 7 0 20 -8 27 -19
          7 -10 37 -31 66 -46 28 -15 52 -31 52 -35 0 -4 17 -15 38 -25 20 -9 42 -22 47
          -29 6 -6 34 -22 63 -35 28 -14 52 -27 52 -31 0 -4 32 -20 70 -35 39 -15 70
          -31 70 -35 0 -4 10 -10 23 -13 37 -9 111 -38 117 -46 3 -4 52 -21 110 -36 58
          -16 107 -32 110 -36 6 -9 197 -49 229 -49 12 0 21 -4 21 -8 0 -12 170 -32 358
          -42 226 -11 682 16 682 42 0 4 10 8 22 8 32 0 252 42 258 49 3 4 57 19 120 35
          63 16 117 32 120 36 3 4 39 17 80 29 41 13 77 26 80 30 3 4 42 21 88 37 45 16
          82 32 82 35 0 4 27 17 60 29 33 12 60 26 60 30 0 4 26 18 58 31 31 12 59 26
          62 30 3 3 29 18 58 33 28 14 52 30 52 34 0 4 25 18 55 31 30 14 52 27 50 31
          -2 4 13 14 34 23 20 9 52 30 71 47 18 16 38 30 43 30 5 0 26 13 46 30 20 16
          49 40 65 52 16 13 42 35 58 51 16 15 32 27 35 27 21 0 314 284 389 377 22 26
          49 59 62 73 12 14 22 27 22 31 0 3 14 21 30 39 17 18 30 40 30 47 0 8 6 16 14
          19 7 3 26 30 42 60 15 29 31 54 35 54 4 0 14 17 22 39 9 21 22 45 29 53 19 20
          48 77 48 93 0 8 6 20 13 27 17 17 47 77 47 94 0 7 6 19 13 26 7 7 26 49 42 93
          16 44 32 82 36 85 4 3 17 39 30 80 12 41 25 77 29 80 4 3 18 46 30 95 12 50
          25 92 29 95 7 5 46 175 57 250 4 25 10 47 14 50 5 3 18 79 31 170 13 91 26
          167 30 170 23 19 51 625 39 860 -10 210 -30 410 -40 410 -4 0 -10 24 -14 52
          -15 127 -40 258 -47 258 -5 0 -9 9 -9 21 0 32 -49 241 -64 274 -8 17 -22 65
          -32 107 -10 42 -20 75 -24 73 -3 -2 -16 31 -29 73 -13 42 -26 79 -30 82 -3 3
          -19 44 -36 93 -16 48 -33 87 -36 87 -4 0 -17 26 -30 57 -12 32 -26 60 -30 63
          -4 3 -17 28 -29 55 -12 27 -25 52 -29 55 -4 3 -18 26 -30 53 -13 26 -26 47
          -31 47 -4 0 -14 14 -21 32 -7 17 -27 50 -44 72 -17 23 -34 49 -38 60 -7 16
          -15 18 -73 13 -36 -2 -68 -7 -71 -11 -7 -6 47 -116 57 -116 4 0 17 -25 30 -55
          13 -30 26 -55 30 -55 3 0 17 -26 30 -57 13 -32 27 -60 32 -64 4 -3 20 -39 34
          -80 15 -40 30 -76 34 -79 4 -3 18 -39 31 -80 13 -41 27 -77 30 -80 4 -3 18
          -46 30 -95 13 -50 27 -92 31 -95 7 -6 48 -200 48 -231 0 -10 4 -19 9 -19 23 0
          61 -435 61 -705 0 -260 -40 -745 -62 -745 -4 0 -8 -10 -8 -22 0 -40 -12 -68
          -28 -68 -12 0 -14 11 -8 72 20 229 25 302 31 483 9 252 -21 766 -44 785 -4 3
          -17 70 -30 150 -13 80 -26 147 -30 150 -4 3 -18 50 -31 105 -12 55 -25 102
          -29 105 -4 3 -20 49 -35 103 -16 53 -32 97 -35 97 -3 0 -15 28 -26 61 -10 34
          -25 67 -31 74 -7 6 -19 31 -26 54 -8 23 -21 50 -29 59 -8 10 -28 48 -43 85
          -16 37 -32 67 -36 67 -4 0 -17 22 -30 49 -13 28 -29 52 -36 55 -8 3 -14 11
          -14 18 0 7 -9 24 -19 37 l-18 24 -319 -23 c-176 -13 -321 -27 -324 -31 -3 -3
          -52 -9 -110 -13 -75 -5 -116 -13 -145 -28 -22 -11 -42 -23 -45 -27 -3 -3 -29
          -17 -57 -30 -29 -14 -53 -27 -53 -30 0 -4 -27 -17 -60 -30 -33 -13 -57 -27
          -55 -31 2 -4 -27 -20 -65 -35 -39 -16 -70 -31 -70 -34 0 -4 -25 -18 -55 -31
          -30 -13 -55 -27 -55 -30 0 -4 -26 -17 -57 -30 -32 -13 -60 -27 -63 -31 -3 -4
          -32 -19 -65 -33 -32 -15 -66 -33 -75 -40 -8 -7 -35 -21 -60 -31 -25 -11 -47
          -23 -50 -26 -3 -4 -32 -19 -65 -33 -32 -15 -62 -30 -65 -35 -3 -4 -26 -18 -52
          -29 -27 -11 -48 -27 -47 -34 0 -10 2 -10 6 0 6 14 333 18 333 3 0 -4 29 -11
          66 -15 81 -8 217 -36 224 -45 3 -4 30 -15 60 -25 48 -15 109 -47 174 -89 31
          -20 102 -96 117 -125 8 -15 17 -29 20 -32 53 -44 46 -271 -11 -332 -11 -12
          -20 -27 -20 -34 0 -6 -11 -22 -25 -35 -13 -13 -25 -29 -25 -35 0 -6 -9 -19
          -20 -29 -11 -10 -20 -24 -20 -32 0 -8 -4 -12 -9 -9 -5 3 -19 -10 -32 -29 -13
          -19 -31 -37 -40 -40 -9 -3 -29 -19 -45 -35 -16 -17 -46 -40 -67 -52 -20 -12
          -37 -25 -37 -29 0 -4 -21 -18 -47 -31 -27 -13 -50 -27 -52 -31 -2 -5 -24 -18
          -49 -28 -26 -11 -49 -24 -52 -28 -6 -9 -133 -61 -148 -61 -6 0 -12 -5 -14 -10
          -2 -6 -37 -20 -78 -31 -41 -12 -77 -25 -80 -29 -6 -9 -191 -50 -225 -50 -14 0
          -25 -4 -25 -10 0 -5 -10 -10 -22 -10 l-23 -1 25 -20 c14 -11 34 -28 45 -37 11
          -10 40 -26 65 -37 25 -11 47 -22 50 -26 8 -11 79 -27 148 -35 40 -4 66 -12 69
          -21 10 -25 -39 -82 -76 -89 -49 -10 -215 2 -219 15 -2 6 -11 11 -20 11 -19 0
          -103 37 -112 48 -3 4 -24 20 -47 36 -24 16 -43 32 -43 36 0 4 -11 19 -24 33
          -13 14 -34 47 -46 74 -13 26 -27 50 -30 53 -11 8 -38 127 -51 225 -15 118 -6
          346 21 500 11 65 24 122 28 125 4 3 16 40 27 84 10 43 24 84 30 90 11 12 55
          136 48 136 -7 0 -88 -42 -93 -49 -3 -3 -18 -14 -35 -23 -16 -9 -31 -26 -33
          -37 -2 -12 -7 -21 -11 -21 -5 0 -19 -39 -31 -87 -13 -49 -27 -90 -31 -93 -4
          -3 -18 -55 -30 -115 -13 -61 -26 -112 -30 -115 -15 -11 -33 -206 -34 -370 0
          -176 20 -394 36 -384 5 3 9 -5 9 -18 0 -37 38 -172 50 -176 5 -2 10 -10 10
          -18 0 -18 38 -93 56 -112 8 -7 14 -19 14 -26 0 -7 9 -21 20 -31 11 -10 20 -22
          20 -26 0 -12 103 -108 122 -115 10 -3 18 -9 18 -14 0 -5 22 -19 50 -31 27 -12
          47 -25 45 -28 -7 -6 119 -47 177 -56 24 -4 46 -12 50 -18 4 -7 83 -13 223 -15
          120 -3 213 -7 208 -11 -6 -3 -16 -17 -22 -29 -14 -26 -66 -52 -105 -52 -13 0
          -26 -6 -28 -12 -7 -19 -449 -19 -455 0 -3 6 -13 12 -22 12 -23 0 -144 40 -151
          49 -3 4 -26 18 -52 30 -63 30 -165 118 -194 167 -6 11 -16 20 -23 20 -6 1 -15
          2 -19 3 -5 0 -22 33 -37 71 -15 39 -31 70 -34 70 -3 0 -17 36 -31 80 -14 44
          -28 80 -31 80 -4 0 -18 44 -30 98 -13 53 -27 99 -30 102 -4 3 -19 56 -33 118
          -24 108 -44 150 -66 137 -5 -3 -10 -12 -10 -20 0 -7 -6 -20 -14 -27 -7 -8 -24
          -45 -37 -84 -12 -38 -26 -71 -29 -74 -11 -8 -42 -180 -47 -262 -3 -43 -10 -78
          -14 -78 -5 0 -9 -36 -9 -80 0 -47 4 -80 10 -80 6 0 10 -22 10 -48 0 -69 29
          -230 50 -277 9 -22 25 -66 35 -97 10 -32 22 -58 26 -58 5 0 9 -5 9 -11 0 -16
          42 -101 57 -117 7 -7 20 -29 29 -48 9 -19 25 -39 35 -45 11 -5 19 -15 19 -21
          0 -16 152 -168 168 -168 7 0 15 -7 19 -15 3 -9 23 -25 44 -36 22 -11 39 -23
          39 -27 0 -4 27 -18 60 -31 33 -13 60 -27 60 -31 0 -9 94 -35 186 -51 105 -17
          356 -6 464 21 46 11 87 24 90 29 3 4 31 16 63 26 33 10 64 23 70 30 7 6 34 22
          61 36 28 13 61 34 74 46 14 13 29 23 34 23 20 0 218 202 218 221 0 5 8 17 17
          27 10 10 29 40 42 67 13 28 29 57 36 65 6 8 20 42 30 75 10 33 21 62 25 65 21
          14 43 176 48 343 3 146 1 191 -13 252 -11 44 -24 76 -33 80 -21 8 -22 5 -38
          -72 -8 -37 -19 -70 -24 -71 -6 -2 -10 -9 -10 -16 0 -24 -42 -146 -50 -146 -4
          0 -17 -19 -28 -43 -17 -36 -38 -66 -100 -142 -12 -14 -91 -78 -111 -89 -9 -5
          -18 -13 -21 -17 -3 -4 -25 -17 -50 -30 -25 -13 -47 -26 -50 -29 -8 -10 -142
          -48 -195 -56 -27 -4 -53 -12 -57 -18 -11 -16 -437 -15 -469 1 -13 7 -49 21
          -80 31 -62 22 -150 80 -188 125 -14 17 -41 44 -58 61 -18 16 -33 33 -33 37 0
          5 -11 19 -25 33 -24 24 -36 64 -15 51 6 -3 10 -12 10 -20 0 -8 6 -18 13 -22 7
          -4 21 -21 31 -38 26 -41 155 -165 173 -165 7 0 16 -7 19 -15 6 -16 153 -65
          193 -65 12 0 19 -4 16 -10 -4 -7 50 -10 154 -10 88 0 162 4 165 9 3 4 36 11
          73 14 71 7 224 43 233 56 3 4 30 17 60 30 30 13 59 30 63 37 4 8 13 14 20 14
          7 0 28 14 47 31 19 17 46 41 60 53 14 11 36 37 50 56 13 19 31 43 40 52 8 9
          24 39 34 65 11 26 25 54 32 60 7 7 20 44 30 83 10 38 22 70 26 70 4 0 10 20
          13 45 16 104 60 98 88 -11 26 -101 20 -409 -12 -544 -12 -52 -26 -97 -31 -100
          -4 -3 -16 -29 -25 -58 -10 -29 -23 -59 -29 -65 -6 -7 -22 -35 -35 -64 -13 -29
          -30 -54 -37 -57 -8 -3 -14 -12 -14 -19 0 -8 -9 -22 -20 -32 -11 -10 -20 -21
          -20 -25 0 -19 -176 -180 -197 -180 -4 0 -18 -11 -30 -23 -12 -13 -40 -29 -62
          -37 -23 -7 -41 -16 -41 -20 0 -4 -26 -18 -57 -30 -32 -13 -64 -28 -70 -33 -18
          -13 -147 -46 -210 -54 -29 -3 -53 -10 -53 -14 0 -5 -70 -9 -155 -9 -85 0 -155
          3 -155 8 0 4 -29 13 -65 20 -69 13 -157 40 -165 51 -3 4 -25 15 -49 26 -24 11
          -49 24 -55 30 -6 6 -28 21 -48 33 -47 29 -88 64 -149 127 -79 82 -95 103 -126
          163 -9 17 -20 32 -23 32 -4 0 -17 24 -31 53 -13 28 -28 57 -34 63 -5 6 -19 37
          -30 70 -10 32 -22 61 -26 64 -9 6 -49 191 -49 224 0 13 -5 26 -12 28 -9 3 -12
          56 -12 198 0 142 3 195 12 198 7 2 12 18 12 35 0 40 37 206 49 221 4 6 19 42
          31 81 29 87 50 113 83 98 20 -9 27 -27 47 -117 13 -58 27 -106 31 -106 4 0 10
          -18 14 -41 13 -78 15 -31 5 118 -8 103 -8 200 -1 310 12 189 31 343 43 343 4
          0 8 9 8 21 0 32 42 233 50 239 10 7 21 70 13 70 -4 0 -28 -8 -53 -17 -71 -26
          -92 -55 -119 -163 -14 -52 -27 -97 -31 -100 -4 -3 -15 -56 -25 -118 -10 -62
          -21 -115 -25 -117 -33 -20 -50 134 -31 275 6 46 9 85 7 87 -2 2 -20 -7 -40
          -21 -20 -14 -53 -30 -71 -36 -19 -6 -35 -15 -35 -20 0 -4 -29 -21 -65 -36 -36
          -16 -65 -31 -65 -35 0 -3 -24 -16 -52 -30 -29 -13 -58 -29 -64 -34 -6 -6 -32
          -19 -58 -30 -25 -10 -46 -22 -46 -26 0 -3 -30 -19 -67 -34 -38 -16 -70 -32
          -73 -36 -3 -4 -28 -17 -55 -29 -27 -12 -52 -24 -55 -28 -8 -12 -49 -32 -63
          -32 -7 0 -22 -8 -33 -18 -10 -9 -50 -31 -89 -47 -38 -16 -75 -34 -82 -41 -7
          -6 -35 -20 -63 -30 -27 -10 -50 -21 -50 -25 0 -4 -25 -17 -55 -29 -30 -12 -55
          -25 -55 -29 0 -3 -35 -20 -77 -36 -43 -16 -86 -36 -95 -45 -9 -8 -37 -21 -62
          -29 -25 -7 -46 -17 -46 -21 0 -4 -27 -18 -61 -31 l-61 -24 7 -110 c4 -60 10
          -112 14 -115 3 -3 17 -77 29 -165 13 -88 27 -162 31 -165 4 -3 18 -49 30 -102
          13 -54 27 -98 32 -98 5 0 9 -5 9 -12 0 -21 43 -153 51 -156 4 -2 17 -32 29
          -68 12 -35 27 -70 34 -77 7 -6 23 -38 36 -69 13 -32 27 -58 32 -58 4 0 17 -22
          29 -50 12 -27 25 -50 29 -50 4 0 18 -21 31 -46 13 -26 29 -49 36 -51 7 -3 13
          -9 13 -14 0 -10 32 -58 60 -90 8 -9 29 -33 45 -54 45 -54 254 -264 309 -310
          26 -22 60 -50 74 -62 14 -12 41 -30 59 -40 18 -10 33 -21 33 -25 0 -4 18 -17
          40 -28 22 -11 40 -24 40 -28 0 -4 23 -17 50 -30 28 -13 50 -26 50 -31 0 -4 31
          -20 70 -37 38 -16 70 -32 70 -35 0 -4 27 -17 60 -29 33 -12 60 -26 60 -30 0
          -5 33 -18 73 -31 39 -12 74 -25 77 -29 3 -4 55 -20 115 -36 61 -15 112 -31
          115 -34 6 -8 170 -36 260 -46 36 -4 71 -11 78 -17 18 -14 465 -13 470 2 2 6
          17 11 34 11 44 0 282 41 288 49 3 4 47 18 99 32 52 13 99 28 105 34 6 6 44 22
          84 35 39 14 72 28 72 31 0 4 20 15 45 25 25 9 49 22 53 28 4 6 33 23 65 38 31
          15 57 32 57 38 0 5 5 10 11 10 18 0 79 47 71 55 -4 5 -2 5 4 2 6 -4 19 2 29
          13 10 11 23 20 30 20 6 0 21 14 33 30 13 17 27 30 33 30 13 0 159 146 159 159
          0 6 11 18 25 27 14 9 25 21 25 26 0 5 15 28 34 50 19 22 42 55 51 74 8 18 20
          36 25 39 5 3 19 27 32 53 13 26 28 52 34 58 6 7 18 33 28 58 10 25 21 46 25
          46 4 0 21 42 36 93 16 50 32 94 36 97 4 3 17 52 30 110 13 58 27 108 31 111
          10 6 48 267 48 326 0 23 5 43 11 45 15 5 17 587 2 597 -6 3 -13 49 -16 101 -9
          128 -38 344 -48 350 -4 3 -10 31 -14 63 -6 47 -5 57 8 57 14 0 30 -64 53 -220
          4 -25 10 -47 14 -50 9 -6 36 -220 51 -405 18 -217 7 -646 -21 -820 -12 -77
          -25 -144 -29 -150 -5 -5 -19 -53 -31 -105 -13 -52 -26 -97 -30 -100 -4 -3 -15
          -30 -25 -60 -9 -30 -23 -64 -29 -75 -7 -11 -25 -51 -41 -90 -16 -38 -32 -72
          -36 -75 -3 -3 -16 -24 -28 -48 -12 -23 -25 -42 -30 -42 -5 0 -11 -8 -14 -17
          -7 -22 -38 -74 -53 -88 -6 -5 -19 -23 -29 -40 -10 -16 -27 -39 -37 -50 -10
          -11 -38 -42 -61 -69 -81 -94 -213 -218 -255 -240 -16 -8 -32 -22 -35 -31 -4
          -8 -12 -15 -19 -15 -7 0 -27 -13 -45 -28 -17 -16 -50 -37 -72 -47 -23 -10 -41
          -21 -41 -25 0 -3 -29 -19 -65 -35 -36 -16 -65 -33 -65 -36 0 -4 -30 -17 -67
          -30 -38 -12 -70 -25 -73 -29 -6 -9 -140 -50 -164 -50 -9 0 -16 -4 -16 -8 0
          -15 -249 -61 -333 -62 -21 0 -36 -4 -33 -8 3 -5 -53 -12 -124 -15 -148 -7
          -390 2 -390 15 0 4 -17 8 -37 8 -68 1 -343 47 -353 60 -3 3 -45 16 -95 29 -49
          12 -91 26 -93 31 -2 6 -9 10 -16 10 -18 0 -140 41 -146 49 -3 4 -38 19 -77 35
          -40 15 -73 31 -73 35 0 3 -27 17 -60 30 -33 13 -60 27 -60 31 0 4 -22 17 -50
          29 -27 12 -50 25 -50 30 0 4 -25 21 -55 36 -30 16 -52 31 -50 33 2 3 -12 14
          -33 24 -20 11 -41 25 -47 32 -5 6 -23 22 -40 34 -130 99 -365 334 -434 435 -8
          12 -19 25 -24 28 -8 6 -26 33 -43 68 -4 7 -18 25 -30 39 -13 14 -24 31 -24 38
          0 7 -6 17 -14 21 -7 4 -26 35 -41 68 -16 33 -34 65 -40 70 -6 6 -20 30 -30 55
          -11 25 -23 47 -26 50 -4 3 -18 32 -30 65 -12 33 -25 62 -29 65 -4 3 -18 37
          -31 75 -13 39 -27 72 -30 75 -7 7 -59 191 -59 212 0 9 -4 18 -10 20 -5 2 -20
          64 -32 138 -13 74 -26 137 -30 140 -4 3 -12 65 -19 138 -7 80 -16 132 -23 132
          -18 0 -116 -43 -116 -51 0 -4 -22 -15 -50 -25 l-50 -18 0 -64 c0 -35 4 -61 9
          -58 4 3 11 -38 14 -92 8 -115 37 -315 47 -322 4 -3 15 -41 25 -85 9 -44 20
          -91 23 -105 3 -14 8 -27 12 -30 3 -3 18 -47 33 -99 15 -52 30 -97 35 -100 4
          -3 18 -41 32 -83 13 -43 27 -78 31 -78 3 0 17 -28 30 -62 13 -35 28 -68 34
          -75 7 -6 22 -39 35 -72 12 -34 26 -61 30 -61 3 0 17 -24 31 -52 13 -29 32 -61
          42 -70 9 -10 17 -24 17 -31 0 -7 8 -22 18 -33 10 -10 29 -39 42 -63 14 -25 35
          -56 48 -69 12 -14 22 -31 22 -38 0 -7 8 -19 18 -26 10 -7 28 -30 41 -50 12
          -21 26 -38 31 -38 5 0 11 -8 14 -17 3 -10 21 -34 38 -54 18 -20 49 -56 68 -78
          55 -66 259 -271 269 -271 6 0 17 -10 25 -21 8 -12 27 -30 41 -39 24 -16 39
          -28 135 -110 19 -16 40 -30 47 -30 7 0 16 -6 20 -13 10 -15 89 -67 103 -67 5
          0 10 -4 10 -9 0 -5 16 -16 35 -25 19 -9 40 -22 47 -29 7 -6 41 -25 76 -41 34
          -16 62 -32 62 -36 0 -3 28 -16 63 -30 34 -13 64 -27 67 -31 3 -3 34 -17 70
          -29 36 -13 70 -27 76 -32 14 -11 173 -58 196 -58 9 0 18 -4 20 -8 4 -10 139
          -39 223 -48 33 -4 63 -12 67 -18 10 -16 616 -15 626 0 4 6 30 14 57 18 83 12
          210 39 215 47 3 5 28 13 55 19 66 14 149 41 155 50 3 4 33 18 68 30 34 12 62
          27 62 31 0 5 7 9 15 9 28 0 15 -26 -20 -39 -19 -7 -40 -18 -47 -25 -16 -15
          -94 -51 -130 -60 -16 -3 -28 -10 -28 -15 0 -5 -31 -19 -70 -31 -38 -12 -70
          -25 -70 -29 0 -4 -42 -18 -92 -30 -51 -13 -95 -27 -98 -31 -10 -15 -267 -52
          -397 -57 -73 -3 -133 -10 -133 -14 0 -5 -13 -9 -30 -9 -16 0 -30 4 -30 9 0 4
          -57 11 -127 14 -124 5 -383 42 -393 57 -3 3 -47 17 -97 30 -51 13 -93 27 -93
          31 0 4 -31 17 -69 28 -38 12 -71 25 -73 29 -2 4 -37 21 -78 37 -41 16 -81 35
          -88 42 -8 8 -30 20 -50 29 -20 8 -39 18 -42 23 -3 4 -26 18 -52 30 -27 13 -48
          26 -48 31 0 4 -15 15 -32 24 -45 23 -66 37 -102 69 -17 15 -35 27 -41 27 -6 0
          -16 9 -23 20 -7 11 -19 20 -27 20 -8 0 -24 14 -37 30 -12 17 -27 30 -33 30 -6
          0 -25 15 -41 33 -16 17 -52 51 -79 73 -54 46 -135 123 -135 130 0 6 -31 41
          -72 81 -21 20 -38 41 -38 46 0 5 -11 18 -25 29 -14 11 -25 25 -25 33 0 7 -11
          20 -25 29 -14 9 -25 22 -25 29 0 8 -16 31 -35 53 -19 21 -41 51 -48 67 -6 15
          -15 27 -19 27 -4 0 -17 18 -28 40 -11 22 -24 40 -28 40 -4 0 -18 23 -31 50
          -13 28 -27 50 -31 50 -3 0 -20 28 -36 63 -16 34 -32 64 -35 67 -9 8 -85 153
          -99 190 -8 19 -17 37 -20 40 -9 7 -60 142 -60 158 0 7 -4 11 -8 8 -5 -3 -19
          30 -32 72 -12 42 -26 79 -30 82 -4 3 -17 43 -30 90 -12 47 -25 87 -29 90 -9 6
          -61 238 -61 270 0 12 -4 19 -9 16 -9 -6 -39 203 -48 337 -3 48 -10 87 -14 87
          -5 0 -9 7 -9 15 0 19 -14 19 -32 2 -7 -8 -37 -22 -66 -31 -29 -10 -58 -23 -65
          -30 -7 -7 -29 -19 -50 -27 -30 -11 -37 -18 -37 -40 0 -15 3 -29 8 -31 4 -1 17
          -75 30 -163 13 -87 26 -162 31 -165 4 -3 11 -25 15 -50 11 -77 50 -245 58
          -250 3 -3 17 -46 29 -95 12 -50 25 -92 29 -95 4 -3 18 -40 31 -82 12 -43 26
          -78 29 -78 4 0 17 -36 30 -79 13 -44 27 -78 30 -76 3 2 19 -32 34 -76 16 -43
          31 -79 35 -79 4 0 18 -29 31 -64 13 -36 27 -63 31 -61 4 3 17 -22 29 -55 12
          -33 26 -60 29 -60 4 0 20 -29 36 -65 16 -36 32 -65 36 -65 4 0 17 -20 29 -45
          12 -25 25 -45 30 -45 4 0 18 -22 31 -50 13 -27 27 -50 30 -50 4 0 19 -20 33
          -45 15 -25 30 -45 34 -45 4 0 13 -14 21 -31 7 -16 26 -44 42 -62 16 -17 29
          -35 29 -39 0 -4 16 -24 35 -45 58 -63 70 -77 94 -113 13 -19 41 -48 62 -65 21
          -16 38 -35 39 -40 0 -12 128 -145 139 -145 3 0 27 -19 52 -42 55 -51 94 -83
          146 -121 21 -15 60 -43 86 -63 27 -19 60 -39 73 -44 13 -5 24 -14 24 -18 0 -5
          28 -22 63 -37 34 -15 64 -31 67 -35 7 -11 92 -50 107 -50 7 0 13 -4 13 -9 0
          -5 30 -19 68 -32 37 -12 69 -25 72 -30 6 -9 177 -59 201 -59 10 0 19 -4 21 -9
          3 -11 213 -51 264 -51 18 0 34 -5 36 -11 3 -8 72 -13 213 -16 115 -2 212 -6
          214 -9 12 -11 -16 -17 -104 -22 -52 -2 -95 -9 -95 -13 0 -5 -52 -9 -115 -9
          -63 0 -115 4 -115 9 0 4 -42 11 -92 14 -99 6 -300 36 -308 46 -3 4 -45 17 -95
          30 -49 12 -92 26 -95 30 -3 3 -27 14 -55 23 -72 23 -119 42 -125 49 -3 4 -30
          17 -60 30 -30 13 -61 29 -69 37 -7 7 -31 20 -52 29 -22 8 -39 18 -39 22 0 4
          -24 20 -54 34 -29 15 -56 33 -60 42 -3 8 -11 15 -18 15 -7 0 -28 14 -47 30
          -18 17 -37 30 -40 30 -3 0 -17 10 -31 23 -14 12 -48 40 -75 62 -89 72 -342
          333 -407 420 -12 17 -25 32 -28 35 -16 15 -60 80 -60 89 0 6 -4 11 -9 11 -5 0
          -19 17 -31 37 -12 21 -28 42 -36 46 -8 4 -14 14 -14 21 0 7 -10 24 -22 38 -13
          13 -33 45 -46 71 -13 26 -29 53 -35 59 -7 7 -24 37 -37 66 -14 28 -27 52 -31
          52 -3 0 -16 24 -30 53 -13 28 -29 57 -35 64 -6 6 -21 39 -34 72 -12 34 -26 61
          -30 61 -4 0 -18 28 -31 63 -12 34 -26 64 -31 67 -4 4 -17 33 -28 65 -11 32
          -24 61 -28 65 -4 3 -18 37 -31 75 -13 39 -27 72 -31 75 -4 3 -17 43 -29 90
          -13 47 -31 103 -41 125 -9 22 -25 74 -35 115 -10 41 -21 77 -25 80 -4 3 -17
          57 -30 120 -13 63 -27 117 -31 120 -6 4 -35 164 -55 302 -3 27 -7 48 -9 48 -8
          0 -81 -33 -85 -39 -3 -3 -32 -17 -65 -31 -33 -13 -66 -30 -73 -37 -7 -7 -21
          -13 -31 -13 -9 0 -21 -4 -26 -9 -6 -5 -27 -14 -47 -21 -21 -6 -38 -15 -38 -19
          0 -4 -25 -15 -55 -25 -30 -9 -61 -23 -68 -31 -8 -7 -38 -21 -66 -31 -29 -9
          -58 -22 -64 -28 -12 -12 -98 -48 -144 -61 -13 -3 -23 -10 -23 -15 0 -4 -29
          -18 -65 -31 -36 -12 -65 -26 -65 -30 0 -4 -29 -17 -65 -29 -36 -12 -65 -25
          -65 -29 0 -4 -38 -20 -85 -36 -47 -16 -85 -33 -85 -36 0 -3 -28 -17 -62 -29
          -35 -12 -65 -26 -68 -30 -3 -4 -42 -20 -87 -36 -46 -15 -83 -31 -83 -35 0 -4
          -34 -17 -75 -30 -41 -12 -75 -26 -75 -30 0 -4 -31 -17 -70 -29 -38 -12 -70
          -25 -70 -28 0 -4 -42 -20 -94 -37 -52 -16 -93 -31 -91 -35 2 -3 -34 -16 -78
          -29 -45 -14 -85 -29 -88 -33 -3 -5 -39 -18 -80 -29 -41 -12 -76 -25 -79 -29
          -3 -4 -50 -20 -105 -36 -55 -16 -102 -31 -105 -35 -3 -4 -41 -17 -85 -29 -44
          -12 -82 -26 -85 -30 -3 -4 -45 -18 -95 -30 -49 -13 -92 -27 -95 -31 -3 -3 -57
          -19 -120 -35 -63 -15 -117 -31 -120 -35 -3 -3 -48 -16 -100 -29 -52 -12 -97
          -25 -100 -29 -3 -4 -52 -18 -110 -31 -58 -12 -107 -26 -110 -30 -3 -3 -66 -19
          -140 -35 -74 -16 -137 -32 -140 -35 -6 -7 -198 -50 -222 -50 -9 0 -18 -4 -20
          -8 -2 -5 -68 -21 -148 -37 -80 -16 -147 -31 -150 -35 -3 -3 -63 -17 -135 -30
          -71 -13 -132 -26 -135 -30 -3 -4 -68 -17 -145 -30 -77 -13 -142 -27 -145 -31
          -3 -4 -81 -20 -175 -35 -93 -15 -172 -31 -175 -35 -3 -3 -72 -17 -155 -29 -82
          -13 -152 -26 -155 -30 -3 -4 -75 -18 -160 -31 -85 -12 -157 -26 -160 -30 -3
          -4 -97 -19 -210 -35 -113 -15 -207 -31 -210 -34 -5 -8 -343 -50 -392 -50 -16
          0 -28 -4 -28 -8 0 -9 -275 -37 -470 -48 -65 -4 -118 -12 -122 -18 -10 -16
          -966 -16 -976 0 -4 6 -29 14 -57 17 -82 11 -194 40 -189 49 3 4 -2 8 -11 8
          -24 0 -117 42 -136 61 -10 10 -29 25 -42 34 -13 8 -33 32 -43 53 -10 20 -23
          40 -28 43 -11 9 -11 139 1 148 4 3 17 26 28 51 11 25 32 58 47 74 15 17 28 34
          28 39 0 13 141 152 163 160 9 3 17 13 17 22 0 8 4 15 10 15 9 0 82 66 213 192
          33 32 62 58 66 58 3 0 29 21 56 46 28 25 67 59 88 77 21 18 50 44 65 60 14 15
          32 27 39 27 7 0 22 14 35 30 12 17 27 30 34 30 7 0 21 11 32 25 11 14 23 25
          26 25 10 0 70 43 79 57 4 7 25 22 47 34 22 13 40 26 40 31 0 4 12 14 28 22 15
          8 41 26 57 40 17 14 44 33 62 41 17 8 33 19 35 23 2 4 20 18 41 31 20 12 37
          27 37 32 0 5 5 9 10 9 12 0 60 33 98 68 14 12 31 22 38 22 8 0 19 9 26 20 7
          11 17 20 23 20 6 0 24 12 41 28 37 32 61 49 92 62 12 5 22 14 22 20 0 5 18 19
          40 30 23 12 43 27 47 35 3 8 9 15 14 15 11 0 62 35 99 68 13 12 30 22 37 22 7
          0 17 9 23 20 6 11 16 20 23 20 7 0 26 12 43 28 37 32 61 49 92 62 12 5 22 14
          22 19 0 6 13 17 29 26 16 8 37 22 46 31 23 23 84 64 95 64 6 0 10 4 10 9 0 5
          17 20 38 32 20 13 43 31 50 41 7 10 17 16 21 14 4 -3 21 7 38 22 40 36 90 72
          100 72 5 0 14 9 21 20 7 11 18 20 25 20 7 0 26 14 42 30 16 17 33 30 37 30 8
          0 82 54 146 107 19 16 42 31 53 35 10 4 19 12 19 17 0 6 15 17 33 26 18 8 50
          31 72 50 22 19 46 35 53 35 7 0 15 7 18 15 4 8 24 25 45 37 21 12 39 25 39 29
          0 4 22 20 50 36 27 15 50 31 50 35 0 3 17 17 38 29 20 13 43 31 50 41 7 10 20
          18 27 18 8 0 20 9 27 20 7 11 17 20 21 20 9 0 68 43 101 72 11 10 23 18 28 18
          4 0 16 8 26 18 10 9 37 30 62 46 24 16 56 40 69 53 14 12 32 23 39 23 7 0 15
          7 18 15 3 8 22 24 42 36 21 11 41 27 45 35 4 8 12 14 18 14 11 0 72 41 77 52
          2 4 20 17 40 30 20 12 43 30 50 40 7 10 20 18 27 18 8 0 20 9 27 20 7 11 17
          20 21 20 5 0 23 11 41 25 18 14 38 25 45 25 7 0 10 4 7 8 -3 5 13 19 34 31 22
          12 40 26 40 31 0 5 8 11 19 15 10 3 32 18 47 33 31 28 55 45 92 61 12 6 22 15
          22 20 0 6 13 17 29 26 16 8 37 22 46 31 18 18 83 64 90 64 3 0 13 8 24 17 10
          10 39 34 65 53 26 19 55 42 65 50 9 8 36 28 61 44 24 16 56 40 69 53 14 12 31
          23 38 23 7 0 17 9 23 20 8 15 8 20 -2 20 -44 0 -344 34 -348 40 -3 4 -106 19
          -230 35 -124 15 -227 31 -230 35 -3 4 -75 17 -160 30 -85 13 -157 26 -160 30
          -3 4 -70 17 -150 30 -80 12 -147 26 -150 29 -3 4 -75 20 -160 36 -85 15 -157
          31 -160 35 -3 3 -59 17 -125 30 -66 13 -122 26 -125 30 -3 3 -52 17 -110 30
          -58 12 -107 26 -110 30 -3 3 -59 19 -125 35 -66 15 -122 31 -125 35 -3 4 -45
          17 -95 29 -49 13 -92 26 -95 30 -3 4 -53 20 -112 36 -60 16 -108 32 -108 36 0
          4 -36 17 -80 29 -43 13 -77 26 -75 30 2 3 -34 17 -81 30 -46 14 -84 28 -84 32
          0 3 -37 19 -82 34 -46 15 -84 30 -86 34 -2 5 -31 18 -65 31 -34 12 -64 26 -67
          30 -3 3 -29 17 -57 29 -29 12 -53 26 -53 31 0 5 -5 9 -11 9 -15 0 -132 52
          -139 62 -3 4 -22 14 -42 22 -20 9 -42 21 -50 29 -7 7 -34 23 -60 36 -27 12
          -48 26 -48 29 0 4 -26 21 -58 37 -31 17 -63 38 -70 48 -7 9 -18 17 -25 17 -6
          0 -23 11 -37 25 -23 22 -33 25 -68 19 -23 -4 -42 -10 -42 -14 0 -4 -42 -18
          -92 -30 -51 -13 -95 -26 -98 -30 -3 -4 -63 -20 -135 -35 -71 -16 -132 -32
          -135 -35 -3 -4 -61 -17 -130 -30 -69 -12 -127 -26 -130 -30 -3 -4 -68 -17
          -145 -30 -77 -13 -142 -27 -145 -30 -4 -6 -183 -36 -325 -56 -27 -4 -52 -10
          -55 -14 -3 -4 -86 -18 -185 -31 -99 -13 -182 -26 -185 -30 -3 -4 -95 -17 -205
          -30 -110 -12 -202 -25 -205 -29 -5 -7 -259 -33 -510 -52 -85 -6 -157 -15 -160
          -19 -11 -15 -491 -34 -895 -34 -422 0 -746 15 -753 36 -2 5 -19 9 -37 9 -54 0
          -330 50 -333 60 -2 6 -11 10 -19 10 -21 0 -157 41 -163 49 -3 3 -32 17 -65 31
          -33 13 -66 30 -73 37 -8 8 -29 20 -47 27 -18 8 -47 30 -65 50 -18 20 -38 36
          -44 36 -6 0 -22 20 -36 45 -14 25 -28 45 -32 45 -4 0 -12 19 -19 43 -16 59 -6
          140 23 178 35 48 118 128 139 134 11 4 19 10 19 15 0 4 21 18 48 31 26 12 49
          26 52 30 3 4 28 17 55 29 27 12 52 24 55 28 3 4 43 21 90 37 47 15 87 31 90
          35 6 7 140 50 158 50 7 0 12 4 12 9 0 5 42 19 93 31 50 12 94 26 97 30 5 7 77
          25 198 51 34 7 62 16 62 20 0 3 51 16 113 29 61 13 114 27 117 31 3 4 56 17
          117 29 62 13 111 26 109 29 -3 4 59 20 137 35 78 16 144 32 147 36 3 4 59 17
          125 30 66 13 122 26 125 30 5 6 223 50 252 50 9 0 18 4 20 8 2 5 82 21 178 37
          96 16 177 31 180 35 3 4 75 17 160 30 85 13 157 27 158 32 2 4 15 8 28 8 21 0
          24 4 24 40 0 22 -4 40 -8 40 -5 0 -20 100 -32 223 -13 122 -27 224 -30 227 -4
          3 -20 131 -35 285 -16 154 -32 282 -36 285 -3 3 -17 113 -29 245 -13 132 -27
          242 -30 245 -4 3 -18 113 -30 245 -13 132 -26 242 -30 245 -4 3 -19 142 -35
          310 -15 168 -31 307 -35 310 -4 3 -17 129 -30 280 -13 151 -26 277 -30 280 -7
          6 -51 554 -50 638 0 28 -4 52 -10 52 -6 0 -10 118 -10 335 0 184 4 335 8 335
          5 0 12 44 15 98 9 126 38 322 49 322 4 0 8 7 8 16 0 28 43 164 52 164 4 0 8 6
          8 13 1 22 44 102 73 132 15 16 27 33 27 37 0 10 84 48 104 48 9 0 16 5 16 10
          0 6 13 10 29 10 17 0 33 -4 36 -10z m14910 -8051 c15 -7 12 -8 -12 -3 -27 5
          -33 2 -33 -13 0 -10 11 -28 23 -40 13 -12 29 -40 37 -62 7 -23 16 -41 20 -41
          4 0 18 -26 30 -57 13 -32 27 -63 32 -69 9 -13 42 -114 53 -164 4 -19 11 -37
          16 -40 4 -3 18 -50 30 -105 13 -55 26 -102 30 -105 3 -3 17 -70 30 -150 13
          -80 26 -147 30 -150 9 -8 30 -180 39 -332 11 -198 -16 -628 -41 -628 -5 0 -9
          -9 -9 -21 0 -33 -41 -223 -49 -229 -4 -3 -18 -47 -31 -97 -13 -51 -26 -93 -30
          -93 -3 0 -19 -43 -35 -94 -16 -52 -31 -93 -34 -91 -5 6 -51 -105 -51 -122 0
          -7 -4 -13 -9 -13 -13 0 -41 -81 -41 -118 0 -27 4 -32 25 -32 14 0 40 8 58 18
          52 28 151 135 188 202 9 16 18 27 21 25 2 -3 15 16 28 43 13 26 29 52 35 57 7
          6 22 35 35 65 12 30 26 57 29 60 4 3 18 39 31 80 14 41 28 77 32 80 4 3 18 47
          31 98 13 50 27 89 30 85 4 -3 7 0 7 8 1 33 49 282 49 257 1 -37 -48 -301 -57
          -308 -5 -3 -17 -41 -27 -84 -11 -44 -25 -84 -30 -90 -6 -6 -19 -38 -30 -70
          -11 -33 -25 -64 -30 -70 -6 -6 -24 -41 -39 -78 -16 -38 -32 -68 -36 -68 -4 0
          -13 -14 -21 -31 -7 -18 -25 -46 -41 -63 -15 -17 -28 -36 -28 -43 0 -6 -13 -21
          -30 -33 -16 -12 -30 -27 -30 -33 -1 -19 -88 -91 -133 -108 -58 -23 -77 -15
          -77 30 0 38 39 161 51 161 3 0 17 28 30 63 12 34 26 64 29 67 4 3 19 43 34 90
          15 47 33 96 40 110 8 14 22 61 32 105 10 44 21 82 25 85 9 6 49 210 49 246 0
          13 4 24 9 24 14 0 33 241 33 415 1 169 -19 405 -33 405 -5 0 -9 9 -9 21 0 36
          -43 284 -50 289 -3 3 -17 56 -30 118 -13 62 -25 110 -28 107 -3 -3 -16 37 -29
          88 -21 80 -41 134 -74 199 -5 10 -9 24 -9 32 0 8 -6 21 -13 28 -7 7 -24 36
          -38 63 -13 28 -32 56 -41 63 -10 7 -18 23 -18 36 0 37 15 44 55 25z m55 -17
          c0 -4 20 -18 45 -30 25 -12 48 -29 51 -37 4 -8 11 -15 18 -15 14 0 136 -116
          136 -129 0 -5 10 -18 23 -28 12 -10 33 -38 46 -63 13 -25 30 -50 37 -56 7 -6
          23 -36 35 -67 12 -32 25 -57 30 -57 9 0 53 -129 46 -136 -3 -3 -13 20 -22 50
          -10 31 -21 56 -24 56 -4 0 -15 23 -25 50 -10 28 -23 54 -30 58 -6 4 -21 28
          -33 53 -28 57 -47 80 -153 186 -109 109 -117 115 -167 139 -43 19 -55 34 -28
          34 8 0 15 -4 15 -8z m536 -862 c4 -30 10 -57 15 -60 21 -14 35 -466 20 -650
          -3 -41 -6 65 -6 236 0 213 -3 315 -11 325 -6 8 -14 41 -18 74 -9 90 -47 265
          -57 265 -11 0 -12 34 -2 44 9 8 49 -151 59 -234z m327 -52 c20 -23 37 -48 37
          -55 0 -6 6 -16 14 -20 8 -4 26 -37 41 -73 15 -36 30 -67 34 -70 4 -3 18 -39
          31 -80 13 -41 27 -77 31 -80 4 -3 18 -46 30 -95 13 -50 26 -92 30 -95 12 -10
          59 -286 59 -352 0 -20 6 -38 12 -40 16 -6 18 -525 2 -536 -5 -4 -16 -54 -23
          -112 -15 -123 -40 -253 -51 -260 -4 -3 -15 -36 -25 -75 -9 -38 -25 -88 -35
          -110 -10 -22 -25 -62 -35 -90 -9 -27 -20 -52 -24 -55 -3 -3 -20 -36 -36 -75
          -16 -38 -32 -72 -36 -75 -3 -3 -17 -25 -29 -50 -12 -25 -25 -47 -29 -50 -3 -3
          -17 -25 -31 -50 -13 -25 -32 -54 -42 -64 -10 -11 -18 -23 -18 -28 0 -4 -8 -16
          -17 -26 -10 -10 -31 -37 -47 -62 -16 -24 -40 -56 -53 -69 -12 -14 -23 -29 -23
          -32 0 -10 -211 -221 -260 -259 -24 -19 -52 -43 -62 -53 -10 -10 -37 -27 -58
          -38 -22 -12 -40 -24 -40 -28 0 -4 -26 -21 -57 -37 -32 -16 -63 -34 -69 -40 -7
          -6 -34 -19 -60 -29 -27 -10 -54 -23 -60 -29 -6 -6 -37 -20 -70 -31 -32 -10
          -61 -23 -64 -27 -7 -9 -196 -58 -226 -58 -13 0 -24 -4 -24 -9 0 -18 -190 -36
          -380 -36 -187 0 -370 17 -370 35 0 4 -16 11 -35 14 -57 11 -125 44 -125 61 0
          8 -4 15 -10 15 -5 0 -10 5 -10 12 0 6 3 8 6 5 4 -4 14 2 23 12 14 16 41 21
          170 30 205 15 341 32 335 43 -3 4 7 8 21 8 34 0 248 49 255 59 3 3 40 17 83
          31 42 13 77 26 77 30 0 3 31 17 70 30 38 13 67 26 65 29 -3 2 22 17 56 31 34
          15 64 32 68 37 3 6 25 20 49 32 23 12 42 25 42 29 0 4 17 16 38 27 36 19 58
          37 125 103 18 17 37 32 42 32 4 0 41 35 81 78 40 42 87 91 104 108 16 16 30
          35 30 41 0 7 8 18 18 25 10 7 28 30 41 51 12 20 26 37 30 37 4 0 20 25 35 55
          14 30 30 55 35 55 4 0 18 24 32 53 13 28 27 54 30 57 3 3 17 25 29 50 12 25
          26 47 30 50 4 3 20 41 35 85 16 44 31 82 35 85 4 3 18 39 31 80 13 41 26 77
          30 80 3 3 16 48 29 100 12 52 26 104 31 115 13 30 38 198 46 308 3 53 10 97
          15 97 4 0 8 86 8 190 0 105 -4 190 -9 190 -4 0 -11 42 -14 93 -7 124 -39 377
          -46 372 -3 -2 -17 64 -30 145 -14 82 -28 147 -32 144 -4 -2 -2 7 4 21 7 14 15
          25 18 25 3 0 22 -19 42 -42z m-3169 -286 c6 -10 -35 -215 -44 -222 -6 -4 -35
          -156 -48 -250 -2 -19 -8 -37 -12 -40 -19 -12 -43 -354 -37 -534 5 -180 23
          -336 39 -336 4 0 8 -7 8 -16 0 -30 41 -157 56 -171 8 -8 14 -21 14 -30 0 -15
          29 -73 41 -83 4 -3 16 -24 28 -48 12 -24 28 -45 36 -49 8 -3 15 -9 15 -14 0
          -16 42 -68 110 -136 60 -60 80 -78 150 -134 14 -11 41 -33 61 -49 20 -17 42
          -30 48 -30 6 0 19 -8 29 -18 17 -17 134 -72 153 -72 6 0 16 -6 23 -13 8 -7 49
          -22 92 -32 44 -11 82 -23 85 -28 14 -19 246 -39 399 -34 157 5 311 23 320 38
          3 4 28 11 56 14 42 6 52 4 57 -9 5 -11 -5 -23 -33 -41 -22 -14 -44 -25 -50
          -25 -5 0 -10 -4 -10 -9 0 -5 -25 -19 -55 -31 -30 -12 -55 -26 -55 -30 0 -4
          -10 -10 -22 -13 -69 -16 -144 -40 -148 -46 -7 -12 -165 -41 -240 -46 -71 -4
          -63 -2 85 21 55 9 102 19 105 23 3 4 44 18 93 31 48 12 87 26 87 29 0 4 25 15
          56 26 30 10 64 26 74 34 11 9 37 27 60 41 46 28 51 40 15 40 -14 0 -25 -4 -25
          -9 0 -4 -51 -17 -112 -27 -157 -26 -473 -27 -610 -1 -54 11 -98 23 -98 28 0 5
          -8 9 -17 9 -23 0 -153 43 -153 50 0 4 -31 20 -70 35 -38 15 -70 32 -70 36 0 4
          -21 18 -47 30 -27 13 -56 33 -66 46 -10 13 -24 23 -31 23 -7 0 -22 14 -34 30
          -13 17 -28 30 -34 30 -12 0 -83 72 -138 140 -19 24 -42 50 -51 59 -10 9 -27
          36 -40 61 -13 25 -28 50 -34 55 -6 6 -20 30 -30 55 -11 25 -22 47 -26 50 -4 3
          -17 39 -30 80 -12 41 -25 77 -29 80 -4 3 -16 50 -26 105 -26 144 -26 533 0
          735 10 80 22 147 26 150 3 3 17 65 30 138 13 72 26 132 30 132 4 0 13 33 20
          73 7 39 15 78 17 85 7 16 -15 16 -32 -1 -7 -7 -26 -25 -41 -38 -32 -28 -47
          -49 -68 -96 -8 -18 -19 -33 -23 -33 -5 0 -19 -27 -32 -60 -13 -33 -27 -60 -30
          -60 -4 0 -13 -21 -20 -47 -8 -27 -25 -71 -38 -100 -12 -28 -23 -60 -23 -69 0
          -10 -3 -15 -6 -11 -8 8 16 107 25 107 4 0 16 28 26 61 11 34 24 67 30 73 6 6
          21 37 34 69 13 31 27 57 32 57 5 0 9 5 9 11 0 13 41 74 82 121 31 35 70 49 82
          30z m2866 -497 c0 -25 -4 -45 -10 -45 -5 0 -10 20 -10 45 0 25 5 45 10 45 6 0
          10 -20 10 -45z m-3202 -111 c-6 -32 -14 -61 -18 -64 -4 -3 -16 -57 -26 -120
          -27 -166 -27 -615 0 -780 11 -63 22 -117 26 -120 4 -3 17 -47 30 -97 13 -51
          27 -93 31 -93 3 0 17 -27 29 -60 12 -33 26 -60 30 -60 4 0 15 -18 25 -41 10
          -22 27 -45 37 -50 10 -5 18 -15 18 -23 0 -14 99 -116 113 -116 5 0 18 -11 29
          -25 11 -14 26 -25 34 -25 8 0 17 -6 20 -14 3 -7 31 -26 62 -42 31 -16 60 -34
          64 -40 4 -7 30 -20 58 -30 27 -10 50 -21 50 -25 0 -3 35 -16 78 -29 42 -13 79
          -26 82 -30 13 -18 200 -44 375 -51 l190 -7 -195 3 c-153 2 -217 7 -300 25 -58
          12 -107 25 -110 29 -3 4 -36 18 -75 30 -38 13 -72 26 -75 30 -3 3 -30 17 -60
          31 -30 14 -57 28 -58 33 -2 4 -21 16 -42 27 -21 11 -44 25 -51 33 -8 7 -30 24
          -49 37 -92 65 -156 132 -194 205 -11 21 -28 47 -38 57 -10 10 -18 23 -18 29 0
          6 -10 32 -23 58 -13 25 -32 78 -42 116 -10 39 -22 72 -27 75 -4 3 -17 60 -29
          125 -27 152 -38 537 -20 695 15 131 42 283 51 290 4 3 10 22 14 44 4 21 9 36
          12 34 2 -3 -1 -31 -8 -64z"/>
          <path id="1" d="M5535 13755 c-34 -36 -48 -72 -66 -165 -7 -36 -15 -67 -19 -70 -9 -6
          -38 -198 -47 -308 -3 -42 -10 -80 -15 -83 -6 -4 -12 -130 -15 -292 l-6 -286
          34 -4 34 -4 -32 -1 c-47 -3 -45 -32 2 -32 19 0 35 -4 35 -10 0 -5 18 -10 41
          -10 22 0 73 -5 112 -10 40 -6 107 -15 150 -21 42 -6 77 -15 77 -19 0 -4 17
          -10 38 -13 20 -4 52 -9 70 -13 27 -6 32 -4 32 13 0 16 -13 22 -72 36 -40 10
          -94 20 -120 23 -53 7 -65 24 -18 24 17 0 30 -4 30 -10 0 -5 10 -10 23 -10 12
          0 54 -8 92 -18 l70 -18 22 -130 c12 -71 22 -144 22 -161 1 -18 5 -33 9 -33 5
          0 19 -82 32 -182 13 -101 27 -185 30 -188 10 -7 53 -357 47 -374 -4 -11 -1
          -13 10 -9 13 5 15 -2 12 -40 -2 -25 0 -43 5 -40 5 4 7 -14 4 -40 -3 -26 -2
          -45 2 -42 10 6 23 -119 15 -147 -5 -19 -4 -20 5 -8 9 12 10 1 7 -45 -4 -42 -3
          -53 4 -35 6 18 7 6 5 -40 -3 -37 -1 -52 3 -35 5 25 8 27 14 12 10 -27 9 -77
          -3 -77 -5 0 -10 -9 -10 -20 0 -25 17 -26 24 -2 3 9 4 -12 1 -48 -2 -48 -1 -57
          5 -35 7 22 8 11 5 -40 -2 -48 -1 -61 4 -40 9 30 9 30 10 4 0 -15 4 -40 7 -57
          6 -28 11 -31 48 -35 l41 -4 -47 -1 c-46 -2 -48 -1 -48 25 0 14 -3 24 -6 21 -7
          -8 15 -197 26 -223 5 -11 19 -105 31 -210 12 -104 25 -192 29 -195 3 -3 16
          -93 29 -200 12 -107 26 -204 31 -215 8 -18 15 -70 52 -382 6 -57 16 -103 20
          -103 4 0 8 -13 8 -30 0 -20 -5 -30 -15 -30 -8 0 -15 -4 -15 -10 0 -5 -10 -10
          -22 -10 -41 0 -343 -52 -348 -60 -3 -4 -53 -18 -112 -30 -60 -12 -108 -26
          -108 -31 0 -4 -10 -9 -22 -11 -22 -3 -23 -7 -28 -136 -3 -74 -3 -135 0 -136 9
          -5 225 27 230 34 5 6 202 34 328 47 34 3 62 9 62 13 0 4 28 10 63 13 183 19
          353 42 357 48 3 5 48 9 100 11 l95 3 3 -28 c3 -22 -1 -30 -17 -34 -12 -3 -21
          -9 -21 -13 0 -4 -43 -18 -95 -31 -52 -12 -95 -25 -95 -29 0 -4 -12 -10 -27
          -13 -67 -11 -220 -48 -238 -57 -11 -6 -73 -20 -138 -33 -65 -13 -115 -25 -113
          -28 3 -3 -74 -18 -172 -34 -97 -15 -179 -31 -182 -35 -5 -6 -155 -32 -267 -46
          -29 -4 -53 -10 -53 -13 0 -4 -75 -18 -167 -31 -93 -13 -170 -27 -173 -30 -3
          -4 -95 -20 -205 -35 -110 -16 -202 -32 -205 -35 -3 -4 -60 -15 -127 -25 -76
          -11 -123 -23 -123 -30 0 -6 34 -19 76 -29 41 -10 73 -20 70 -23 -8 -8 -170 38
          -178 51 -9 13 -127 13 -136 -1 -4 -5 -61 -20 -127 -32 -66 -12 -124 -26 -130
          -30 -5 -4 -68 -20 -140 -36 -71 -16 -132 -32 -135 -35 -3 -4 -47 -17 -97 -30
          -51 -12 -93 -26 -93 -31 0 -5 -7 -9 -16 -9 -21 0 -156 -41 -169 -51 -6 -4 -49
          -20 -95 -35 -47 -14 -87 -30 -90 -34 -3 -4 -35 -18 -72 -30 -38 -12 -68 -26
          -68 -30 0 -4 -30 -18 -67 -30 -38 -12 -70 -26 -73 -29 -3 -4 -34 -19 -70 -35
          -36 -15 -67 -31 -70 -34 -3 -4 -26 -18 -52 -32 -27 -13 -48 -28 -48 -31 0 -4
          -15 -17 -34 -28 -18 -12 -39 -35 -46 -51 -7 -17 -16 -30 -21 -30 -5 0 -9 -16
          -9 -35 0 -19 5 -35 10 -35 6 0 10 -6 10 -12 1 -23 56 -74 106 -98 27 -13 51
          -27 54 -31 6 -8 141 -49 163 -49 8 0 17 -4 19 -9 4 -13 177 -37 362 -52 137
          -11 845 -8 1171 5 l100 4 -95 -8 c-52 -4 -137 -8 -187 -9 -55 -1 -93 -5 -93
          -11 0 -7 -147 -10 -420 -10 -273 0 -420 3 -420 10 0 6 -21 10 -47 10 -106 0
          -423 46 -423 61 0 4 -15 10 -32 14 -46 9 -118 36 -118 44 0 4 -16 13 -36 20
          -20 7 -57 33 -81 58 -36 35 -46 54 -52 91 l-7 47 -8 -38 c-4 -21 -4 -48 0 -60
          8 -27 84 -107 101 -107 7 0 13 -4 13 -9 0 -9 94 -51 116 -51 7 0 14 -5 16 -11
          4 -12 212 -59 260 -59 14 0 28 -4 30 -9 7 -20 312 -35 693 -35 373 0 874 19
          885 33 3 4 82 12 177 19 264 18 547 45 553 52 3 3 64 12 135 18 223 21 340 35
          345 42 3 4 98 17 212 30 114 13 205 27 202 32 -3 4 12 8 33 9 54 1 425 53 419
          59 -2 3 70 16 162 29 92 13 169 26 172 30 3 4 73 17 155 30 83 13 152 27 155
          31 3 4 48 15 100 24 157 29 190 37 190 49 0 7 -21 35 -45 62 -25 28 -48 58
          -51 68 -3 9 -9 17 -15 17 -5 0 -21 24 -35 53 -15 28 -30 54 -34 57 -4 3 -18
          26 -30 53 -12 26 -26 47 -31 47 -5 0 -9 6 -9 14 0 17 -42 119 -52 126 -9 7
          -58 188 -58 218 0 12 -4 22 -10 22 -6 0 -10 33 -10 80 0 44 3 80 8 80 4 0 13
          14 21 31 l13 30 16 -23 c9 -13 18 -25 21 -28 3 -3 12 -15 19 -27 6 -13 18 -33
          25 -45 6 -13 15 -25 18 -28 3 -3 13 -19 22 -37 9 -17 22 -37 30 -45 7 -7 23
          -29 34 -48 12 -20 32 -47 44 -60 11 -14 27 -37 35 -52 8 -16 19 -28 25 -28 5
          0 12 -8 16 -17 6 -19 12 -26 107 -128 31 -33 56 -65 56 -70 0 -6 13 -19 30
          -28 16 -10 56 -43 88 -75 84 -81 132 -118 191 -148 7 -4 25 -18 39 -30 14 -13
          31 -24 39 -24 7 0 16 -6 19 -14 3 -7 31 -26 62 -42 31 -16 59 -32 62 -35 3 -4
          25 -17 50 -29 25 -12 47 -26 50 -29 3 -4 31 -18 63 -30 31 -13 57 -26 57 -30
          0 -6 51 -28 123 -52 20 -7 37 -16 37 -20 0 -4 30 -17 68 -30 37 -12 69 -26 72
          -30 3 -5 35 -18 70 -29 35 -11 67 -24 70 -28 3 -5 46 -20 95 -36 50 -15 101
          -33 115 -41 14 -7 54 -21 90 -30 36 -9 67 -20 70 -24 3 -4 43 -18 90 -31 47
          -13 87 -27 90 -30 3 -4 57 -20 120 -36 63 -16 117 -31 120 -35 3 -3 48 -16
          100 -29 52 -14 97 -27 100 -30 5 -6 113 -33 183 -45 20 -4 37 -10 37 -14 0 -4
          62 -20 138 -36 75 -16 139 -32 142 -35 3 -4 57 -17 120 -30 63 -13 117 -27
          120 -31 3 -4 73 -19 155 -34 83 -15 155 -31 160 -35 6 -4 69 -18 140 -31 72
          -13 132 -26 135 -30 3 -4 68 -17 145 -30 77 -12 142 -26 145 -30 3 -4 84 -20
          180 -35 96 -15 177 -31 180 -34 3 -4 67 -17 143 -28 126 -20 141 -21 165 -7
          34 19 143 105 226 177 17 15 34 28 37 28 3 0 21 13 40 30 69 60 137 116 219
          179 68 52 114 91 154 129 13 12 27 22 31 22 4 0 32 20 61 45 28 25 55 45 59
          45 4 0 18 10 31 23 50 48 169 136 222 164 17 9 32 20 32 24 0 4 21 18 48 31
          26 13 52 28 57 35 6 7 26 19 47 27 20 9 42 21 50 29 7 7 39 23 71 36 31 13 57
          26 57 30 0 4 29 18 65 30 36 13 65 26 65 30 0 4 19 13 43 19 128 33 178 48
          173 53 -3 3 43 16 102 29 59 13 109 27 112 31 3 4 82 19 175 35 94 15 172 31
          175 34 5 7 335 50 383 50 15 0 27 4 27 8 0 14 509 51 703 52 64 0 107 4 107
          10 0 6 67 10 180 10 99 0 180 -4 180 -8 0 -5 87 -12 193 -15 232 -7 571 -36
          575 -48 2 -5 15 -9 28 -9 44 0 329 -43 334 -50 3 -4 49 -15 102 -25 95 -18
          100 -18 155 -1 32 10 67 24 78 31 11 7 54 21 95 30 41 10 80 21 85 25 11 8
          143 35 223 46 28 3 52 10 52 14 0 13 265 30 468 30 215 0 445 -16 450 -31 2
          -5 15 -9 29 -9 38 0 277 -42 283 -49 3 -4 51 -18 108 -31 56 -13 102 -27 102
          -32 0 -4 7 -8 14 -8 20 0 180 -51 186 -59 3 -3 38 -17 78 -31 39 -13 72 -27
          72 -31 0 -4 34 -19 75 -34 41 -15 75 -30 75 -34 0 -3 24 -17 53 -31 28 -14 54
          -28 57 -31 3 -4 25 -17 50 -29 25 -13 59 -33 76 -46 17 -13 36 -24 42 -24 7 0
          21 -9 31 -19 11 -11 33 -26 48 -35 15 -8 39 -24 53 -36 14 -12 37 -28 52 -36
          15 -8 40 -25 55 -39 58 -52 72 -63 81 -66 37 -14 412 -387 412 -410 0 -3 12
          -20 28 -36 55 -62 77 -89 94 -115 18 -28 26 -38 78 -97 16 -19 30 -38 30 -42
          0 -10 110 -12 134 -3 22 8 20 27 -6 56 -30 32 -68 85 -68 94 0 3 -14 19 -31
          35 -18 16 -29 33 -25 37 4 4 14 -2 22 -12 8 -11 14 -16 14 -12 0 13 82 -53
          165 -133 l40 -38 165 23 c91 13 179 23 197 24 17 0 33 4 35 9 4 10 247 35 256
          26 3 -3 10 -79 15 -168 5 -89 12 -164 17 -167 4 -3 10 -50 14 -105 11 -164 37
          -380 47 -383 5 -2 9 -13 9 -25 0 -36 43 -292 50 -297 4 -3 17 -57 29 -120 13
          -63 26 -117 31 -120 4 -3 19 -52 34 -110 15 -57 30 -107 35 -110 4 -3 18 -39
          30 -80 13 -41 30 -80 37 -88 8 -7 14 -20 14 -27 0 -18 32 -89 43 -93 4 -2 7
          -8 7 -13 0 -14 52 -124 61 -127 4 -2 18 -24 30 -49 12 -26 30 -54 38 -63 9
          -10 32 -37 51 -60 85 -104 196 -204 248 -221 17 -6 32 -14 32 -18 0 -11 76
          -39 128 -47 23 -4 45 -11 48 -15 7 -13 144 -11 144 1 0 6 13 10 28 10 35 0
          162 39 162 50 0 4 21 15 48 25 26 10 60 31 76 47 17 15 34 28 39 28 13 0 126
          116 157 160 13 19 29 40 35 46 5 6 19 28 30 49 11 22 28 48 38 59 9 11 17 28
          17 37 0 10 5 21 10 24 6 3 10 13 10 21 0 9 7 22 15 30 8 8 22 40 32 72 10 31
          21 59 25 62 8 6 31 75 49 148 7 28 15 52 19 52 3 0 17 41 30 90 13 50 28 90
          32 90 4 0 8 7 8 16 0 24 43 168 52 174 4 4 10 19 14 36 5 27 3 30 -45 49 -28
          10 -53 25 -55 31 -3 7 -21 17 -41 24 -40 13 -55 5 -55 -32 0 -10 -4 -18 -9
          -18 -4 0 -19 -39 -32 -87 -12 -49 -28 -93 -34 -99 -6 -6 -20 -39 -30 -73 -11
          -33 -23 -61 -26 -61 -4 0 -17 -26 -30 -57 -12 -32 -26 -60 -29 -63 -4 -3 -20
          -32 -36 -65 -16 -33 -37 -68 -46 -77 -10 -10 -18 -24 -18 -31 0 -13 -30 -51
          -87 -112 -17 -19 -39 -48 -49 -64 -10 -17 -25 -33 -34 -36 -8 -3 -31 -19 -50
          -36 -47 -42 -156 -95 -212 -103 -50 -8 -178 11 -178 25 0 5 -6 9 -12 9 -33 1
          -109 52 -170 114 -38 38 -68 71 -68 74 0 2 -15 26 -34 52 -19 27 -45 72 -57
          102 -13 29 -26 54 -30 56 -4 2 -18 33 -30 70 -12 37 -25 69 -29 72 -4 3 -18
          44 -31 93 -13 48 -27 87 -30 87 -4 0 -17 49 -29 110 -13 60 -26 107 -29 105
          -7 -4 -61 283 -61 323 0 12 -4 22 -8 22 -9 0 -26 125 -44 325 -6 77 -15 142
          -19 145 -6 5 -19 207 -39 634 -6 110 -14 202 -18 205 -5 3 -21 33 -37 66 -15
          33 -31 62 -35 65 -3 3 -17 43 -30 90 -14 47 -27 87 -31 90 -16 12 -42 224 -42
          342 0 98 -3 124 -12 114 -7 -6 -31 -20 -54 -30 -22 -11 -41 -23 -41 -27 0 -5
          -18 -18 -40 -29 -22 -11 -40 -24 -40 -29 0 -7 -47 -41 -80 -58 -14 -7 -130
          -116 -219 -203 -126 -125 -124 -124 -150 -108 -14 9 -21 23 -21 45 0 18 4 33
          9 33 5 0 11 19 15 43 8 49 36 140 46 147 3 3 17 27 29 53 41 86 175 224 269
          277 14 8 32 20 39 27 7 6 34 19 60 29 27 10 53 24 60 30 7 7 56 22 110 35 54
          13 100 26 103 30 10 13 176 29 304 29 129 0 306 -16 316 -29 3 -3 54 -18 114
          -33 60 -15 112 -32 115 -36 3 -5 33 -19 66 -32 33 -13 66 -30 73 -37 7 -7 28
          -20 47 -29 19 -9 35 -20 35 -25 0 -5 11 -14 25 -19 28 -11 32 -25 11 -46 -16
          -15 -30 -19 -22 -5 6 9 -352 61 -416 61 -21 0 -38 5 -38 10 0 6 -75 10 -205
          10 -130 0 -205 -4 -205 -10 0 -5 -17 -10 -37 -10 -21 0 -44 -4 -50 -8 -16 -10
          -33 -124 -33 -217 0 -107 30 -289 50 -305 3 -3 15 -34 25 -69 10 -35 24 -69
          30 -75 6 -6 24 -42 40 -81 17 -38 38 -78 48 -87 9 -10 17 -24 17 -32 0 -8 11
          -24 25 -37 13 -13 25 -29 25 -35 0 -6 11 -22 24 -36 12 -14 26 -32 30 -39 15
          -31 32 -55 51 -76 11 -11 34 -38 50 -59 17 -21 47 -55 68 -75 20 -20 37 -39
          37 -44 0 -9 275 -285 284 -285 3 0 32 -24 63 -54 98 -92 114 -106 121 -106 4
          0 27 -19 52 -41 25 -22 57 -47 73 -55 15 -8 27 -18 27 -23 0 -5 17 -19 38 -31
          20 -12 41 -28 45 -36 4 -8 13 -14 20 -14 7 0 24 -11 38 -24 15 -14 50 -37 77
          -52 28 -15 49 -31 47 -35 -3 -4 8 -12 24 -17 16 -6 38 -19 48 -29 11 -11 41
          -30 67 -42 26 -13 52 -28 59 -35 7 -6 35 -22 62 -35 28 -14 52 -28 55 -32 3
          -4 30 -17 60 -29 30 -12 57 -25 60 -30 3 -4 34 -20 70 -35 36 -15 67 -31 70
          -35 3 -4 35 -17 73 -29 37 -13 67 -26 67 -30 0 -5 35 -18 78 -31 42 -13 79
          -26 82 -31 3 -4 48 -19 100 -34 52 -15 97 -31 100 -34 6 -8 177 -51 202 -51 9
          0 18 -4 20 -10 2 -5 53 -20 113 -32 61 -12 112 -25 115 -28 6 -8 340 -64 346
          -58 7 8 -8 68 -17 68 -4 0 -18 44 -30 98 -12 53 -25 99 -29 102 -7 6 -36 172
          -46 265 -4 33 -12 63 -18 67 -17 11 -15 700 2 705 7 3 12 22 12 44 0 53 41
          343 50 349 3 3 17 65 30 138 13 72 26 132 30 132 4 0 17 47 30 105 14 58 27
          105 31 105 4 0 12 24 19 53 16 70 42 151 50 157 3 3 17 42 30 88 12 45 26 82
          30 82 4 0 15 29 25 66 10 36 23 70 29 77 6 7 24 50 40 97 16 47 33 88 38 91 4
          3 18 32 29 65 12 32 24 61 28 64 4 3 18 31 31 63 13 31 27 57 31 57 3 0 17 27
          29 60 12 33 26 60 30 60 4 0 20 29 36 64 15 36 31 63 34 61 4 -2 17 18 29 45
          13 28 26 50 30 50 4 0 18 21 31 48 13 26 32 53 42 60 10 7 18 19 18 26 0 7 11
          24 24 38 12 14 33 42 46 63 12 20 32 47 44 60 12 13 32 38 46 55 14 17 37 45
          53 61 15 17 27 33 27 37 0 3 66 70 148 149 178 172 188 182 232 212 19 13 37
          27 40 31 3 3 37 27 75 53 39 25 77 52 85 59 9 7 42 26 75 43 33 16 62 32 65
          35 3 4 31 17 63 30 31 13 57 27 57 32 0 4 33 17 73 29 39 12 75 26 78 31 3 5
          59 20 125 34 65 14 128 29 139 34 11 5 38 12 60 16 l40 7 -60 15 c-33 8 -62
          19 -65 23 -3 4 -46 18 -95 30 -50 12 -92 25 -95 29 -3 4 -61 20 -130 36 -69
          15 -127 31 -130 34 -3 4 -54 17 -115 30 -60 13 -112 27 -115 31 -3 4 -57 17
          -120 29 -63 12 -117 25 -120 29 -3 4 -75 20 -160 35 -85 16 -159 32 -165 36
          -5 5 -71 18 -145 31 -74 13 -137 26 -140 30 -9 12 -169 30 -338 39 -233 12
          -832 -18 -832 -42 0 -4 -12 -8 -27 -8 -44 0 -348 -43 -353 -50 -3 -3 -85 -19
          -182 -35 -98 -15 -176 -31 -173 -34 2 -4 -53 -17 -123 -29 -69 -13 -129 -26
          -132 -30 -3 -5 -54 -18 -115 -31 -60 -12 -112 -26 -115 -30 -3 -3 -59 -19
          -125 -35 -66 -16 -122 -32 -125 -36 -3 -3 -45 -17 -95 -30 -49 -12 -92 -26
          -95 -29 -5 -6 -100 -35 -160 -48 -14 -3 -27 -9 -30 -13 -3 -4 -51 -19 -107
          -35 -57 -16 -103 -32 -103 -35 0 -4 -37 -18 -82 -30 -46 -13 -85 -27 -88 -30
          -3 -4 -40 -17 -82 -30 -43 -13 -78 -26 -78 -30 0 -4 -12 -11 -27 -14 -56 -14
          -168 -50 -173 -56 -3 -4 -39 -17 -80 -30 -41 -13 -77 -26 -80 -29 -3 -4 -41
          -18 -85 -31 -44 -13 -82 -27 -85 -31 -3 -4 -25 -12 -50 -19 -73 -19 -145 -43
          -150 -50 -3 -4 -27 -12 -55 -19 -70 -18 -119 -35 -125 -42 -3 -3 -45 -19 -95
          -35 -49 -15 -92 -31 -95 -35 -3 -3 -41 -17 -85 -29 -44 -13 -82 -27 -85 -30
          -3 -4 -48 -17 -100 -30 -52 -14 -97 -28 -100 -32 -3 -4 -60 -20 -125 -35 -66
          -15 -122 -30 -125 -34 -3 -4 -52 -17 -110 -29 -58 -13 -107 -26 -110 -29 -3
          -4 -57 -18 -120 -31 -63 -13 -117 -26 -120 -30 -6 -8 -191 -43 -197 -36 -2 2
          29 10 69 17 40 7 75 15 78 19 3 3 57 17 120 30 63 13 117 27 120 30 3 4 55 17
          115 30 61 12 112 26 115 30 3 4 61 20 130 35 69 15 127 31 130 35 3 4 43 17
          90 29 47 12 94 26 105 31 28 14 145 50 161 50 8 0 14 4 14 8 0 5 35 19 78 31
          42 13 86 29 97 36 11 6 43 19 70 29 28 9 58 24 67 34 8 9 46 46 83 81 59 57
          65 66 49 73 -13 6 -10 7 9 3 18 -4 26 -2 22 4 -3 6 6 13 20 17 14 3 25 9 25
          13 0 4 18 17 40 30 22 12 45 28 52 35 7 7 21 17 30 22 15 8 15 14 -11 64 -15
          30 -34 79 -41 108 -6 28 -16 52 -20 52 -10 0 -50 212 -50 263 0 20 -4 37 -10
          37 -13 0 -14 357 -1 365 6 3 11 -14 13 -37 3 -40 4 -36 21 41 9 47 21 87 26
          90 4 3 16 30 25 59 10 29 23 58 29 65 7 7 21 30 32 52 11 22 30 51 42 64 13
          13 23 29 23 36 0 6 13 23 29 37 16 15 37 40 47 57 10 17 25 33 34 36 8 3 37
          25 64 50 27 24 57 47 68 50 10 4 18 10 18 15 0 5 26 21 58 36 31 15 60 31 63
          35 3 5 32 19 65 30 32 12 61 24 64 29 7 10 180 50 219 50 17 0 31 5 31 10 0 6
          37 10 90 10 50 0 90 -4 90 -9 0 -4 28 -11 62 -15 70 -7 190 -35 198 -46 3 -4
          14 -9 25 -13 46 -14 110 -39 115 -46 3 -4 31 -20 63 -36 31 -15 57 -31 57 -35
          0 -4 14 -13 30 -20 17 -7 36 -20 43 -29 7 -9 23 -24 37 -33 36 -22 110 -96
          140 -138 14 -19 33 -43 43 -53 10 -10 23 -32 29 -48 5 -16 13 -27 18 -24 4 2
          18 -22 31 -55 13 -33 26 -60 29 -60 3 0 18 38 34 85 15 47 31 85 35 85 4 0 18
          23 30 51 13 28 34 62 47 76 13 14 24 31 24 38 0 6 14 21 30 33 17 13 30 28 30
          35 0 7 25 32 56 57 31 25 67 55 81 67 14 12 45 30 69 39 24 9 44 20 44 25 0 9
          122 49 150 49 10 0 20 4 22 10 5 17 156 33 311 33 l148 1 -7 30 c-12 58 -45
          116 -65 116 -10 0 -19 5 -19 10 0 6 -27 10 -61 10 -33 0 -58 -4 -55 -9 3 -4
          -36 -11 -87 -14 -111 -7 -245 -34 -265 -53 -7 -8 -20 -14 -27 -14 -20 0 -87
          -32 -109 -52 -11 -10 -25 -18 -32 -18 -7 0 -52 -39 -100 -87 -112 -114 -124
          -127 -124 -138 0 -6 -11 -23 -24 -38 -14 -16 -33 -48 -43 -73 -10 -24 -21 -44
          -25 -44 -3 0 -18 -36 -33 -80 -15 -44 -30 -80 -35 -80 -4 0 -13 15 -20 33 -7
          17 -28 55 -47 84 -18 28 -33 54 -33 58 0 11 -196 215 -206 215 -8 0 -69 44
          -76 56 -4 6 -25 19 -48 29 -22 10 -40 21 -40 25 0 4 -33 20 -72 35 -40 16 -75
          32 -78 36 -7 10 -165 49 -198 49 -13 0 -20 4 -17 10 4 6 -10 10 -36 10 -23 0
          -40 3 -36 6 10 10 138 -7 132 -17 -4 -5 4 -9 16 -9 27 0 162 -39 169 -49 3 -4
          35 -20 73 -36 37 -15 67 -31 67 -35 0 -4 8 -10 18 -13 28 -9 76 -41 90 -59 7
          -10 18 -18 25 -18 11 0 24 -11 112 -99 75 -75 106 -112 123 -148 9 -18 19 -33
          22 -33 4 0 17 -22 30 -50 26 -55 36 -61 45 -27 12 42 49 132 56 137 4 3 17 27
          28 53 11 26 25 47 31 47 5 0 10 6 10 14 0 7 16 31 35 52 19 21 35 41 35 45 0
          17 124 124 168 144 17 9 32 20 32 25 0 5 10 12 23 16 12 3 48 19 80 35 33 16
          99 36 150 45 51 9 101 20 112 25 11 5 75 11 142 12 68 1 123 4 123 7 0 18 -22
          80 -29 80 -5 0 -16 21 -26 48 -10 26 -23 52 -30 57 -7 6 -25 37 -40 69 -15 32
          -36 67 -46 77 -11 11 -19 23 -19 28 0 4 -14 26 -31 47 l-31 39 -256 5 -257 6
          254 2 c284 2 262 -6 192 70 -17 19 -31 37 -31 41 0 17 -219 228 -284 274 -17
          12 -40 29 -50 39 -11 10 -25 18 -31 18 -7 0 -24 11 -38 24 -14 13 -47 34 -74
          46 -26 13 -52 30 -56 37 -4 7 -17 13 -27 13 -10 0 -21 4 -25 9 -3 5 -19 12
          -35 16 -17 4 -28 10 -26 14 3 4 -34 20 -82 36 -48 16 -89 33 -92 36 -6 9 -156
          49 -182 49 -10 0 -18 4 -18 8 0 10 -145 39 -240 49 -36 3 -67 10 -70 14 -3 5
          -97 13 -209 19 -216 11 -643 -2 -649 -21 -2 -5 -20 -9 -39 -9 -57 0 -367 -41
          -373 -50 -3 -4 -25 -10 -50 -14 -98 -14 -225 -40 -230 -46 -3 -4 -43 -15 -90
          -24 -153 -29 -195 -39 -200 -46 -3 -4 -50 -18 -105 -30 -55 -12 -102 -26 -105
          -30 -3 -4 -45 -17 -95 -29 -49 -13 -92 -26 -95 -30 -3 -4 -52 -20 -110 -36
          -58 -16 -107 -31 -110 -35 -3 -4 -39 -17 -80 -29 -41 -13 -77 -26 -80 -30 -3
          -3 -39 -17 -80 -30 -41 -13 -79 -27 -85 -31 -5 -4 -48 -19 -95 -35 -47 -15
          -87 -31 -90 -35 -3 -4 -37 -17 -77 -29 -39 -12 -73 -25 -75 -29 -2 -4 -34 -18
          -73 -31 -38 -13 -72 -26 -75 -30 -3 -4 -41 -20 -85 -36 -44 -16 -82 -32 -85
          -36 -3 -4 -29 -15 -58 -24 -29 -10 -59 -23 -65 -29 -7 -6 -48 -24 -92 -39 -43
          -15 -82 -31 -85 -36 -3 -5 -33 -19 -67 -31 -35 -12 -63 -26 -63 -30 0 -3 -25
          -14 -55 -24 -30 -9 -60 -23 -67 -30 -7 -7 -33 -20 -58 -29 -93 -33 -125 -46
          -115 -47 6 0 -19 -12 -55 -27 -36 -14 -67 -29 -70 -33 -3 -3 -33 -17 -67 -29
          -34 -13 -63 -27 -65 -32 -2 -4 -34 -20 -72 -33 -38 -14 -72 -29 -75 -34 -3 -4
          -33 -19 -66 -32 -33 -13 -62 -27 -65 -30 -3 -3 -32 -17 -65 -30 -33 -13 -62
          -27 -65 -30 -3 -4 -36 -19 -75 -34 -38 -15 -72 -31 -75 -35 -3 -3 -32 -17 -65
          -30 -33 -14 -62 -28 -65 -31 -7 -10 -100 -50 -115 -50 -8 0 -15 -4 -17 -9 -1
          -5 -36 -23 -75 -39 -40 -16 -70 -32 -66 -35 3 -4 -1 -7 -9 -7 -23 0 -118 -41
          -118 -51 0 -5 -5 -9 -11 -9 -6 0 -25 -6 -42 -14 -18 -7 -48 -20 -67 -27 -19
          -7 -41 -19 -47 -25 -7 -7 -36 -20 -65 -30 -29 -9 -55 -20 -58 -24 -3 -4 -30
          -18 -60 -31 -30 -13 -61 -29 -68 -36 -7 -7 -19 -13 -26 -13 -15 0 -108 -39
          -116 -49 -8 -10 -80 -41 -95 -41 -7 0 -20 -6 -27 -13 -7 -7 -40 -24 -73 -37
          -33 -13 -62 -26 -65 -30 -8 -10 -130 -60 -146 -60 -8 0 -14 -4 -14 -8 0 -5
          -26 -19 -57 -32 -32 -13 -60 -26 -63 -29 -3 -4 -32 -17 -65 -30 -33 -14 -62
          -27 -65 -31 -3 -3 -39 -19 -80 -35 -41 -16 -77 -32 -80 -35 -3 -4 -31 -17 -62
          -29 -32 -13 -58 -27 -58 -32 0 -5 -6 -9 -14 -9 -19 0 -119 -42 -133 -56 -7 -7
          -27 -15 -44 -19 -16 -4 -42 -13 -57 -21 -15 -8 -33 -14 -39 -14 -7 0 -13 -4
          -13 -9 0 -5 -29 -19 -64 -31 -36 -12 -66 -24 -68 -28 -3 -8 -137 -62 -151 -62
          -5 0 -15 -6 -22 -14 -8 -8 -39 -22 -70 -31 -30 -10 -55 -21 -55 -24 0 -4 -31
          -18 -70 -31 -38 -13 -70 -27 -70 -31 0 -4 -38 -19 -85 -35 -47 -15 -85 -31
          -85 -35 0 -4 -30 -17 -67 -30 -38 -12 -70 -25 -73 -30 -3 -4 -34 -17 -70 -30
          -36 -12 -67 -25 -70 -29 -3 -3 -41 -19 -85 -35 -44 -16 -82 -31 -85 -35 -3 -4
          -36 -17 -75 -30 -38 -13 -72 -26 -75 -30 -3 -4 -36 -17 -75 -30 -38 -13 -72
          -26 -75 -30 -3 -4 -43 -20 -90 -35 -47 -16 -87 -32 -90 -35 -3 -4 -36 -17 -75
          -30 -38 -13 -79 -28 -90 -35 -11 -7 -49 -20 -85 -31 -36 -10 -70 -23 -76 -29
          -6 -6 -49 -21 -95 -34 -46 -14 -86 -28 -89 -32 -3 -4 -39 -17 -80 -30 -41 -12
          -77 -25 -80 -29 -3 -3 -48 -19 -100 -35 -52 -15 -97 -31 -100 -35 -3 -3 -45
          -17 -95 -30 -49 -13 -92 -27 -95 -30 -3 -4 -45 -17 -95 -30 -49 -12 -92 -25
          -95 -29 -6 -8 -228 -61 -254 -61 -9 0 -16 -4 -16 -8 0 -5 -48 -19 -107 -32
          -60 -13 -110 -27 -113 -30 -3 -4 -61 -17 -130 -30 -69 -12 -127 -26 -130 -30
          -3 -4 -81 -20 -175 -36 -93 -15 -172 -31 -175 -34 -3 -4 -72 -18 -155 -31 -82
          -13 -152 -26 -155 -29 -3 -4 -84 -17 -180 -30 -96 -13 -177 -27 -180 -30 -5
          -7 -437 -61 -483 -60 -16 0 -26 -4 -23 -8 4 -7 -438 -53 -506 -52 -14 0 -28
          -4 -30 -8 -3 -9 -366 -37 -623 -49 -84 -3 -143 -10 -147 -17 -10 -16 -1396
          -16 -1406 0 -4 6 -54 14 -112 18 -154 9 -365 36 -368 47 -2 5 -17 9 -33 9 -16
          0 -29 4 -29 9 0 5 -6 16 -13 23 -7 7 -24 51 -37 98 -13 47 -27 87 -31 90 -4 3
          -17 39 -29 80 -12 41 -26 80 -30 85 -4 6 -20 51 -35 100 -15 50 -31 92 -35 95
          -4 3 -17 39 -29 80 -13 41 -26 77 -30 80 -4 3 -18 41 -31 85 -13 44 -27 82
          -31 85 -4 3 -19 46 -34 95 -15 50 -31 92 -35 95 -4 3 -17 39 -30 80 -13 41
          -28 84 -35 95 -6 11 -20 47 -31 80 -10 33 -22 62 -25 65 -3 3 -19 46 -34 95
          -16 50 -32 92 -35 95 -4 3 -17 37 -30 75 -13 39 -26 72 -30 75 -3 3 -17 39
          -29 80 -13 41 -30 81 -38 89 -8 7 -12 23 -9 34 5 20 -32 150 -45 155 -4 2 -20
          42 -35 90 -15 48 -31 89 -36 92 -4 3 -17 35 -28 70 -11 35 -24 67 -28 70 -4 3
          -18 37 -31 75 -14 39 -27 72 -31 75 -4 3 -19 41 -34 85 -15 44 -31 82 -34 85
          -4 3 -18 37 -31 75 -14 39 -28 72 -31 75 -4 3 -17 34 -30 70 -13 36 -26 67
          -30 70 -4 3 -19 40 -35 83 -15 42 -30 77 -34 77 -3 0 -15 28 -26 61 -10 34
          -24 67 -31 73 -6 7 -22 40 -34 74 -13 34 -26 62 -30 62 -3 0 -17 32 -30 70
          -13 39 -27 70 -31 70 -4 0 -19 34 -34 75 -15 41 -31 75 -34 75 -4 0 -18 26
          -31 58 -13 31 -27 59 -30 62 -4 3 -18 30 -31 60 -12 30 -27 57 -31 61 -4 3
          -20 34 -34 70 -14 35 -29 66 -33 69 -4 3 -18 28 -31 55 -13 28 -27 52 -30 55
          -4 3 -18 25 -30 50 -12 25 -25 47 -29 50 -3 3 -16 26 -29 53 -13 26 -27 47
          -32 47 -4 0 -13 12 -19 28 -13 36 -41 80 -68 111 -13 14 -23 31 -23 38 0 7 -6
          16 -13 20 -7 4 -23 24 -35 45 -12 20 -29 39 -37 42 -8 3 -15 12 -15 19 0 8 -9
          22 -20 32 -11 10 -20 22 -20 26 0 20 -208 212 -252 232 -26 12 -48 25 -48 29
          0 5 -21 7 -47 7 -40 -1 -53 -7 -78 -34z m18695 -1165 c8 -15 8 -26 -2 -44 -7
          -13 -25 -46 -41 -75 -15 -28 -31 -51 -35 -51 -4 0 -15 -13 -24 -28 -19 -32
          -98 -114 -147 -152 -17 -14 -41 -34 -54 -45 -25 -23 -74 -53 -99 -61 -10 -4
          -18 -10 -18 -13 0 -4 -24 -18 -52 -32 -29 -13 -56 -27 -59 -32 -3 -4 -36 -19
          -74 -32 -38 -14 -72 -29 -75 -33 -3 -4 -41 -19 -85 -32 -44 -13 -82 -27 -85
          -31 -6 -7 -178 -49 -202 -49 -9 0 -18 -4 -20 -8 -4 -12 -189 -41 -345 -55 -73
          -6 -133 -15 -133 -19 0 -4 -97 -8 -215 -8 -118 0 -215 4 -215 8 0 4 -15 12
          -32 17 -23 7 -34 17 -36 34 -6 39 7 71 27 71 11 0 21 4 23 8 2 5 48 19 103 32
          55 12 102 26 105 29 3 4 66 20 140 35 74 16 137 31 140 35 3 4 57 17 120 30
          63 13 117 26 120 30 3 4 64 20 135 36 72 16 132 32 135 35 3 4 41 18 85 30 44
          13 82 27 85 31 3 4 32 17 65 30 33 12 67 27 75 34 8 6 38 20 65 30 28 10 59
          26 71 36 11 11 25 19 31 19 12 0 75 31 83 41 3 4 30 17 60 30 30 12 58 27 61
          31 3 5 39 22 80 38 41 16 70 30 64 30 -27 3 85 38 122 39 33 1 45 -4 53 -19z
          m-18702 -57 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z m95 -10
          c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m75 -10 c-10 -2 -26
          -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m18950 -440 c-49 -2 -88 -7 -88
          -12 0 -4 -46 -11 -102 -15 -167 -11 -483 -48 -486 -57 -5 -12 -72 -12 -72 -1
          0 9 292 47 448 59 45 3 82 10 82 14 0 9 91 15 220 15 l85 0 -87 -3z m-883
          -116 c-71 -11 -132 -23 -135 -27 -3 -3 -69 -17 -147 -30 -79 -13 -143 -27
          -143 -32 0 -4 -11 -8 -25 -8 -14 0 -25 4 -25 8 0 5 62 19 138 32 75 13 139 27
          142 30 6 9 244 48 290 48 19 0 -23 -9 -95 -21z m-605 -124 c-69 -14 -127 -28
          -130 -32 -3 -4 -57 -18 -120 -30 -63 -13 -117 -26 -120 -30 -6 -9 -220 -55
          -226 -48 -2 2 35 12 83 22 49 10 90 21 93 25 3 4 57 17 120 30 63 13 117 27
          120 31 6 8 246 57 280 57 14 0 -31 -11 -100 -25z m-610 -151 c0 -5 -26 -14
          -57 -21 -131 -28 -198 -45 -203 -51 -7 -8 -201 -52 -207 -47 -2 3 31 13 74 22
          43 10 80 21 83 25 3 4 28 12 55 17 28 6 87 19 133 28 45 10 82 22 82 26 0 5 9
          9 20 9 11 0 20 -4 20 -8z m866 -113 c-71 -5 -132 -12 -135 -17 -3 -5 -24 -12
          -46 -16 -68 -12 -145 -37 -145 -47 0 -5 -4 -9 -10 -9 -18 0 -120 -42 -133 -55
          -7 -7 -32 -22 -55 -34 -23 -12 -42 -26 -42 -31 0 -6 -6 -10 -12 -10 -34 0
          -248 -217 -248 -250 0 -6 -6 -13 -14 -16 -7 -3 -24 -28 -37 -57 -13 -29 -29
          -58 -36 -65 -7 -7 -20 -38 -30 -70 -10 -31 -21 -60 -26 -63 -4 -3 -17 -51 -27
          -105 -10 -54 -18 -88 -19 -74 -1 36 36 212 47 219 4 3 19 34 31 69 13 34 26
          62 30 62 4 0 18 25 31 55 13 30 28 55 32 55 4 0 20 19 37 43 37 54 84 103 141
          147 24 19 52 43 62 53 10 10 37 27 58 38 22 12 40 24 40 28 0 4 32 21 70 36
          39 15 70 31 70 35 0 8 139 50 163 50 9 0 17 4 19 10 4 13 119 28 223 27 83 0
          80 -1 -39 -8z m-1341 -19 c3 -5 -1 -10 -10 -10 -30 0 -200 -43 -203 -52 -2 -4
          -9 -8 -15 -8 -20 1 -242 -54 -247 -60 -5 -6 -112 -33 -182 -45 -21 -4 -38 -10
          -38 -14 0 -4 -48 -18 -107 -31 -60 -13 -110 -27 -113 -30 -3 -4 -59 -20 -125
          -35 -66 -15 -122 -31 -125 -35 -6 -10 -159 -44 -166 -37 -2 2 22 10 55 16 33
          6 61 15 63 19 2 5 59 21 128 36 69 16 127 32 130 36 3 4 52 18 110 30 58 13
          107 27 110 31 3 4 50 17 105 28 55 12 102 26 103 32 2 5 10 9 19 9 24 0 238
          52 243 60 3 3 53 17 112 31 60 13 108 27 108 32 0 11 38 9 45 -3z m-1355 -367
          c-8 -3 -17 -9 -20 -13 -3 -4 -57 -20 -120 -35 -63 -15 -117 -30 -120 -34 -3
          -4 -48 -18 -100 -30 -52 -13 -97 -26 -100 -31 -3 -4 -48 -17 -100 -30 -52 -12
          -100 -26 -106 -31 -5 -5 -57 -20 -115 -35 -57 -14 -106 -29 -109 -34 -3 -4
          -45 -17 -95 -29 -49 -13 -92 -26 -95 -30 -3 -4 -45 -18 -95 -31 -49 -13 -92
          -26 -95 -30 -3 -4 -52 -19 -110 -35 -58 -15 -109 -31 -115 -36 -5 -4 -48 -17
          -95 -30 -47 -13 -87 -26 -90 -30 -3 -4 -41 -17 -85 -29 -44 -12 -82 -25 -85
          -29 -3 -4 -52 -20 -110 -36 -58 -15 -107 -32 -110 -36 -3 -4 -43 -17 -90 -30
          -47 -13 -87 -26 -90 -30 -3 -4 -41 -17 -85 -29 -44 -12 -82 -26 -85 -30 -3 -4
          -53 -20 -112 -35 -59 -16 -105 -32 -102 -36 4 -7 -30 -19 -56 -19 -30 0 57 37
          140 60 47 13 87 27 90 30 3 3 43 17 90 30 47 13 87 27 90 31 3 4 42 17 88 30
          45 12 82 26 82 30 0 4 15 10 33 14 56 12 182 49 187 55 3 4 43 17 90 30 47 13
          87 27 90 31 3 4 43 17 90 29 47 13 94 29 105 35 11 7 62 23 113 35 50 12 92
          26 92 30 0 4 43 17 95 30 52 13 95 26 95 30 0 4 42 17 93 29 50 13 94 27 97
          31 3 4 55 20 115 35 61 15 112 31 115 35 3 3 46 17 95 29 50 13 95 27 100 31
          6 4 50 18 100 30 49 12 92 25 95 29 3 4 59 20 125 36 66 15 122 31 125 35 5 8
          36 17 60 18 13 0 13 0 0 -5z m-13442 -540 c-21 -2 -55 -2 -75 0 -21 2 -4 4 37
          4 41 0 58 -2 38 -4z m-145 -10 c-18 -2 -48 -2 -65 0 -18 2 -4 4 32 4 36 0 50
          -2 33 -4z m-188 -14 c-49 -4 -115 -7 -145 -6 -53 1 -53 1 15 7 39 4 104 7 145
          7 l75 0 -90 -8z m-201 -17 c5 -9 -381 -38 -390 -29 -3 2 54 8 125 12 72 4 131
          11 131 16 0 11 127 12 134 1z m12726 -117 c24 -3 49 -9 55 -14 5 -4 34 -17 62
          -30 54 -22 69 -33 138 -99 60 -58 96 -107 119 -160 11 -26 23 -49 27 -52 11
          -8 49 -150 49 -182 0 -16 5 -28 10 -28 6 0 10 -43 10 -110 0 -67 -4 -110 -10
          -110 -5 0 -10 -12 -10 -28 0 -33 -38 -174 -49 -182 -4 -3 -19 -30 -33 -60 -27
          -60 -136 -178 -189 -206 -18 -9 -39 -22 -46 -29 -18 -18 -141 -45 -205 -45
          -67 0 -190 26 -203 42 -5 7 -27 22 -48 32 -68 35 -177 153 -203 221 -9 25 -20
          45 -25 45 -9 0 -36 81 -45 137 -4 24 -10 40 -14 38 -13 -8 -23 142 -16 239 3
          50 11 93 16 94 6 2 10 12 10 23 0 22 41 152 51 159 3 3 15 23 27 45 11 22 34
          56 51 75 17 19 31 38 31 42 0 3 15 16 33 27 18 11 46 31 62 45 17 14 36 26 43
          26 13 0 54 21 62 32 8 10 129 26 165 22 17 -2 50 -6 75 -9z m-1470 -175 c-8
          -5 -32 -12 -52 -15 -21 -4 -38 -10 -38 -14 0 -4 -45 -20 -100 -36 -55 -15
          -100 -31 -100 -35 0 -4 -39 -18 -87 -30 -49 -13 -90 -27 -93 -31 -3 -4 -39
          -17 -80 -30 -41 -12 -77 -25 -80 -29 -3 -4 -51 -19 -107 -35 -56 -15 -100 -31
          -98 -34 2 -4 -37 -17 -86 -30 -49 -13 -89 -27 -89 -31 0 -4 -37 -18 -82 -30
          -46 -13 -85 -27 -88 -31 -3 -4 -48 -19 -100 -34 -52 -15 -97 -31 -100 -34 -3
          -4 -41 -18 -85 -31 -44 -13 -82 -27 -85 -31 -7 -9 -121 -41 -126 -36 -3 2 17
          10 43 17 26 8 50 17 53 20 3 4 39 17 80 29 41 13 77 26 80 30 3 4 50 20 105
          36 55 16 102 32 105 36 3 4 38 16 79 28 41 11 77 24 80 29 3 5 35 16 71 26 36
          10 83 26 105 36 22 10 77 28 123 41 45 12 82 26 82 31 0 4 5 8 12 8 19 0 152
          43 158 51 3 4 43 17 90 29 47 13 88 27 91 32 3 5 48 20 100 35 52 15 96 30 99
          34 6 7 97 36 120 38 13 1 13 0 0 -9z m-425 -410 c-4 -6 8 -10 28 -10 41 0 163
          -36 172 -50 4 -6 12 -7 18 -4 7 4 8 3 4 -1 -4 -5 8 -19 27 -30 37 -22 146
          -151 146 -172 0 -7 4 -13 9 -13 6 0 20 -33 33 -72 12 -40 26 -75 29 -78 11 -8
          29 -143 29 -211 0 -68 -18 -191 -30 -199 -4 -3 -17 -38 -29 -78 -12 -40 -26
          -70 -30 -67 -5 3 -14 -9 -20 -26 -12 -36 -153 -179 -175 -179 -8 0 -21 -6 -28
          -14 -8 -7 -45 -21 -83 -31 -102 -26 -252 -9 -293 32 -7 7 -19 13 -26 13 -7 0
          -25 11 -40 25 -15 14 -30 25 -34 25 -9 1 -72 58 -72 65 0 6 -34 55 -59 86 -8
          10 -21 37 -29 61 -8 24 -18 46 -23 49 -19 11 -42 157 -42 264 0 111 25 265 44
          265 5 0 9 5 9 11 0 15 38 97 55 117 7 9 30 37 51 62 20 25 50 54 68 65 17 11
          36 25 41 32 14 16 127 53 165 53 16 0 30 5 30 10 0 6 14 10 31 10 17 0 28 -4
          24 -10z m4905 -70 c0 -6 -10 -15 -22 -20 -13 -5 -36 -20 -53 -35 -16 -15 -54
          -48 -84 -73 -71 -62 -111 -104 -111 -119 0 -14 26 -18 32 -5 2 5 16 12 32 16
          25 6 27 5 13 -8 -8 -9 -33 -18 -56 -22 -77 -12 -49 38 80 146 23 19 50 42 59
          51 15 14 102 78 108 79 1 0 2 -5 2 -10z m-6184 -115 c-8 -8 -18 -15 -23 -15
          -16 0 -188 -54 -193 -61 -3 -4 -34 -15 -70 -24 -36 -10 -75 -24 -86 -31 -12
          -7 -57 -23 -100 -35 -44 -13 -81 -26 -84 -30 -3 -4 -48 -19 -100 -34 -52 -15
          -97 -31 -100 -35 -3 -5 -41 -18 -85 -31 -44 -13 -82 -26 -85 -30 -3 -3 -48
          -19 -100 -34 -52 -15 -97 -31 -100 -35 -5 -7 -108 -39 -152 -47 -15 -3 -25 -9
          -22 -14 3 -5 -3 -9 -13 -9 -10 0 -45 -9 -77 -20 -33 -11 -66 -20 -75 -19 -9 0
          13 9 49 21 36 11 67 23 70 27 3 4 43 17 90 30 47 13 87 26 90 30 3 4 50 20
          105 36 55 16 102 32 105 36 3 4 39 17 80 29 41 12 77 25 80 29 3 4 50 20 105
          35 55 16 102 32 105 36 3 4 41 18 85 30 44 12 82 25 85 29 6 8 139 51 158 51
          7 0 12 4 12 9 0 4 44 21 98 37 53 15 99 31 102 35 4 6 30 15 53 18 5 0 2 -6
          -7 -14z m-214 -369 c26 -7 54 -20 63 -27 60 -50 125 -115 125 -126 0 -7 5 -13
          11 -13 5 0 20 -21 31 -47 11 -27 24 -50 28 -53 16 -11 34 -130 33 -225 0 -94
          -15 -193 -31 -205 -4 -3 -18 -28 -31 -57 -13 -29 -30 -54 -38 -57 -7 -3 -18
          -17 -23 -31 -5 -14 -21 -29 -34 -35 -13 -5 -30 -17 -38 -28 -50 -72 -306 -75
          -388 -5 -56 47 -119 115 -125 133 -3 11 -10 20 -15 20 -5 0 -19 33 -31 73 -12
          39 -27 78 -33 86 -15 19 -15 243 0 262 6 8 21 46 33 86 12 39 29 74 37 77 8 3
          14 11 14 18 1 16 93 108 108 108 6 0 15 7 19 15 22 39 197 58 285 31z m-1272
          -145 c0 -5 -26 -17 -57 -26 -32 -9 -76 -21 -98 -28 -22 -6 -42 -14 -45 -17 -3
          -4 -43 -17 -90 -30 -47 -13 -87 -27 -90 -31 -3 -4 -43 -17 -90 -29 -47 -13
          -87 -26 -90 -31 -3 -4 -52 -19 -110 -34 -58 -16 -107 -31 -110 -35 -3 -4 -45
          -18 -95 -30 -49 -12 -92 -26 -95 -30 -3 -4 -45 -18 -95 -30 -49 -12 -92 -26
          -95 -30 -3 -4 -57 -20 -120 -35 -63 -15 -117 -31 -120 -35 -3 -4 -48 -18 -100
          -30 -52 -13 -97 -26 -100 -30 -3 -4 -50 -17 -105 -30 -55 -13 -102 -26 -105
          -30 -3 -4 -63 -20 -135 -36 -71 -15 -132 -32 -136 -36 -3 -4 -52 -17 -110 -29
          -57 -12 -106 -24 -109 -28 -3 -4 -68 -20 -145 -36 -77 -16 -142 -31 -145 -35
          -6 -8 -191 -42 -197 -37 -2 3 27 10 64 17 37 7 70 16 73 19 3 3 70 19 150 35
          80 16 147 32 150 36 3 4 55 17 115 30 61 12 112 26 115 30 4 7 52 19 215 53
          28 6 52 13 55 17 3 4 50 18 105 30 55 13 102 27 105 31 3 3 48 16 100 29 52
          12 97 25 100 29 3 4 57 21 120 36 63 15 117 31 120 35 3 4 47 18 98 30 50 12
          92 26 92 30 0 4 43 17 95 29 52 13 95 26 95 30 0 4 51 20 113 36 61 16 114 32
          117 36 3 5 44 18 90 31 47 12 87 25 90 29 3 4 41 17 85 29 44 12 82 25 85 29
          3 4 53 20 113 36 59 16 107 33 107 37 0 5 5 8 10 8 6 0 10 -4 10 -9z m5790
          -241 c-14 -4 -37 -8 -52 -9 -16 -1 -28 -5 -28 -9 0 -4 -61 -18 -135 -31 -74
          -13 -135 -27 -135 -31 0 -3 -64 -17 -142 -30 -79 -13 -145 -26 -148 -30 -7 -9
          -337 -63 -352 -58 -7 3 55 15 137 28 83 12 152 26 155 30 3 3 68 17 145 30 77
          13 142 26 145 30 3 3 64 17 135 30 72 13 132 26 135 30 5 7 109 27 145 28 16
          0 14 -2 -5 -8z m-5929 -62 c18 -6 42 -20 53 -30 11 -10 23 -18 27 -18 29 0
          129 -136 129 -175 0 -8 4 -15 9 -15 14 0 31 -92 31 -165 0 -64 -17 -166 -29
          -175 -4 -3 -15 -24 -25 -47 -19 -44 -85 -123 -102 -123 -6 0 -19 -9 -31 -19
          -40 -38 -148 -52 -225 -30 -21 5 -38 14 -38 19 0 6 -5 10 -10 10 -21 0 -78 45
          -101 79 -13 20 -29 40 -36 44 -14 9 -53 128 -53 163 0 13 -4 24 -10 24 -6 0
          -10 30 -10 70 0 40 4 70 10 70 6 0 10 10 10 23 0 38 47 167 60 167 5 0 18 15
          29 34 12 18 29 36 39 40 21 7 55 28 63 39 18 25 155 34 210 15z m4923 -146 c2
          -4 -67 -19 -155 -31 -87 -13 -159 -28 -159 -33 0 -10 -57 -11 -64 0 -2 4 67
          19 155 32 87 13 159 27 159 32 0 10 57 11 64 0z m-454 -88 c-41 -7 -86 -12
          -100 -12 -33 1 107 26 145 26 17 0 -4 -6 -45 -14z m-155 -21 c-16 -3 -32 -8
          -35 -12 -6 -9 -314 -55 -331 -49 -6 2 50 12 125 22 75 10 136 22 136 27 0 8
          50 15 105 16 l30 0 -30 -4z m-5506 -64 c18 -6 43 -22 56 -35 13 -13 29 -24 34
          -24 6 0 20 -18 31 -39 10 -22 24 -41 29 -43 16 -5 41 -117 41 -180 0 -76 -17
          -152 -41 -179 -10 -12 -19 -26 -19 -32 0 -19 -90 -95 -120 -102 -16 -4 -30
          -11 -30 -16 0 -5 -27 -9 -60 -9 -33 0 -60 4 -60 8 0 4 -16 14 -35 21 -43 15
          -114 85 -137 135 -10 20 -21 54 -24 76 -4 22 -10 40 -15 40 -5 0 -9 29 -9 65
          0 37 4 65 10 65 6 0 10 9 10 20 0 59 89 200 127 200 7 0 13 4 13 9 0 27 139
          41 199 20z m5109 -6 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z
          m-58 -12 c0 -10 -119 -25 -128 -17 -3 3 9 6 26 6 18 0 32 5 32 10 0 6 16 10
          35 10 19 0 35 -4 35 -9z m-180 -32 c-25 -4 -56 -7 -70 -7 -38 1 37 15 80 15
          32 0 31 0 -10 -8z m-10192 -11 c26 -6 49 -15 52 -19 3 -4 79 -20 170 -35 91
          -15 167 -32 168 -36 2 -4 18 -8 35 -8 16 0 28 -3 25 -6 -9 -9 -128 8 -122 17
          3 5 -5 9 -18 9 -43 0 -298 52 -298 61 0 5 -9 9 -20 9 -25 0 -26 -18 -2 -27 18
          -7 73 -43 82 -54 3 -4 25 -17 50 -29 25 -12 47 -26 50 -30 3 -3 31 -17 63 -30
          31 -13 57 -27 57 -32 0 -4 5 -8 10 -8 6 0 52 -20 104 -44 51 -24 112 -50 134
          -57 23 -7 42 -16 42 -20 0 -4 32 -17 70 -29 39 -12 70 -25 70 -29 0 -4 42 -20
          93 -36 50 -16 94 -32 97 -36 3 -4 41 -17 85 -30 44 -13 82 -26 85 -30 3 -4 42
          -17 88 -30 74 -20 106 -39 67 -39 -8 0 -15 4 -15 8 0 5 -40 19 -90 31 -49 13
          -90 27 -90 30 0 4 -37 18 -82 31 -46 13 -85 27 -88 30 -3 4 -42 19 -87 34 -46
          15 -83 31 -83 34 0 4 -36 18 -80 32 -44 14 -80 28 -80 32 0 3 -29 16 -65 28
          -36 12 -65 25 -65 29 0 5 -28 18 -62 31 -35 12 -70 28 -78 34 -8 7 -39 23 -67
          36 -29 13 -53 26 -53 30 0 4 -22 17 -50 30 -27 13 -50 27 -50 31 0 4 -16 14
          -34 23 -19 9 -44 30 -56 46 -24 34 -25 34 38 18z m10062 -5 c-19 -3 -37 -8
          -40 -13 -3 -4 -34 -10 -70 -13 -36 -2 -50 -2 -32 0 17 2 32 8 32 13 0 9 49 17
          110 17 l35 0 -35 -4z m-300 -53 c-63 -10 -128 -18 -145 -18 -40 1 180 36 225
          36 19 0 -17 -8 -80 -18z m-180 -30 c0 -5 -16 -10 -35 -10 -19 0 -35 5 -35 10
          0 6 16 10 35 10 19 0 35 -4 35 -10z m-200 -31 c-69 -10 -134 -18 -145 -16 -25
          3 186 34 235 34 19 0 -21 -8 -90 -18z m-8755 -6 c77 -10 142 -20 145 -24 3 -4
          70 -13 150 -20 80 -7 136 -15 126 -17 -25 -5 -360 29 -366 38 -3 3 -54 13
          -115 22 -60 8 -103 16 -95 17 8 0 78 -7 155 -16z m3205 -10 c-14 -3 -27 -9
          -30 -13 -3 -4 -27 -10 -55 -12 -27 -3 -40 -3 -27 -1 12 2 22 8 22 13 0 9 42
          17 90 17 l25 0 -25 -4z m5380 -13 c0 -5 -16 -10 -35 -10 -19 0 -35 5 -35 10 0
          6 16 10 35 10 19 0 35 -4 35 -10z m-92 -17 c-10 -2 -26 -2 -35 0 -10 3 -2 5
          17 5 19 0 27 -2 18 -5z m-5445 -10 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0
          24 -2 18 -5z m5272 -11 c-66 -8 -121 -19 -123 -24 -5 -10 -92 -11 -92 0 0 9
          229 39 295 39 22 0 -14 -6 -80 -15z m-5360 -3 c-22 -4 -53 -7 -70 -7 -26 1
          -24 2 10 8 22 4 54 7 70 7 25 1 23 -1 -10 -8z m-2442 -6 c-13 -2 -35 -2 -50 0
          -16 2 -5 4 22 4 28 0 40 -2 28 -4z m157 -13 c0 -6 31 -10 72 -10 39 0 69 -3
          65 -6 -9 -10 -257 7 -257 17 0 5 27 9 60 9 33 0 60 -4 60 -10z m2180 1 c0 -10
          -242 -35 -280 -29 -14 2 26 7 88 12 61 4 112 12 112 17 0 5 18 9 40 9 22 0 40
          -4 40 -9z m5230 -17 c-30 -7 -381 -44 -408 -43 -36 2 332 46 383 47 22 0 33
          -2 25 -4z m-6921 -20 c7 -6 -158 -2 -254 6 -63 5 -39 6 80 3 94 -3 172 -7 174
          -9z m1349 -1 c-21 -2 -55 -2 -75 0 -21 2 -4 4 37 4 41 0 58 -2 38 -4z m-359
          -19 c7 -6 -652 -2 -819 5 -85 4 63 5 330 3 267 -3 487 -6 489 -8z m189 9 c-32
          -2 -84 -2 -115 0 -32 2 -6 3 57 3 63 0 89 -1 58 -3z m5302 -23 c0 -5 -22 -10
          -50 -10 -27 0 -50 5 -50 10 0 6 23 10 50 10 28 0 50 -4 50 -10z m-160 -21
          c-30 -4 -93 -10 -140 -12 l-85 -5 100 12 c55 7 118 13 140 13 34 0 32 -1 -15
          -8z m-270 -26 c-24 -3 -47 -8 -50 -12 -3 -5 -41 -11 -85 -13 -44 -3 -63 -3
          -42 -1 20 2 37 8 37 13 0 9 61 16 140 16 l45 1 -45 -4z m-355 -51 c-71 -8
          -132 -18 -135 -22 -3 -3 -102 -19 -220 -34 -118 -16 -219 -32 -225 -36 -13
          -10 -174 -34 -181 -27 -3 3 22 8 55 12 34 4 61 10 61 14 0 4 96 20 213 36 116
          16 214 32 217 35 6 9 237 37 300 37 25 0 -13 -6 -85 -15z m-10840 -22 c-3 -5
          1 -10 11 -10 28 0 214 -54 214 -62 0 -5 -9 -8 -20 -8 -11 0 -20 4 -20 8 0 8
          -91 36 -182 56 -30 7 -39 26 -12 26 8 0 12 -4 9 -10z m329 -102 c45 -11 83
          -24 86 -28 3 -3 46 -17 95 -30 50 -12 92 -26 95 -30 3 -4 50 -20 105 -35 55
          -15 105 -30 110 -35 6 -4 46 -18 90 -30 44 -12 82 -25 85 -29 3 -4 41 -18 85
          -31 44 -13 82 -28 85 -32 3 -4 43 -19 90 -34 46 -14 93 -32 104 -39 12 -8 44
          -21 71 -31 28 -9 52 -20 55 -23 3 -4 40 -20 83 -36 42 -15 77 -31 77 -34 0 -4
          30 -18 68 -31 37 -13 69 -27 72 -31 3 -4 26 -17 50 -28 25 -11 37 -20 28 -20
          -10 -1 -46 14 -80 32 -35 18 -85 42 -113 52 -27 11 -52 23 -56 27 -3 4 -39 20
          -80 34 -40 15 -76 30 -79 34 -3 3 -34 17 -70 30 -36 13 -67 27 -70 31 -3 3
          -45 19 -95 35 -49 15 -92 31 -95 34 -3 4 -39 17 -80 29 -41 13 -77 26 -80 30
          -3 4 -44 18 -92 31 -49 12 -88 26 -88 29 0 4 -49 20 -110 36 -60 16 -110 32
          -110 36 0 4 -43 17 -95 30 -52 13 -95 26 -95 30 0 4 -31 15 -70 24 -38 9 -70
          19 -70 21 0 7 11 5 94 -18z m9681 -11 c-33 -5 -62 -13 -65 -17 -3 -5 -30 -10
          -60 -13 -30 -2 -44 -2 -30 0 14 3 27 8 30 13 6 8 111 27 155 27 17 0 3 -4 -30
          -10z m-285 -55 c-52 -9 -97 -19 -100 -22 -6 -9 -235 -43 -251 -38 -7 2 33 10
          87 17 54 8 101 17 104 21 6 8 181 37 225 37 17 0 -13 -7 -65 -15z m-4975 -59
          c66 -15 122 -30 125 -33 5 -6 150 -35 233 -46 26 -4 47 -10 47 -14 0 -4 46
          -14 102 -21 56 -7 96 -15 89 -17 -16 -5 -244 28 -251 37 -3 4 -63 17 -135 30
          -71 13 -132 26 -135 30 -3 4 -52 18 -110 33 -139 34 -116 35 35 1z m4540 -16
          c-38 -5 -72 -13 -75 -17 -6 -8 -219 -34 -226 -27 -3 3 28 8 68 12 40 4 75 11
          78 15 6 8 137 27 190 27 19 0 4 -4 -35 -10z m-360 -57 c-27 -5 -66 -8 -85 -8
          -32 1 -31 2 10 8 25 4 63 7 85 7 38 0 37 0 -10 -7z m-125 -20 c0 -5 -18 -10
          -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40 10 22 0 40 -4 40 -10z m-140 -20
          c-30 -5 -73 -8 -95 -7 -38 0 -38 0 10 7 28 4 70 7 95 7 44 0 44 0 -10 -7z
          m-157 -17 c-13 -2 -35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m-2973 -23
          c3 -4 84 -14 180 -22 96 -7 159 -14 140 -16 -47 -5 -393 28 -405 38 -6 5 -26
          11 -45 13 -19 2 1 2 45 0 44 -3 82 -8 85 -13z m2838 13 c-32 -2 -58 -8 -58
          -13 0 -13 -97 -13 -105 -1 -6 10 64 17 165 17 l55 0 -57 -3z m-190 -30 c-15
          -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z m-140 -10 c-21 -2 -55 -2
          -75 0 -21 2 -4 4 37 4 41 0 58 -2 38 -4z m-150 -10 c-21 -2 -55 -2 -75 0 -21
          2 -4 4 37 4 41 0 58 -2 38 -4z m-1195 -19 c318 -4 580 -11 583 -15 3 -5 -204
          -9 -460 -9 -304 0 -466 3 -466 10 0 6 -50 10 -127 11 -71 1 -173 5 -228 9
          l-100 8 110 -4 c61 -2 370 -7 688 -10z m1035 9 c-26 -2 -71 -2 -100 0 -29 2
          -8 3 47 3 55 0 79 -1 53 -3z m-235 -10 c-40 -2 -106 -2 -145 0 -40 1 -8 3 72
          3 80 0 112 -2 73 -3z m-5663 -261 c0 -10 -198 -37 -206 -29 -3 3 27 8 65 12
          39 4 71 11 71 16 0 5 16 9 35 9 19 0 35 -4 35 -8z m-220 -36 c0 -6 -221 -28
          -226 -23 -3 3 32 9 78 15 94 11 148 14 148 8z m-240 -36 c0 -5 -16 -10 -35
          -10 -19 0 -35 5 -35 10 0 6 16 10 35 10 19 0 35 -4 35 -10z m-125 -20 c-27 -5
          -66 -8 -85 -8 -32 1 -31 2 10 8 25 4 63 7 85 7 38 0 37 0 -10 -7z m-180 -20
          c-27 -5 -68 -8 -90 -8 -38 1 -38 1 10 8 28 4 68 7 90 7 38 0 37 0 -10 -7z
          m-131 -19 c6 -9 -261 -37 -270 -28 -3 3 38 8 90 12 53 4 96 11 96 16 0 12 77
          12 84 0z m-339 -41 c-33 -4 -82 -8 -110 -7 l-50 0 60 7 c33 4 83 7 110 7 l50
          0 -60 -7z m-165 -20 c0 -5 -23 -10 -51 -10 -31 0 -48 4 -44 10 3 6 26 10 51
          10 24 0 44 -4 44 -10z m-143 -17 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53
          -2 32 -4z m-134 -10 c-18 -2 -48 -2 -65 0 -18 2 -4 4 32 4 36 0 50 -2 33 -4z
          m-136 -10 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z"/>
          <path fill="black" d="M19247 10374 c-26 -8 -50 -21 -54 -29 -3 -8 -9 -15 -14 -15 -11 0
          -72 -41 -77 -52 -2 -4 -17 -24 -34 -45 -17 -21 -40 -58 -51 -83 -11 -25 -23
          -47 -26 -50 -36 -28 -53 -313 -27 -434 10 -42 21 -76 25 -76 4 0 14 -18 21
          -41 19 -57 129 -199 155 -199 6 0 21 -9 33 -20 41 -39 101 -55 202 -55 69 0
          107 5 140 18 25 11 47 22 50 26 3 4 22 17 42 30 38 23 98 90 98 110 0 6 5 11
          10 11 6 0 20 21 31 47 11 26 24 50 28 53 16 10 41 163 41 250 0 89 -23 239
          -38 249 -4 3 -18 30 -31 59 -12 29 -30 62 -39 72 -85 96 -108 120 -119 120 -7
          0 -16 7 -20 15 -5 8 -34 24 -66 35 -69 24 -207 26 -280 4z m241 -159 c8 -12
          39 -32 70 -45 12 -5 22 -14 22 -20 0 -6 10 -21 23 -34 24 -24 67 -113 67 -140
          0 -8 6 -16 13 -18 17 -6 17 -245 0 -256 -6 -4 -15 -21 -19 -37 -10 -42 -30
          -79 -59 -111 -14 -15 -25 -31 -25 -35 0 -12 -103 -59 -128 -59 -12 0 -22 -4
          -22 -10 0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 -10 10 -22 10 -38 1
          -102 35 -141 76 -20 22 -37 44 -37 50 0 7 -6 14 -14 17 -7 3 -22 35 -32 71
          -10 36 -22 66 -26 66 -5 0 -8 47 -8 105 0 58 4 105 9 105 4 0 11 16 14 34 5
          34 35 106 47 116 3 3 18 21 33 41 15 20 41 42 57 49 17 7 30 16 30 21 0 4 10
          10 23 12 37 9 168 2 175 -8z"/>
          <path fill="black" d="M17475 9804 c-41 -10 -81 -29 -99 -46 -11 -10 -24 -18 -29 -18 -14 0
          -97 -83 -97 -97 0 -7 -9 -22 -20 -33 -19 -19 -60 -128 -60 -161 0 -9 -6 -19
          -12 -22 -18 -5 -17 -259 0 -265 7 -2 12 -12 12 -21 0 -32 41 -143 61 -164 10
          -11 19 -25 19 -29 0 -8 84 -94 126 -128 36 -30 81 -42 171 -47 95 -6 178 11
          213 42 72 64 116 111 133 143 11 20 23 39 28 43 14 8 49 157 49 206 0 23 5 43
          10 43 6 0 10 11 10 25 0 14 -4 25 -9 25 -4 0 -11 33 -14 73 -5 62 -43 210 -46
          182 -1 -5 -14 15 -29 45 -31 62 -80 117 -132 145 -19 11 -37 23 -40 26 -23 27
          -183 49 -245 33z m173 -163 c2 -6 8 -11 13 -11 12 0 70 -40 86 -60 6 -8 18
          -26 25 -40 7 -14 18 -31 25 -38 39 -41 54 -225 28 -331 -10 -40 -23 -77 -29
          -84 -7 -7 -21 -27 -32 -45 -19 -33 -90 -82 -118 -82 -9 0 -16 -4 -16 -10 0 -6
          -27 -10 -60 -10 -33 0 -60 4 -60 9 0 5 -15 14 -34 21 -35 11 -116 85 -116 106
          0 6 -6 14 -13 17 -17 6 -47 110 -47 161 0 22 -4 35 -10 31 -6 -4 -10 8 -10 30
          0 22 4 34 10 30 6 -4 10 8 10 29 0 40 39 166 51 166 4 0 17 15 28 33 11 19 38
          42 58 53 21 10 40 22 43 25 18 21 161 21 168 0z"/>
          <path fill="black" d="M15866 9278 c-16 -6 -36 -18 -46 -27 -10 -9 -31 -28 -48 -44 -17 -15
          -36 -41 -44 -57 -7 -17 -16 -30 -19 -30 -4 0 -15 -19 -24 -42 -13 -30 -20 -80
          -23 -165 -4 -104 -1 -132 17 -192 12 -39 26 -71 31 -71 6 0 10 -7 10 -15 0
          -13 79 -98 124 -133 55 -43 228 -43 282 0 54 43 123 123 134 158 7 22 17 40
          21 40 10 0 29 116 29 177 0 52 -19 164 -29 173 -4 3 -15 25 -26 50 -11 25 -30
          55 -42 67 -13 12 -23 27 -23 32 0 6 -13 17 -29 26 -16 8 -35 20 -43 27 -27 27
          -71 38 -146 37 -42 0 -90 -5 -106 -11z m189 -123 c37 -15 84 -61 85 -82 0 -7
          4 -13 9 -13 5 0 18 -21 28 -47 32 -85 21 -261 -20 -306 -7 -7 -17 -21 -23 -32
          -6 -10 -19 -23 -29 -29 -11 -6 -25 -17 -31 -25 -15 -19 -104 -26 -148 -11 -67
          22 -130 101 -142 180 -4 22 -10 40 -15 40 -5 0 -9 29 -9 65 0 36 4 65 9 65 5
          0 12 17 16 37 4 21 13 50 21 65 19 38 81 96 107 101 48 9 110 6 142 -8z"/>
          <path fill="black" d="M14503 8839 c-18 -5 -33 -14 -33 -19 0 -5 -12 -15 -27 -22 -15 -7
          -32 -23 -39 -35 -6 -13 -13 -21 -16 -18 -6 6 -48 -91 -48 -111 0 -7 -6 -14
          -12 -16 -9 -3 -13 -35 -13 -108 0 -73 4 -105 13 -108 6 -2 12 -11 12 -20 1
          -19 49 -123 49 -107 1 5 12 -7 26 -28 14 -20 31 -37 38 -37 7 0 24 -10 37 -22
          21 -19 35 -23 104 -23 55 0 81 4 83 13 3 6 11 12 19 12 21 0 101 79 115 114 7
          17 17 34 22 37 35 24 34 303 -1 329 -4 3 -18 26 -31 51 -14 25 -40 57 -60 70
          -20 13 -38 27 -41 31 -20 26 -132 36 -197 17z m107 -88 c0 -5 12 -11 28 -14
          53 -11 122 -102 122 -161 0 -14 5 -26 10 -26 6 0 10 -20 10 -45 0 -25 -4 -45
          -10 -45 -5 0 -10 -9 -10 -19 0 -31 -36 -104 -64 -130 -35 -32 -99 -45 -145
          -30 -78 26 -141 131 -141 237 0 46 20 124 37 142 7 8 23 27 34 42 12 16 34 30
          54 34 19 3 35 10 35 15 0 5 9 9 20 9 11 0 20 -4 20 -9z"/>
          <path fill="black" d="M13305 8524 c-42 -22 -80 -68 -95 -117 -8 -23 -18 -45 -24 -49 -14
          -9 -12 -181 3 -186 6 -2 11 -9 11 -15 0 -7 12 -36 27 -65 22 -43 35 -57 75
          -75 26 -12 48 -25 48 -29 0 -5 25 -8 55 -8 30 0 55 4 55 9 0 5 18 16 39 25 22
          8 45 25 51 36 6 12 19 29 29 39 42 42 52 229 17 313 -19 45 -69 99 -113 122
          -41 21 -138 21 -178 0z m187 -105 c53 -55 71 -145 47 -232 -12 -46 -69 -106
          -101 -109 -43 -2 -78 3 -104 17 -26 13 -64 73 -64 100 0 8 -4 15 -10 15 -6 0
          -10 26 -10 59 0 33 5 63 10 66 6 3 10 13 10 21 0 21 42 81 65 93 11 6 43 9 72
          8 44 -3 57 -9 85 -38z"/>
          <path id="2" d="M31585 13408 c-22 -6 -42 -14 -45 -18 -3 -4 -36 -14 -74 -24 -38 -9
          -88 -25 -110 -35 -23 -11 -70 -27 -106 -37 -36 -10 -67 -21 -70 -24 -3 -4 -48
          -20 -100 -35 -52 -15 -97 -30 -100 -34 -3 -4 -41 -17 -85 -30 -44 -13 -82 -26
          -85 -30 -6 -9 -103 -41 -124 -41 -8 0 -21 -6 -28 -13 -7 -7 -59 -26 -115 -42
          -56 -16 -100 -32 -98 -35 2 -3 -34 -16 -80 -29 -46 -13 -85 -26 -87 -30 -2 -4
          -47 -20 -100 -35 -54 -16 -98 -33 -98 -37 0 -5 -5 -9 -12 -9 -18 0 -152 -43
          -158 -50 -3 -3 -41 -17 -85 -30 -44 -12 -82 -26 -85 -30 -3 -4 -50 -20 -105
          -36 -55 -15 -102 -31 -105 -34 -3 -4 -41 -18 -85 -30 -44 -13 -82 -27 -85 -31
          -4 -6 -121 -39 -137 -39 -2 0 2 -7 11 -15 8 -8 19 -15 24 -15 21 0 134 -52
          152 -70 11 -11 26 -20 33 -20 7 0 22 -8 33 -18 10 -10 27 -21 37 -25 27 -10
          20 -24 -17 -31 -19 -4 -36 -10 -38 -14 -2 -4 -43 -20 -93 -35 -49 -15 -92 -31
          -95 -35 -3 -4 -39 -19 -80 -32 -41 -14 -76 -28 -78 -32 -2 -4 -33 -17 -70 -29
          -37 -12 -69 -25 -72 -29 -3 -3 -41 -19 -85 -34 -44 -16 -82 -31 -85 -35 -3 -4
          -34 -18 -70 -31 -36 -13 -67 -27 -70 -30 -3 -4 -30 -15 -60 -25 -30 -9 -77
          -29 -105 -42 -27 -14 -70 -32 -95 -40 -25 -8 -47 -18 -50 -22 -3 -4 -34 -18
          -70 -31 -36 -13 -67 -27 -70 -32 -3 -4 -42 -20 -85 -35 -44 -15 -85 -33 -92
          -39 -6 -6 -35 -19 -64 -28 -28 -10 -57 -23 -64 -30 -7 -6 -40 -21 -74 -31 -33
          -11 -61 -23 -61 -27 0 -3 -29 -16 -65 -28 -36 -12 -72 -28 -79 -36 -8 -8 -19
          -14 -24 -14 -13 0 -128 -49 -117 -50 6 0 -24 -12 -65 -26 -41 -15 -77 -30 -80
          -34 -3 -5 -38 -21 -78 -36 -39 -14 -72 -30 -72 -35 0 -5 -6 -9 -14 -9 -16 0
          -119 -41 -126 -51 -3 -3 -34 -16 -70 -29 -36 -13 -68 -27 -71 -32 -3 -5 -37
          -20 -75 -34 -38 -13 -70 -29 -72 -34 -4 -12 -22 -13 -22 -1 0 10 -188 202
          -215 218 -11 7 -30 17 -42 22 -13 6 -23 15 -23 20 0 5 -26 19 -57 32 -32 12
          -60 26 -63 30 -10 15 -123 27 -147 16 -13 -6 -23 -14 -23 -19 0 -4 -7 -8 -16
          -8 -13 0 -14 -8 -8 -47 4 -27 10 -50 14 -53 9 -7 31 -141 24 -152 -11 -17 -45
          -7 -53 15 -5 13 -15 30 -23 38 -7 8 -22 31 -32 52 -11 21 -28 47 -37 58 -17
          20 -18 20 -61 -2 -24 -12 -45 -24 -48 -28 -8 -10 -80 -41 -95 -41 -8 0 -18 -6
          -22 -14 -4 -7 -15 -15 -23 -18 -8 -3 8 -12 38 -22 28 -10 52 -21 52 -25 0 -4
          27 -19 60 -34 33 -16 60 -32 60 -36 0 -5 16 -16 36 -26 19 -9 38 -23 41 -31 3
          -8 10 -14 17 -14 6 0 38 -25 71 -55 33 -30 63 -55 68 -55 4 0 7 -6 7 -12 0 -7
          21 -35 45 -62 25 -27 45 -52 45 -56 0 -4 11 -20 25 -36 14 -16 25 -34 25 -40
          0 -6 9 -23 21 -38 21 -27 69 -164 69 -197 0 -11 4 -19 9 -19 5 0 13 -25 16
          -55 l7 -55 64 0 c36 0 64 -4 64 -10 0 -5 16 -10 36 -10 57 0 388 -42 394 -50
          3 -4 41 -12 85 -19 126 -19 250 -44 255 -51 3 -3 47 -14 99 -24 52 -10 99 -20
          106 -22 6 -3 21 20 34 51 13 30 27 55 32 55 5 0 9 11 9 25 0 14 -4 25 -9 25
          -9 0 -79 69 -125 123 -16 19 -39 46 -51 60 -12 14 -33 47 -46 74 -12 26 -26
          50 -31 53 -14 10 -39 94 -45 155 -3 33 -11 63 -17 67 -8 5 -7 8 2 8 7 0 12 15
          12 39 0 45 37 171 50 171 5 0 12 9 15 21 12 37 158 189 182 189 7 0 13 5 13
          10 0 11 103 60 125 60 8 0 15 4 17 10 4 11 168 49 215 50 17 0 33 6 36 13 5
          17 469 16 475 -1 2 -7 17 -12 34 -12 17 0 91 -10 166 -22 l135 -21 101 24 c55
          13 103 26 106 30 11 14 184 31 327 31 144 0 332 -17 343 -32 3 -3 43 -14 90
          -24 47 -10 99 -24 115 -32 17 -7 68 -25 115 -40 47 -14 87 -30 90 -34 3 -4 32
          -17 65 -29 33 -12 62 -27 66 -32 10 -17 74 -22 90 -7 8 7 41 22 73 33 33 11
          64 25 70 31 6 5 43 21 81 34 78 27 110 51 92 69 -6 6 -17 11 -24 11 -7 0 -20
          8 -30 18 -10 11 -39 27 -65 38 -27 10 -48 22 -48 26 0 3 -33 19 -72 34 -40 16
          -75 31 -78 35 -3 3 -39 17 -80 30 -41 13 -77 27 -80 31 -6 8 -171 48 -200 48
          -10 0 -20 4 -22 9 -3 10 -125 26 -280 37 -54 3 -98 9 -98 13 1 33 116 51 335
          51 145 0 314 -15 325 -29 3 -4 50 -17 105 -30 55 -13 114 -31 130 -41 17 -10
          53 -26 80 -36 28 -10 52 -21 55 -24 3 -3 28 -17 55 -30 28 -14 53 -30 57 -35
          4 -6 30 -22 57 -35 28 -13 51 -27 51 -31 0 -15 192 170 250 240 19 24 42 50
          50 60 8 9 26 31 39 49 13 18 36 47 52 65 16 17 29 36 29 42 0 6 9 16 20 23 11
          7 20 18 20 26 0 7 10 24 23 38 26 30 56 76 64 101 3 9 9 17 14 17 5 0 19 20
          31 45 12 25 25 45 29 45 4 0 18 21 30 48 13 26 28 52 33 58 6 6 22 35 36 65
          14 30 28 56 31 59 4 3 17 30 30 60 12 30 26 57 30 60 11 7 49 99 49 116 0 8 4
          14 9 14 9 0 61 153 61 178 0 7 4 12 9 12 5 0 14 24 20 53 31 159 25 250 -20
          299 -25 28 -36 32 -86 34 -32 2 -76 -2 -98 -8z"/>
          <path id="3"  d="M29030 12522 c-30 -8 -57 -19 -60 -23 -3 -4 -42 -17 -87 -29 -46 -12
          -83 -26 -83 -31 0 -5 -6 -9 -13 -9 -19 0 -151 -42 -157 -49 -3 -4 -52 -20
          -110 -36 -58 -15 -108 -32 -111 -37 -3 -5 -39 -18 -80 -29 -41 -12 -76 -24
          -79 -28 -3 -3 -41 -17 -85 -29 -44 -13 -82 -27 -85 -31 -3 -4 -50 -20 -105
          -35 -55 -16 -102 -32 -105 -36 -3 -4 -41 -18 -85 -30 -44 -12 -82 -25 -85 -29
          -3 -4 -50 -20 -105 -35 -55 -16 -102 -31 -105 -35 -6 -7 -57 -25 -120 -41 -25
          -7 -47 -15 -50 -19 -3 -4 -43 -18 -90 -31 -47 -13 -87 -27 -90 -30 -3 -4 -50
          -19 -105 -34 -55 -16 -102 -32 -105 -35 -3 -4 -45 -18 -95 -31 -49 -13 -92
          -27 -95 -31 -3 -3 -46 -17 -97 -30 -50 -13 -89 -27 -87 -31 3 -4 -3 -8 -13 -8
          -10 0 -52 -9 -93 -19 -65 -16 -71 -19 -47 -26 15 -4 27 -10 27 -14 0 -4 23
          -18 50 -31 28 -13 50 -27 50 -31 0 -4 8 -10 18 -13 16 -6 38 -23 117 -94 27
          -24 78 -85 97 -114 4 -8 18 -6 47 7 23 10 41 21 41 25 0 4 19 13 43 20 23 7
          53 20 67 30 14 10 57 30 95 45 39 16 72 31 75 35 3 4 30 18 60 31 30 12 57 26
          60 29 3 4 33 18 68 31 34 13 62 27 62 31 0 3 29 16 65 28 36 12 71 27 78 34 7
          7 30 19 52 26 22 7 65 25 95 40 30 15 73 33 95 40 22 7 45 19 52 26 7 7 42 22
          78 34 36 12 65 25 65 28 0 4 28 16 61 27 34 10 67 24 73 30 6 5 43 21 81 34
          39 14 72 28 75 32 3 4 41 20 85 35 44 15 82 30 85 34 3 4 37 17 75 30 39 13
          72 26 75 30 3 4 46 20 95 36 50 15 92 31 95 36 3 4 37 17 75 29 39 12 72 24
          75 28 5 8 139 51 157 51 6 0 13 5 15 10 2 6 46 22 98 37 52 15 97 30 100 33 3
          4 29 13 59 21 52 13 52 14 25 23 -16 5 -31 12 -34 16 -3 3 -32 17 -65 31 -33
          13 -62 27 -65 30 -6 7 -97 39 -108 38 -4 0 -32 -8 -62 -17z"/>
          <path id="4" d="M5380 12440 c0 -5 4 -10 8 -10 5 0 12 -57 15 -127 11 -215 40 -568
          47 -573 4 -3 17 -124 30 -270 12 -146 26 -267 30 -270 10 -8 41 -352 36 -401
          -5 -38 -4 -40 4 -14 7 22 8 9 5 -50 -2 -56 -1 -73 6 -55 6 19 8 8 6 -40 -2
          -36 -2 -55 0 -42 7 37 23 14 23 -33 l0 -44 58 -4 c51 -4 50 -4 -10 -3 -64 2
          -68 0 -68 -20 0 -12 4 -24 8 -26 5 -1 19 -102 32 -223 12 -121 26 -222 29
          -225 4 -3 18 -95 31 -205 13 -110 26 -202 30 -205 3 -3 16 -84 29 -180 13 -96
          26 -179 31 -185 4 -5 13 -50 20 -98 8 -48 17 -90 22 -93 12 -8 242 17 248 26
          3 4 64 13 135 20 72 7 132 14 134 16 6 6 -41 428 -49 434 -5 4 -16 90 -47 395
          -3 33 -9 62 -13 65 -5 4 -61 509 -60 542 0 4 -42 6 -92 4 -95 -5 -83 -1 30 10
          57 5 62 7 62 30 0 13 -4 24 -8 24 -5 0 -19 98 -32 218 -13 119 -26 219 -30
          222 -3 3 -17 97 -30 210 -12 113 -26 207 -30 210 -3 3 -19 113 -34 245 -16
          132 -32 242 -36 245 -3 3 -17 82 -31 177 -13 94 -27 169 -31 167 -5 -3 -8 7
          -8 23 -1 49 -17 58 -140 76 -63 9 -148 20 -187 23 -40 4 -73 11 -73 15 0 5
          -22 9 -50 9 -27 0 -50 -4 -50 -10z m580 -1909 c0 -10 -228 -26 -237 -17 -4 3
          21 6 55 6 35 0 62 4 62 10 0 6 27 10 60 10 33 0 60 -4 60 -9z"/>
          <path id="5" d="M28056 11459 c-98 -10 -186 -28 -186 -38 0 -3 -21 -13 -47 -20 -54
          -16 -78 -28 -107 -53 -11 -10 -23 -18 -28 -18 -17 0 -96 -92 -114 -132 -10
          -24 -23 -46 -29 -50 -15 -11 -13 -200 3 -205 6 -3 12 -10 12 -18 0 -15 40
          -108 49 -115 4 -3 17 -22 30 -42 12 -21 27 -38 32 -38 5 0 8 -3 7 -7 -3 -15
          43 -81 68 -98 29 -19 24 -22 81 59 30 42 133 146 144 146 4 0 15 7 23 16 14
          13 16 13 16 1 0 -8 -11 -22 -25 -31 -14 -9 -23 -21 -20 -26 3 -4 -5 -17 -18
          -27 -12 -10 -36 -40 -51 -68 -16 -27 -33 -52 -37 -55 -3 -3 -14 -22 -23 -43
          -14 -35 -14 -40 0 -63 9 -13 19 -24 23 -24 3 0 23 -16 44 -35 21 -19 42 -35
          46 -35 4 0 17 -9 29 -20 12 -12 45 -32 72 -46 28 -14 50 -28 50 -32 0 -4 27
          -18 60 -31 33 -13 60 -27 60 -31 0 -3 35 -17 77 -30 l76 -23 41 23 c23 13 47
          32 54 42 7 10 19 18 26 18 7 0 21 9 32 21 10 11 40 32 66 47 26 15 48 30 48
          34 0 4 18 17 39 28 22 10 41 23 43 27 4 10 57 42 81 50 9 3 17 9 17 14 0 5 23
          20 50 34 28 14 50 29 50 34 0 5 8 11 18 14 19 6 74 38 82 48 3 4 24 16 47 27
          22 12 43 25 45 29 1 5 24 18 50 31 27 13 54 29 60 35 7 7 37 24 66 37 28 14
          52 27 52 31 0 3 24 16 53 30 28 13 58 29 65 36 7 7 33 21 58 32 53 24 96 56
          87 65 -3 3 -93 6 -199 6 -195 0 -426 -22 -432 -40 -2 -6 -14 -10 -26 -10 -13
          0 -36 -4 -52 -9 -16 -5 -58 -17 -94 -27 -36 -10 -67 -21 -70 -24 -3 -3 -32
          -17 -65 -30 -33 -13 -62 -27 -65 -30 -3 -4 -26 -17 -52 -29 -27 -13 -48 -26
          -48 -30 0 -4 -20 -18 -45 -30 -25 -12 -48 -28 -52 -35 -8 -12 -45 -36 -57 -36
          -3 0 -6 7 -6 15 0 15 144 165 158 165 4 0 20 11 35 25 15 14 51 38 80 52 28
          14 54 29 57 33 3 3 32 17 65 30 33 13 62 27 65 30 3 4 46 18 95 30 50 13 92
          27 95 31 9 13 283 39 407 39 65 0 167 -5 227 -10 60 -6 111 -9 113 -6 2 2 -21
          12 -51 21 -31 10 -56 21 -56 25 0 3 -33 17 -72 30 -40 14 -75 28 -78 32 -3 4
          -48 19 -100 34 -52 15 -97 30 -100 34 -6 7 -181 50 -205 50 -9 0 -14 4 -11 9
          3 5 -16 12 -43 16 -51 7 -141 -2 -141 -14 0 -4 -37 -16 -81 -26 -45 -10 -86
          -24 -93 -30 -6 -6 -44 -21 -85 -35 -41 -13 -79 -29 -85 -35 -6 -5 -36 -21 -66
          -35 -30 -14 -63 -33 -72 -42 -10 -10 -24 -18 -32 -18 -7 0 -24 -10 -38 -22
          -14 -13 -40 -32 -59 -42 -33 -19 -54 -36 -106 -83 -28 -27 -29 -27 -36 -9 -3
          8 1 29 9 46 19 37 145 170 162 170 6 0 19 11 28 25 9 13 24 27 34 30 10 3 29
          16 43 29 14 13 46 33 71 46 25 12 46 25 46 29 0 4 25 18 55 30 30 13 55 26 55
          31 0 15 -321 51 -440 49 -30 -1 -91 -5 -134 -10z"/>
          <path fill="black" d="M29220 11420 c0 -5 -21 -10 -47 -11 -52 0 -45 -6 37 -30 25 -7 47
          -16 50 -20 3 -3 39 -16 80 -29 41 -13 77 -26 80 -30 3 -4 42 -20 88 -35 45
          -15 82 -31 82 -35 0 -5 25 -16 56 -26 31 -9 62 -23 69 -30 7 -8 37 -23 65 -34
          l52 -21 59 23 c33 13 59 26 59 29 0 4 25 17 55 29 30 12 55 26 55 31 0 5 6 9
          13 9 8 0 39 10 69 23 70 28 62 38 -59 69 -48 13 -90 26 -93 29 -8 10 -175 38
          -267 45 -46 4 -83 11 -83 15 0 5 -94 9 -210 9 -133 0 -210 -4 -210 -10z"/>
          <path fill="black" d="M25251 11279 c-30 -5 -56 -11 -59 -13 -4 -5 18 -49 29 -56 11 -8 49
          -108 49 -130 0 -11 5 -20 10 -20 12 0 14 -187 2 -192 -4 -1 -15 -29 -25 -61
          -10 -32 -25 -61 -33 -64 -8 -3 -14 -9 -14 -14 0 -13 -42 -72 -59 -83 -8 -5
          -27 -21 -44 -37 -28 -27 -121 -69 -152 -69 -8 0 -15 -4 -15 -10 0 -6 -28 -10
          -65 -10 -37 0 -65 4 -65 10 0 6 -9 10 -20 10 -38 0 -144 42 -169 66 -27 28
          -37 30 -44 10 -11 -27 -41 -225 -36 -231 8 -7 149 17 149 26 0 4 66 17 148 30
          81 12 149 25 152 29 5 7 210 34 350 46 41 4 77 10 80 14 9 14 492 33 707 28
          117 -3 213 -4 213 -3 0 16 -43 135 -48 135 -5 0 -18 20 -30 45 -12 25 -26 45
          -31 45 -5 0 -12 8 -15 18 -11 30 -31 59 -61 90 -17 16 -46 48 -65 70 -19 23
          -40 41 -47 42 -7 0 -24 16 -39 35 -14 19 -30 35 -35 35 -5 0 -32 18 -61 40
          -29 22 -58 40 -65 40 -6 0 -18 6 -25 13 -16 16 -100 57 -115 57 -6 0 -13 4
          -15 10 -1 5 -39 19 -83 30 -92 24 -272 34 -354 19z"/>
          <path fill="black" d="M23321 11249 c-41 -5 -77 -13 -80 -17 -3 -5 -39 -19 -79 -32 -40 -12
          -70 -25 -68 -28 3 -2 -18 -16 -47 -30 -29 -14 -62 -36 -74 -49 -23 -24 -30
          -39 -15 -30 12 8 185 -12 191 -22 3 -4 17 -11 31 -15 43 -12 100 -39 100 -47
          0 -4 16 -17 34 -29 19 -11 38 -29 41 -39 3 -9 14 -26 24 -37 61 -66 89 -277
          55 -420 -10 -43 -26 -86 -36 -96 -10 -10 -18 -22 -18 -26 0 -13 -93 -102 -106
          -102 -7 0 -21 -9 -32 -20 -19 -20 -91 -50 -122 -50 -10 0 -20 -6 -22 -12 -4
          -10 -38 -13 -128 -13 -90 0 -124 3 -127 13 -3 6 -13 12 -22 12 -49 0 -140 51
          -202 113 -15 15 -29 27 -32 27 -11 0 39 -260 51 -270 4 -3 18 -38 31 -78 22
          -72 46 -112 65 -112 20 0 181 54 184 62 2 4 9 8 15 8 18 0 151 43 157 51 3 4
          41 17 85 29 44 12 82 26 85 29 3 4 52 20 110 36 58 16 107 32 110 36 3 4 46
          17 95 30 50 12 92 25 95 29 3 4 48 17 100 30 52 13 97 26 100 30 5 6 226 61
          248 60 6 0 12 4 12 8 0 4 54 19 121 32 l121 24 -6 30 c-3 17 -8 57 -12 89 -3
          31 -10 57 -15 57 -5 0 -9 6 -9 14 0 22 -48 159 -58 165 -5 3 -20 30 -33 59
          -13 28 -27 52 -31 52 -3 0 -16 16 -29 36 -24 39 -203 224 -217 224 -5 0 -21
          11 -36 25 -16 14 -51 38 -79 52 -29 15 -54 30 -57 33 -7 10 -130 50 -151 50
          -10 0 -19 4 -21 9 -3 10 -152 32 -198 30 -14 -1 -58 -5 -99 -10z"/>
          <path fill="black" d="M24991 11217 c-7 -13 -11 -37 -9 -53 3 -25 8 -29 36 -32 56 -6 76 27
          50 83 -15 33 -59 35 -77 2z"/>
          <path  fill="black" d="M24780 11153 c-27 -10 -40 -36 -40 -81 0 -49 14 -82 35 -82 8 0 15
          -4 15 -10 0 -5 18 -10 39 -10 28 0 48 8 70 27 26 23 31 34 31 75 0 40 -4 50
          -29 67 -28 20 -86 27 -121 14z"/>
          <path fill="black" d="M23053 10964 c-28 -31 -29 -68 -4 -93 11 -11 17 -22 14 -25 -4 -3 8
          -6 25 -6 18 0 32 5 32 10 0 6 7 10 15 10 21 0 39 43 31 74 -4 16 -16 28 -31
          32 -14 3 -22 10 -19 15 13 21 -43 6 -63 -17z"/>
          <path id="6" d="M29800 10981 c0 -5 -25 -19 -56 -31 -30 -13 -61 -28 -67 -34 -7 -7
          -33 -22 -59 -35 -44 -21 -62 -41 -38 -41 6 0 10 4 10 8 0 14 112 32 197 32 90
          0 186 -15 191 -30 2 -6 10 -10 18 -10 23 0 90 -37 119 -66 35 -34 21 -44 -62
          -44 -35 0 -63 -4 -63 -9 0 -4 -27 -11 -60 -14 -67 -7 -192 -37 -200 -47 -3 -4
          -29 -15 -58 -24 -29 -10 -58 -23 -65 -30 -6 -7 -34 -23 -62 -36 -27 -13 -59
          -32 -70 -42 -67 -62 -185 -180 -185 -184 0 -3 -15 -24 -34 -46 -19 -22 -42
          -57 -52 -77 -9 -20 -23 -40 -29 -46 -7 -5 -21 -31 -31 -57 -23 -59 -50 -65
          -59 -14 -4 19 -5 42 -2 53 3 12 5 8 7 -12 2 -37 18 -80 19 -52 1 9 5 17 9 17
          5 0 19 23 33 52 13 29 34 63 47 77 12 13 22 30 22 37 0 7 8 19 18 26 9 7 25
          25 35 41 15 23 108 118 182 186 11 10 40 28 64 41 25 12 51 28 58 35 7 7 36
          21 65 31 29 9 55 20 58 25 7 9 156 49 186 49 12 0 24 4 26 10 2 5 49 13 106
          16 56 4 102 9 102 12 0 22 -125 92 -164 92 -8 0 -16 6 -18 13 -6 15 -285 18
          -296 2 -4 -5 -40 -21 -79 -34 -40 -13 -73 -27 -73 -31 0 -3 -23 -17 -51 -29
          -28 -13 -53 -30 -56 -37 -6 -17 -33 -18 -33 -1 0 8 -2 9 -8 1 -4 -6 -27 -22
          -52 -34 -25 -12 -47 -26 -50 -30 -3 -5 -28 -20 -55 -34 -28 -14 -45 -26 -40
          -26 6 0 -17 -14 -50 -31 -33 -17 -62 -35 -65 -39 -3 -4 -19 -14 -37 -23 -17
          -9 -37 -22 -45 -29 -7 -7 -33 -24 -58 -37 -25 -14 -48 -28 -51 -33 -7 -10 -67
          -46 -91 -54 -10 -4 -18 -10 -18 -14 0 -5 -17 -19 -37 -31 -21 -13 -42 -29 -46
          -36 -4 -7 -12 -13 -18 -13 -11 0 -59 -33 -102 -70 -16 -14 -33 -25 -38 -25 -5
          0 -16 -10 -25 -23 -9 -13 -27 -27 -40 -32 -38 -15 -29 -38 19 -50 74 -19 107
          -31 107 -39 0 -5 36 -19 79 -32 44 -13 78 -27 76 -30 -3 -3 29 -16 70 -29 41
          -13 75 -26 75 -30 0 -4 38 -19 85 -35 47 -16 85 -32 85 -37 0 -4 15 -11 33
          -17 17 -5 47 -14 64 -19 32 -10 33 -10 43 29 6 21 15 39 20 39 4 0 11 12 15
          28 9 40 98 182 114 182 5 0 18 16 30 35 11 19 25 35 31 35 6 0 23 14 37 30 13
          17 31 30 38 30 7 0 15 6 18 14 3 8 27 24 54 37 26 13 50 27 53 31 8 10 99 48
          116 48 8 0 14 4 14 10 0 11 77 33 90 25 19 -12 -3 -32 -58 -55 -31 -13 -66
          -32 -76 -42 -11 -10 -25 -18 -32 -18 -6 0 -34 -18 -62 -40 -27 -22 -53 -40
          -56 -40 -16 0 -156 -157 -178 -199 -7 -14 -16 -28 -19 -31 -10 -8 -49 -101
          -49 -117 0 -8 -4 -12 -9 -9 -5 3 -11 -4 -15 -17 -12 -46 -7 -54 39 -67 25 -7
          45 -18 46 -24 0 -6 9 13 20 43 10 30 22 57 27 60 4 3 20 32 35 64 16 31 33 57
          38 57 5 0 12 8 16 18 3 9 31 41 62 70 81 76 92 85 126 106 16 10 32 22 35 26
          3 4 25 17 49 30 24 12 67 46 96 76 29 30 57 54 62 54 5 0 19 9 31 20 25 24
          158 70 201 70 17 0 31 5 31 10 0 6 27 10 60 10 33 0 60 -4 60 -10 0 -5 15 -10
          33 -10 35 0 167 -44 176 -58 3 -5 21 -17 39 -26 18 -9 36 -24 39 -32 3 -7 11
          -14 18 -14 7 0 20 -11 29 -25 31 -48 25 -50 -97 -47 -93 3 -128 0 -195 -18
          -45 -12 -82 -26 -82 -30 0 -5 -16 -14 -36 -20 -19 -7 -40 -20 -47 -31 -6 -10
          -17 -19 -23 -19 -11 0 -121 -105 -142 -136 -6 -11 -17 -30 -22 -43 -6 -13 -17
          -29 -25 -35 -8 -6 -21 -33 -30 -60 -26 -76 -54 -96 -76 -54 -7 13 -18 28 -25
          35 -13 14 -44 109 -44 137 0 9 -4 16 -10 16 -6 0 -10 35 -10 86 0 54 4 83 10
          79 6 -3 10 3 10 14 0 26 -10 26 -56 -1 -41 -24 -174 -150 -174 -165 0 -5 -9
          -19 -19 -30 -19 -20 -61 -131 -61 -159 0 -7 -4 -14 -9 -16 -5 -1 -11 -21 -13
          -42 -3 -36 -1 -40 26 -47 16 -5 32 -12 35 -17 3 -4 39 -19 79 -32 39 -14 72
          -28 72 -31 0 -4 32 -17 70 -29 39 -12 70 -25 70 -29 0 -7 115 -51 132 -51 4 0
          9 14 13 30 4 17 10 30 14 30 4 0 18 23 30 50 12 28 27 50 32 50 5 0 9 4 9 10
          0 11 36 66 65 97 68 76 116 116 165 138 25 11 48 23 51 28 14 20 129 41 224
          40 96 0 225 -25 225 -43 0 -4 23 -17 51 -29 27 -12 52 -28 55 -36 3 -8 14 -17
          25 -20 10 -4 19 -10 19 -15 0 -17 -32 -19 -97 -5 -75 16 -143 19 -143 7 0 -11
          39 -44 75 -63 35 -19 75 -51 116 -91 15 -16 30 -28 35 -28 4 0 30 -24 59 -52
          28 -29 65 -63 82 -75 l31 -22 6 37 c13 80 6 281 -14 398 -11 65 -24 121 -29
          124 -4 3 -18 37 -31 75 -13 39 -26 72 -30 75 -4 3 -19 31 -34 63 -15 31 -31
          57 -35 57 -4 0 -14 12 -22 28 -27 52 -213 264 -228 259 -3 -1 -22 13 -41 32
          -58 54 -66 61 -76 61 -6 0 -23 12 -40 28 -16 15 -51 38 -79 51 -27 14 -52 27
          -55 30 -7 9 -71 38 -97 46 -13 3 -23 10 -23 14 0 5 -30 19 -67 31 -38 12 -70
          26 -73 30 -6 8 -151 46 -217 56 -24 4 -40 10 -37 15 3 5 -2 9 -10 9 -9 0 -16
          -4 -16 -9z m-524 -347 c-42 -41 -76 -79 -76 -83 0 -4 -9 -18 -20 -30 -10 -13
          -27 -44 -37 -70 -9 -25 -21 -49 -25 -52 -7 -5 -13 -32 -37 -169 l-10 -55 5 65
          c6 75 31 192 43 200 4 3 18 27 30 53 25 51 41 72 111 146 38 40 75 69 88 71 2
          0 -30 -34 -72 -76z m1134 26 c0 -5 11 -10 24 -10 31 0 108 -27 121 -43 12 -14
          44 -36 65 -43 8 -3 29 -27 45 -54 17 -27 33 -50 36 -50 4 0 10 -10 14 -22 4
          -13 8 -25 9 -28 27 -68 33 -148 12 -156 -19 -7 -56 13 -79 43 -10 12 -25 23
          -33 23 -8 0 -17 6 -20 14 -3 8 -33 26 -67 41 -34 16 -64 31 -67 35 -4 6 -79
          26 -165 44 -13 2 -9 4 10 5 89 3 273 -82 369 -171 47 -45 60 -38 52 27 -6 53
          -23 104 -38 115 -4 3 -11 14 -15 25 -21 49 -51 86 -90 111 -24 15 -43 30 -43
          34 0 20 -112 44 -230 48 -151 5 -223 -14 -288 -75 -60 -57 -98 -145 -55 -129
          9 4 42 9 74 13 32 3 56 10 53 14 -3 5 38 9 91 9 62 0 94 -4 90 -10 -3 -5 -41
          -10 -83 -10 -42 -1 -113 -7 -157 -15 -49 -9 -83 -11 -89 -5 -18 18 15 93 58
          133 49 46 79 63 129 72 20 4 37 11 37 16 0 5 52 9 115 9 70 0 115 -4 115 -10z"/>
          <path fill="black" d="M22795 10928 c-43 -26 -65 -62 -65 -108 0 -69 52 -120 120 -120 42 0
          53 5 84 36 31 31 36 42 36 83 0 38 -6 54 -30 81 -24 27 -38 34 -77 37 -27 2
          -57 -2 -68 -9z"/>
          <path d="M26188 10543 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
          <path d="M26250 10530 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10
          -25 0 -45 -4 -45 -10z"/>
          <path fill="black" d="M30115 10389 c-66 -11 -155 -39 -155 -49 0 -4 -8 -10 -17 -13 -30
          -10 -77 -41 -92 -61 -8 -11 -23 -28 -33 -38 -10 -10 -27 -46 -39 -80 -18 -52
          -20 -78 -16 -153 5 -93 30 -184 54 -198 7 -4 13 -14 13 -21 0 -8 7 -19 15 -26
          12 -10 20 1 51 69 21 44 40 79 42 76 3 -2 13 12 23 33 34 68 154 182 214 204
          19 6 35 15 35 18 0 11 41 27 103 40 31 7 57 17 57 22 0 4 50 8 110 8 67 0 110
          -4 110 -10 0 -5 5 -10 10 -10 21 0 8 24 -37 66 -25 24 -51 44 -58 44 -6 0 -20
          8 -31 18 -58 53 -229 83 -359 61z"/>
          <path fill="black" d="M27711 10353 c-26 -100 -35 -87 80 -112 57 -12 106 -25 109 -30 3 -4
          49 -19 103 -32 53 -12 97 -27 97 -31 0 -4 7 -8 15 -8 31 0 13 16 -45 39 -33
          13 -60 27 -60 31 0 3 -27 19 -60 34 -33 15 -60 32 -60 36 0 5 -16 17 -36 26
          -20 9 -38 23 -41 31 -3 7 -11 13 -18 13 -6 0 -20 9 -30 20 -27 30 -43 24 -54
          -17z"/>
          <path id="7"  d="M12490 9973 c-30 -3 -57 -8 -60 -12 -3 -4 -94 -18 -202 -31 -108 -13
          -195 -27 -193 -30 2 -3 -92 -19 -208 -35 -117 -16 -214 -32 -217 -36 -3 -3
          -77 -17 -165 -29 -88 -13 -162 -27 -165 -30 -3 -4 -72 -18 -155 -30 -82 -13
          -152 -26 -155 -30 -3 -4 -81 -19 -175 -34 -93 -16 -172 -32 -175 -36 -3 -4
          -67 -17 -142 -30 -76 -13 -138 -27 -138 -32 0 -4 -10 -8 -22 -8 -13 0 -45 -6
          -73 -14 l-50 -13 53 -7 c28 -5 52 -12 52 -16 0 -5 12 -11 28 -13 20 -3 17 -4
          -13 -5 -63 -3 -295 -45 -295 -54 0 -4 -8 -8 -17 -8 -29 0 -248 -43 -253 -50
          -3 -4 -72 -19 -155 -35 -82 -15 -152 -31 -155 -34 -3 -4 -61 -17 -130 -30 -69
          -13 -127 -27 -130 -31 -3 -3 -57 -17 -120 -30 -63 -13 -117 -26 -120 -30 -3
          -3 -68 -19 -145 -35 -77 -16 -142 -32 -145 -36 -3 -3 -50 -17 -105 -29 -55
          -12 -102 -26 -105 -29 -3 -4 -49 -18 -102 -31 -54 -13 -98 -27 -98 -31 0 -3
          -25 -12 -55 -19 -51 -12 -55 -15 -55 -42 0 -38 20 -118 30 -118 4 0 10 -8 12
          -18 4 -15 23 -21 104 -31 54 -6 144 -15 199 -18 55 -3 103 -11 107 -17 11 -17
          1290 -15 1296 2 2 8 29 12 85 12 170 1 837 49 837 60 0 4 41 10 91 14 89 7
          209 32 219 45 3 4 43 17 90 30 47 13 87 26 90 30 3 4 50 20 105 36 55 16 101
          32 103 36 2 5 36 18 75 30 40 13 74 26 77 31 4 4 33 17 65 28 32 11 61 24 65
          28 3 4 39 21 80 37 41 16 81 35 89 42 7 8 26 17 42 20 15 3 36 13 46 22 10 10
          40 26 66 36 26 10 51 23 55 30 4 6 33 24 65 39 31 14 57 30 57 35 0 5 13 14
          29 21 16 6 51 31 78 55 26 25 54 44 61 45 6 0 12 6 12 13 0 7 15 27 33 44 19
          17 41 47 50 65 9 18 17 29 17 23 0 -14 33 42 50 85 8 19 18 38 24 42 5 3 11
          36 12 72 1 35 5 69 9 75 3 6 3 20 0 31 -7 21 -22 22 -165 13z m150 -123 c0 -5
          -4 -10 -8 -10 -5 0 -15 -18 -22 -40 -7 -23 -24 -52 -37 -66 -13 -14 -23 -29
          -23 -33 0 -4 -9 -16 -20 -26 -11 -10 -20 -21 -20 -26 0 -10 -65 -74 -102 -100
          -16 -12 -41 -32 -55 -45 -14 -13 -31 -24 -38 -24 -6 0 -20 -8 -31 -18 -10 -9
          -40 -28 -66 -42 -27 -14 -48 -28 -48 -31 0 -3 -30 -19 -67 -34 -38 -16 -70
          -32 -73 -36 -6 -9 -217 -109 -229 -109 -5 0 -11 -5 -13 -12 -5 -14 -88 -14
          -97 0 -3 5 -64 19 -136 32 -71 13 -132 26 -135 30 -3 5 -14 10 -25 13 -11 2 0
          2 25 0 25 -3 47 -8 50 -12 3 -4 64 -18 135 -30 72 -13 133 -29 138 -35 5 -8 9
          -8 15 1 4 6 34 22 67 34 33 12 62 25 65 29 3 4 30 18 60 31 30 12 57 27 61 31
          3 4 33 19 67 33 34 14 71 34 82 45 11 11 26 20 33 20 7 0 22 8 33 18 10 9 41
          28 67 42 27 14 53 33 58 43 5 9 15 17 21 17 12 0 147 137 184 187 12 15 32 52
          45 81 22 48 39 66 39 42z m-2247 -357 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14
          0 19 -2 13 -5z m136 -20 c53 -9 98 -20 101 -24 3 -4 49 -15 103 -25 53 -9 95
          -19 92 -22 -6 -6 -249 40 -255 48 -3 4 -41 14 -85 22 -44 9 -74 16 -66 17 8 0
          58 -7 110 -16z m363 -81 c2 -4 73 -21 158 -37 85 -16 157 -32 160 -36 3 -4 38
          -12 78 -19 40 -7 71 -14 69 -17 -7 -6 -201 29 -207 38 -3 3 -74 19 -157 35
          -84 16 -153 32 -153 36 0 10 47 10 52 0z"/>
          <path id="8"  d="M28285 9874 c-11 -3 -60 -12 -110 -21 -49 -9 -92 -19 -95 -23 -3 -4
          -44 -18 -92 -31 -49 -13 -88 -27 -88 -30 0 -4 -29 -17 -65 -29 -36 -12 -65
          -25 -65 -29 0 -3 -29 -19 -65 -35 -36 -15 -65 -31 -65 -35 0 -4 -22 -18 -50
          -31 -27 -13 -50 -26 -50 -30 0 -3 -18 -16 -40 -29 -22 -12 -40 -26 -40 -30 0
          -4 -18 -17 -40 -30 -22 -12 -43 -28 -47 -35 -5 -7 -15 -17 -25 -22 -64 -35
          -338 -312 -360 -363 -4 -9 -7 -12 -7 -8 -1 10 -51 -47 -51 -58 0 -4 -11 -16
          -25 -27 -14 -11 -25 -24 -25 -30 0 -6 -10 -21 -22 -33 -20 -18 -56 -75 -58
          -90 0 -2 -14 -19 -30 -37 -17 -18 -30 -38 -30 -45 0 -7 -4 -13 -10 -13 -5 0
          -19 -20 -31 -45 -12 -25 -24 -45 -28 -45 -4 0 -21 -27 -37 -60 -16 -33 -32
          -60 -36 -60 -4 0 -17 -22 -29 -50 -12 -27 -25 -50 -29 -50 -4 0 -18 -26 -31
          -57 -12 -32 -26 -60 -30 -63 -4 -3 -20 -34 -35 -70 -16 -36 -31 -67 -35 -70
          -9 -8 -49 -101 -49 -116 0 -8 -4 -14 -9 -14 -5 0 -20 -29 -33 -65 -12 -36 -26
          -65 -30 -65 -3 0 -16 -34 -29 -75 -12 -41 -25 -75 -29 -75 -4 0 -20 -46 -35
          -102 -16 -57 -32 -105 -36 -108 -3 -3 -17 -52 -30 -110 -12 -58 -26 -107 -30
          -110 -3 -3 -17 -63 -30 -135 -13 -71 -26 -132 -30 -135 -9 -7 -49 -328 -56
          -457 -3 -57 -10 -103 -15 -103 -4 0 -8 -74 -8 -165 0 -91 4 -165 9 -165 4 0
          11 -48 14 -107 8 -143 47 -437 58 -441 5 -2 9 -13 9 -23 0 -31 42 -233 49
          -239 4 -3 15 -41 24 -84 9 -43 19 -82 23 -85 9 -9 478 -1 484 8 3 4 59 11 125
          15 169 9 549 47 555 56 3 3 91 17 195 30 105 12 192 26 195 30 3 4 75 17 160
          30 85 12 157 26 160 29 3 4 79 20 170 35 90 15 167 30 170 35 3 4 64 18 135
          30 72 13 132 27 135 31 3 4 55 18 115 30 61 11 112 25 113 31 2 5 11 9 19 9
          25 0 226 50 258 64 17 7 62 22 100 32 39 10 72 21 75 24 3 3 43 16 90 29 47
          13 87 26 90 31 3 4 46 19 95 34 50 14 92 31 93 36 2 6 9 10 16 10 22 0 146 42
          146 49 0 4 42 20 94 36 52 16 93 32 91 35 -2 4 27 17 65 29 39 13 70 27 70 31
          0 5 25 16 56 26 31 9 62 24 70 31 12 12 72 39 136 60 10 3 15 9 12 14 -3 5 0
          9 7 9 13 0 101 40 109 49 3 4 29 17 58 30 28 14 52 27 52 31 0 3 29 19 64 35
          36 16 63 32 61 36 -3 3 14 14 36 24 22 10 43 23 47 29 4 6 27 22 52 35 25 14
          51 33 58 43 7 10 19 18 25 18 13 0 58 34 109 82 15 14 32 28 37 30 29 10 176
          150 241 228 19 23 42 50 50 59 9 9 27 35 40 56 13 22 31 48 40 57 8 9 24 39
          35 67 11 28 23 51 27 51 4 0 17 33 29 73 12 39 25 74 29 77 12 9 30 146 30
          227 0 95 -17 217 -30 221 -6 2 -10 12 -10 22 0 30 -40 150 -50 150 -6 0 -10 6
          -10 13 0 14 -48 119 -58 127 -10 7 -42 62 -49 83 -3 9 -9 17 -13 17 -5 0 -19
          17 -31 38 -13 20 -31 43 -41 50 -10 7 -18 18 -18 25 0 12 -6 18 -167 180 -134
          135 -168 167 -177 167 -6 0 -116 87 -126 100 -3 3 -23 17 -45 30 -22 13 -44
          29 -50 36 -5 6 -30 22 -55 35 -24 13 -53 32 -63 42 -10 10 -32 23 -48 29 -16
          5 -29 14 -29 18 0 5 -21 19 -47 32 -27 13 -56 30 -66 39 -10 9 -38 24 -63 34
          -24 9 -44 20 -44 25 0 4 -25 18 -55 30 -30 12 -55 25 -55 29 0 7 -93 51 -109
          51 -6 0 -11 4 -11 9 0 5 -27 19 -61 31 -33 13 -66 28 -72 34 -15 14 -114 56
          -133 56 -8 0 -14 4 -14 9 0 9 -94 51 -116 51 -7 0 -14 4 -16 10 -1 5 -34 21
          -73 35 -38 14 -72 29 -75 33 -3 4 -32 17 -64 27 -33 11 -64 25 -70 30 -6 6
          -49 23 -96 39 -47 16 -87 32 -90 36 -3 4 -34 18 -70 31 -36 12 -67 26 -70 29
          -3 4 -36 17 -75 30 -38 13 -72 26 -75 30 -3 4 -43 20 -90 35 -47 15 -87 31
          -90 35 -3 5 -39 18 -80 31 -41 12 -77 25 -80 29 -3 4 -39 18 -80 31 -41 13
          -77 26 -80 30 -7 9 -141 49 -159 48 -9 -1 -25 -3 -36 -5z"/>
          <path fill="black" d="M30498 9783 c-10 -2 -18 -9 -18 -14 0 -5 -5 -9 -12 -9 -18 0 -101
          -42 -122 -62 -11 -10 -24 -18 -29 -18 -8 0 -94 -85 -127 -126 -8 -10 -23 -34
          -34 -54 -10 -19 -21 -37 -25 -40 -9 -7 -31 -82 -26 -89 2 -3 26 -15 54 -26 28
          -11 51 -22 51 -26 0 -4 26 -17 58 -30 31 -12 59 -26 62 -30 3 -4 26 -17 52
          -28 25 -10 47 -23 49 -28 2 -4 28 -19 58 -33 30 -14 59 -30 65 -36 6 -5 32
          -20 59 -33 26 -12 47 -27 47 -31 0 -5 10 -12 22 -16 21 -6 82 -45 187 -117 24
          -17 61 -32 85 -34 48 -6 150 13 168 30 7 7 25 18 41 24 15 7 27 17 27 23 0 6
          5 8 10 5 6 -4 10 12 10 39 0 25 -4 46 -8 46 -5 0 -15 18 -22 41 -8 22 -21 46
          -30 52 -10 7 -24 26 -34 42 -9 17 -22 38 -29 48 -53 78 -385 407 -446 441 -9
          6 -24 20 -34 33 -10 13 -25 23 -32 23 -8 0 -19 9 -25 20 -11 20 -19 22 -52 13z"/>
          <path fill="black" d="M18460 7758 c-63 -6 -117 -13 -120 -17 -3 -5 -27 -11 -55 -14 -110
          -15 -275 -52 -272 -61 2 -6 82 -19 178 -29 96 -11 176 -23 179 -27 3 -4 28
          -10 55 -14 85 -11 280 -50 285 -56 3 -4 52 -17 110 -30 58 -12 107 -26 110
          -30 3 -4 46 -18 95 -30 50 -13 92 -26 95 -31 3 -4 46 -19 95 -34 50 -15 92
          -31 95 -35 5 -9 78 -38 118 -47 12 -3 22 -9 22 -13 0 -5 29 -18 65 -30 36 -12
          65 -25 65 -29 0 -4 31 -20 70 -37 38 -16 70 -32 70 -35 0 -4 23 -16 50 -28 28
          -12 50 -25 50 -29 0 -5 23 -19 50 -31 28 -12 50 -26 50 -30 0 -4 25 -20 55
          -36 30 -15 55 -32 55 -36 0 -5 13 -15 29 -24 16 -8 35 -20 43 -28 7 -7 29 -24
          48 -37 19 -14 42 -32 52 -40 9 -8 36 -31 58 -50 83 -69 276 -270 332 -345 49
          -65 45 -63 122 -57 39 3 73 8 76 12 3 3 111 14 240 24 282 20 310 24 310 41 0
          7 -11 20 -25 29 -14 9 -25 23 -25 31 0 7 -11 22 -25 33 -14 11 -25 24 -25 28
          0 5 -13 23 -29 39 -17 17 -54 56 -83 89 -29 32 -67 72 -85 88 -17 17 -58 56
          -91 87 -32 31 -75 70 -96 86 -20 17 -49 41 -64 55 -15 14 -34 30 -43 35 -31
          20 -118 86 -121 93 -2 4 -23 18 -48 31 -25 13 -50 29 -55 35 -12 13 -41 32
          -75 49 -14 6 -34 20 -44 29 -11 10 -25 18 -31 18 -6 0 -20 8 -31 18 -10 9 -48
          30 -84 46 -36 16 -67 32 -70 36 -3 3 -31 17 -62 29 -32 13 -58 27 -58 31 0 4
          -33 18 -72 31 -40 13 -75 27 -78 31 -7 9 -155 58 -175 58 -8 0 -15 4 -15 9 0
          5 -44 20 -97 32 -54 13 -101 27 -104 31 -5 8 -284 58 -324 58 -13 0 -25 4 -27
          9 -9 25 -485 38 -718 19z"/>
          <path id="8-1" d="M15675 7688 c-110 -5 -202 -13 -205 -17 -3 -4 -54 -11 -115 -15 -147
          -8 -409 -37 -415 -45 -3 -4 -72 -20 -155 -35 -82 -16 -152 -32 -155 -36 -3 -4
          -49 -17 -102 -30 -54 -13 -98 -26 -98 -30 0 -4 -40 -18 -90 -31 -49 -12 -90
          -26 -90 -30 0 -4 -38 -19 -85 -33 -47 -15 -85 -30 -85 -34 0 -4 -34 -18 -75
          -31 -41 -13 -75 -27 -75 -32 0 -4 -17 -13 -37 -19 -21 -7 -42 -16 -47 -21 -6
          -5 -18 -9 -29 -9 -10 0 -16 -4 -13 -9 3 -5 -13 -16 -37 -26 -23 -9 -58 -29
          -77 -44 -19 -15 -46 -34 -60 -41 -14 -7 -31 -21 -38 -32 -7 -10 -19 -18 -27
          -18 -8 0 -21 -10 -30 -22 -8 -13 -19 -23 -23 -24 -12 -1 -82 -46 -82 -52 0 -4
          -17 -18 -37 -32 -20 -14 -47 -34 -59 -45 -72 -64 -191 -155 -201 -155 -6 0
          -18 -11 -27 -25 -9 -14 -23 -25 -31 -25 -7 0 -22 -10 -32 -23 -10 -12 -35 -33
          -55 -46 -21 -12 -38 -26 -38 -29 0 -4 -18 -18 -40 -32 -22 -14 -40 -28 -40
          -32 0 -4 -18 -19 -39 -32 -22 -14 -51 -35 -65 -48 -14 -13 -39 -34 -56 -48
          -17 -14 -41 -34 -54 -45 -12 -11 -42 -33 -67 -50 -24 -16 -52 -40 -62 -52 -10
          -13 -25 -23 -32 -23 -8 0 -19 -9 -25 -20 -6 -11 -17 -20 -25 -20 -7 0 -22 -10
          -32 -23 -10 -13 -27 -27 -38 -32 -13 -6 32 -11 139 -16 155 -7 316 -26 326
          -38 3 -4 50 -16 104 -29 55 -12 102 -26 105 -30 3 -5 30 -17 59 -26 29 -10 59
          -24 66 -32 8 -8 20 -14 26 -14 9 0 -20 -30 -107 -108 -12 -11 -39 -33 -60 -49
          -20 -15 -45 -35 -54 -43 -9 -8 -34 -28 -54 -45 -21 -16 -50 -42 -65 -57 -15
          -16 -32 -28 -37 -28 -4 0 -30 -21 -58 -45 -27 -25 -53 -45 -56 -45 -3 0 -20
          -12 -37 -27 -35 -32 -76 -66 -142 -118 -25 -20 -62 -50 -84 -68 -21 -18 -43
          -35 -50 -39 -16 -10 -76 -60 -98 -81 -10 -10 -31 -25 -46 -35 -28 -17 -42 -28
          -102 -83 -17 -16 -34 -29 -36 -29 -10 0 -69 -46 -87 -68 -10 -12 -23 -20 -27
          -17 -5 3 -16 -6 -25 -19 -9 -14 -24 -28 -33 -30 -10 -3 -28 -16 -42 -29 -14
          -12 -38 -33 -55 -45 -64 -48 -89 -68 -124 -99 -20 -18 -42 -33 -48 -33 -7 0
          -20 -11 -29 -25 -9 -14 -22 -25 -29 -25 -8 0 -22 -11 -33 -25 -11 -14 -23 -25
          -27 -25 -9 0 -83 -56 -138 -105 -13 -11 -36 -29 -52 -39 -17 -11 -39 -27 -50
          -37 -54 -50 -76 -69 -80 -69 -10 0 -81 -53 -88 -66 -4 -8 -14 -14 -22 -14 -7
          0 -22 -10 -32 -22 -10 -13 -38 -36 -63 -52 -24 -16 -56 -40 -69 -53 -14 -12
          -29 -23 -32 -23 -3 0 -20 -12 -37 -27 -33 -30 -58 -50 -112 -88 -39 -28 -67
          -50 -125 -100 -22 -19 -45 -35 -52 -35 -7 0 -17 -9 -23 -20 -6 -12 -21 -25
          -33 -30 -28 -13 -54 -30 -82 -55 -58 -52 -72 -63 -100 -81 -16 -10 -35 -25
          -40 -32 -6 -7 -20 -18 -31 -25 -27 -15 -103 -72 -129 -95 -11 -11 -33 -27 -50
          -38 -16 -10 -36 -27 -43 -36 -7 -10 -17 -18 -23 -18 -5 0 -33 -19 -63 -42 -30
          -24 -64 -51 -77 -62 -13 -10 -38 -27 -56 -38 -17 -10 -34 -25 -37 -33 -3 -8
          -11 -15 -18 -15 -7 0 -24 -10 -38 -23 -14 -12 -41 -34 -60 -47 -19 -13 -46
          -35 -60 -47 -14 -13 -30 -23 -37 -23 -6 0 -18 -8 -25 -18 -7 -10 -30 -28 -50
          -40 -20 -13 -38 -26 -40 -30 -5 -10 -66 -52 -77 -52 -5 0 -11 -7 -14 -15 -4
          -8 -24 -23 -47 -35 -22 -11 -40 -24 -40 -30 0 -5 -20 -19 -45 -31 -25 -12 -45
          -26 -45 -30 0 -4 -15 -17 -33 -28 l-33 -20 -64 22 c-36 12 -66 25 -68 29 -2 4
          -36 17 -75 29 -40 12 -74 25 -77 29 -5 8 -216 60 -242 60 -9 0 -18 4 -20 9 -5
          15 -258 41 -391 41 l-119 0 -28 -30 c-15 -16 -33 -30 -38 -30 -6 0 -22 -13
          -35 -30 -14 -16 -29 -30 -34 -30 -9 0 -72 -57 -174 -156 -37 -35 -69 -64 -72
          -64 -4 0 -81 -74 -172 -166 -165 -165 -173 -177 -159 -230 5 -20 46 -46 94
          -60 14 -4 27 -10 30 -15 11 -17 200 -40 373 -46 97 -3 177 -10 177 -15 0 -4
          27 -8 61 -8 37 0 58 4 54 10 -4 6 42 10 132 10 205 0 693 36 693 50 0 4 33 10
          73 14 162 14 436 50 432 56 -2 3 71 16 162 28 92 12 171 26 177 31 6 5 76 18
          156 31 80 12 147 25 150 29 3 3 82 19 175 35 94 15 172 31 175 35 3 4 64 18
          135 31 72 13 132 26 135 29 3 4 75 20 160 36 85 16 157 32 160 35 3 4 59 17
          125 29 66 13 122 26 125 30 3 4 61 17 130 30 69 13 127 27 130 30 3 4 70 20
          150 35 80 16 147 32 150 36 3 4 57 18 120 30 63 12 117 26 120 30 3 4 52 17
          110 30 58 12 107 25 110 29 3 4 61 19 130 34 68 15 127 31 130 35 3 4 52 18
          110 31 58 13 107 26 110 31 3 4 48 17 100 30 52 13 97 26 100 29 3 4 57 20
          120 36 63 16 117 32 120 36 3 4 46 18 95 30 50 12 92 26 95 30 3 4 43 17 90
          29 47 13 87 26 90 30 3 4 52 20 110 35 58 14 107 30 110 34 3 3 41 18 85 31
          44 13 82 27 85 30 3 4 43 17 90 30 47 12 95 29 106 36 12 7 57 23 100 35 44
          13 82 27 85 31 3 5 37 17 75 28 38 11 71 25 72 30 2 6 9 10 16 10 20 0 166 48
          179 59 7 5 47 19 90 32 42 12 77 25 77 30 0 4 33 17 73 29 39 12 74 25 77 30
          3 4 43 20 90 35 47 15 87 31 90 35 3 4 37 17 75 30 39 13 72 26 75 30 3 4 35
          17 72 29 37 12 68 26 70 30 2 4 41 21 88 36 47 15 87 31 90 36 5 8 79 37 118
          46 12 3 22 9 22 13 0 5 16 14 36 20 32 11 35 15 32 48 -2 29 -8 37 -33 45 -16
          6 -32 14 -35 18 -4 5 -33 18 -65 29 -32 11 -61 24 -65 28 -3 5 -41 21 -85 37
          -44 16 -82 32 -85 35 -3 4 -34 17 -70 29 -36 13 -67 26 -70 30 -3 4 -34 18
          -70 31 -36 13 -67 27 -70 30 -4 6 -96 43 -142 57 -10 3 -18 9 -18 13 0 4 -29
          18 -65 31 -36 12 -65 26 -65 30 0 4 -16 13 -37 20 -20 6 -74 31 -121 55 -47
          24 -91 44 -99 44 -8 0 -12 4 -9 8 3 5 -21 19 -52 32 -31 13 -59 26 -62 30 -3
          4 -27 17 -55 31 -27 13 -54 29 -60 36 -5 6 -31 21 -57 33 -27 12 -48 25 -48
          29 0 4 -19 17 -43 29 -24 12 -52 31 -62 42 -10 11 -24 20 -32 20 -7 0 -21 8
          -31 18 -10 10 -36 28 -57 41 -22 13 -44 29 -50 35 -5 6 -23 22 -40 34 -72 54
          -105 84 -231 207 -74 72 -134 135 -134 141 0 7 -11 19 -25 28 -14 9 -25 22
          -25 29 0 7 -12 26 -28 43 -15 16 -36 47 -47 69 -11 22 -23 42 -27 45 -10 8
          -48 99 -48 116 0 8 -4 14 -9 14 -17 0 -43 144 -43 240 0 90 25 240 41 240 3 0
          12 20 20 44 7 24 21 53 32 65 10 11 19 23 19 26 0 13 48 71 106 129 60 60 81
          75 137 104 15 7 27 17 27 21 0 4 26 18 58 31 31 13 59 27 62 30 3 4 46 20 95
          36 50 16 92 32 96 36 3 4 52 17 110 29 57 12 106 25 109 29 5 8 183 36 295 46
          39 4 72 11 75 15 3 4 139 13 302 20 235 10 376 10 684 0 225 -7 390 -16 392
          -21 2 -6 29 -10 60 -10 67 0 425 -28 464 -36 27 -5 77 20 78 39 0 12 104 130
          170 193 53 51 73 69 152 137 21 18 52 44 68 60 17 15 34 27 39 27 4 0 25 15
          46 34 21 19 50 40 64 48 14 7 36 23 49 36 14 12 31 22 38 22 7 0 17 6 21 14 4
          7 33 25 63 39 37 18 48 27 33 27 -13 0 -23 4 -23 8 0 9 -243 38 -410 49 -52 3
          -97 9 -100 13 -17 25 -852 37 -1265 18z"/>
          <path id="9" d="M17741 7575 c-36 -14 -67 -28 -70 -32 -3 -4 -28 -19 -56 -32 -27 -14
          -52 -27 -55 -30 -3 -3 -25 -17 -50 -31 -25 -13 -54 -32 -64 -42 -11 -10 -25
          -18 -31 -18 -7 0 -23 -11 -37 -24 -14 -12 -32 -26 -39 -30 -38 -19 -68 -41
          -128 -96 -9 -8 -32 -26 -51 -40 -37 -26 -72 -56 -153 -132 -28 -27 -54 -48
          -57 -48 -4 0 -36 -28 -71 -62 l-64 -63 90 -7 c148 -11 320 -31 325 -38 5 -6
          296 -50 331 -50 9 0 31 13 48 30 18 16 48 39 67 50 18 12 34 25 34 30 0 5 21
          19 48 32 26 13 55 30 64 38 9 8 39 24 67 35 28 11 51 22 51 25 0 4 33 17 73
          30 39 13 74 27 77 30 6 9 171 50 200 50 12 0 18 5 15 10 -3 6 4 10 17 11 13 0
          50 4 83 8 116 15 125 -27 12 -55 -40 -9 -74 -20 -77 -24 -3 -4 -39 -17 -80
          -29 -41 -13 -77 -26 -80 -30 -3 -4 -36 -20 -75 -36 -38 -16 -76 -35 -83 -43
          -8 -7 -29 -19 -48 -27 -18 -7 -43 -23 -56 -34 -12 -12 -34 -27 -50 -33 -15 -7
          -28 -16 -28 -20 0 -3 -20 -19 -45 -35 -25 -15 -45 -33 -45 -38 0 -6 57 -19
          128 -29 70 -10 129 -22 132 -25 3 -4 79 -20 170 -35 91 -15 167 -31 170 -35 3
          -5 60 -19 127 -31 67 -13 120 -27 118 -31 -3 -3 14 -10 38 -13 69 -11 182 -39
          187 -47 3 -4 43 -17 90 -28 47 -12 93 -24 102 -27 13 -4 46 23 116 93 54 54
          110 104 124 111 14 8 38 25 54 39 16 14 46 32 67 40 20 8 37 18 37 23 0 14
          157 52 237 58 46 2 110 0 145 -7 74 -14 158 -42 158 -52 0 -4 15 -12 33 -18
          17 -6 45 -22 62 -37 16 -14 44 -34 62 -45 18 -11 33 -24 33 -28 0 -4 14 -15
          30 -24 30 -18 39 -40 20 -52 -5 -3 -19 6 -32 20 -12 14 -27 25 -34 25 -7 0
          -19 9 -27 20 -7 11 -40 32 -73 46 -32 15 -61 30 -64 33 -24 28 -199 53 -290
          42 -84 -11 -150 -29 -150 -41 0 -6 -6 -10 -12 -10 -23 0 -111 -49 -125 -70 -8
          -11 -19 -20 -25 -20 -6 0 -47 -38 -92 -84 -113 -117 -120 -126 -91 -126 8 0
          15 -4 15 -9 0 -4 28 -19 63 -31 34 -12 64 -26 67 -30 3 -3 32 -20 65 -36 33
          -16 69 -37 79 -46 11 -10 23 -18 27 -18 13 0 119 -82 119 -91 0 -5 6 -9 13 -9
          8 0 46 -31 84 -69 55 -54 75 -68 92 -64 11 3 21 9 21 13 0 4 28 18 63 31 34
          12 64 26 67 30 3 4 34 19 70 34 36 15 67 30 70 34 3 3 55 31 115 61 61 30 112
          58 115 61 3 3 32 19 65 35 33 16 69 37 79 46 11 10 44 20 77 24 47 5 56 9 51
          23 -6 16 -257 268 -329 329 -21 18 -53 45 -70 61 -18 15 -45 34 -60 42 -16 8
          -34 22 -41 32 -7 9 -19 17 -25 17 -7 0 -27 14 -45 30 -18 17 -37 30 -41 30 -4
          0 -16 8 -27 18 -10 9 -44 30 -74 46 -30 16 -57 32 -60 36 -3 3 -27 17 -55 30
          -27 13 -54 29 -60 35 -5 6 -30 20 -55 30 -25 11 -47 23 -50 27 -7 10 -99 48
          -115 48 -8 0 -20 6 -27 13 -7 7 -42 24 -78 37 -36 13 -67 27 -70 31 -3 3 -39
          17 -80 30 -41 12 -77 26 -80 30 -3 4 -52 19 -110 35 -58 15 -107 31 -110 34
          -3 4 -54 17 -115 30 -60 13 -112 27 -115 30 -3 4 -70 18 -150 31 -80 13 -147
          27 -150 30 -9 12 -488 60 -575 58 -11 0 -49 -11 -84 -24z"/>
          <path id="10" d="M15215 6893 c-134 -6 -440 -35 -445 -42 -3 -5 -32 -11 -65 -15 -85
          -9 -289 -48 -295 -55 -3 -4 -45 -17 -95 -30 -49 -12 -91 -26 -93 -31 -2 -6 -9
          -10 -15 -10 -16 0 -151 -52 -157 -60 -3 -4 -24 -17 -47 -29 -24 -12 -43 -25
          -43 -29 0 -4 -13 -13 -30 -20 -39 -18 -125 -103 -150 -149 -11 -21 -20 -33
          -20 -28 -1 32 -50 -108 -50 -141 0 -13 -4 -24 -10 -24 -5 0 -10 -22 -10 -50 0
          -27 5 -50 10 -50 6 0 10 -13 10 -30 0 -41 41 -161 62 -182 10 -10 18 -24 18
          -31 0 -7 8 -22 18 -33 24 -28 62 -82 62 -89 0 -4 15 -21 33 -40 17 -18 60 -63
          95 -99 34 -36 66 -66 71 -66 4 0 24 -17 44 -37 50 -51 75 -73 128 -108 24 -16
          50 -38 57 -47 7 -10 19 -18 27 -18 8 0 20 -8 27 -18 7 -10 33 -29 57 -43 25
          -13 54 -32 65 -42 28 -25 82 -61 131 -88 22 -12 42 -25 45 -29 3 -4 24 -17 46
          -28 23 -12 48 -27 55 -34 8 -7 37 -24 64 -37 28 -14 55 -30 60 -36 6 -6 30
          -20 55 -30 25 -11 48 -25 52 -31 4 -6 29 -19 55 -29 26 -10 57 -26 68 -36 11
          -9 40 -25 65 -35 25 -10 50 -23 57 -30 7 -6 35 -20 63 -30 27 -10 50 -21 50
          -25 0 -4 33 -20 73 -35 39 -16 74 -31 77 -35 3 -4 30 -17 60 -30 30 -12 57
          -26 60 -30 3 -4 34 -17 70 -30 36 -12 67 -25 70 -29 5 -8 145 -60 159 -60 4 0
          11 87 14 193 6 208 27 447 38 447 3 0 9 27 13 59 12 109 46 295 55 301 4 3 11
          23 15 45 11 64 38 169 46 180 4 6 17 48 30 95 13 47 27 87 31 90 4 3 20 44 35
          90 14 47 30 87 34 90 4 3 18 35 31 73 12 37 26 67 29 67 4 0 18 27 31 60 13
          33 27 60 30 60 4 0 17 26 30 58 13 31 29 63 37 71 7 8 25 37 38 65 35 74 76
          140 91 149 7 4 13 14 13 21 0 7 11 25 25 40 14 15 25 31 25 35 0 5 7 14 15 21
          8 7 15 20 15 30 0 15 -19 18 -142 29 -277 23 -497 31 -848 29 -195 -1 -386 -3
          -425 -5z"/>
          <path id="11" d="M16749 6793 c-13 -21 -29 -42 -36 -46 -7 -4 -13 -14 -13 -21 0 -7
          -11 -25 -24 -39 -13 -14 -33 -46 -46 -71 -12 -26 -27 -52 -34 -59 -17 -20 -46
          -77 -46 -92 0 -8 -6 -18 -14 -22 -14 -9 -56 -97 -56 -119 0 -8 -4 -14 -9 -14
          -9 0 -51 -94 -51 -116 0 -7 -6 -19 -13 -26 -7 -7 -24 -47 -37 -88 -14 -41 -28
          -77 -31 -80 -4 -3 -17 -39 -30 -80 -12 -41 -25 -77 -29 -80 -4 -3 -17 -50 -30
          -105 -14 -55 -27 -102 -31 -105 -4 -3 -19 -68 -35 -145 -16 -77 -31 -142 -35
          -145 -8 -6 -49 -282 -49 -327 0 -16 -4 -33 -9 -39 -16 -16 -31 -279 -31 -533
          l0 -242 23 6 c51 15 87 29 87 35 0 3 32 17 70 30 39 13 70 27 70 31 0 4 29 17
          65 29 36 12 72 28 79 36 8 8 19 14 24 14 13 0 116 43 122 51 3 3 21 12 41 19
          l36 13 6 186 c7 207 25 433 36 441 4 3 17 71 30 153 13 81 27 147 31 147 4 0
          11 17 14 38 13 68 50 217 57 222 3 3 16 39 29 80 13 41 26 77 30 80 3 3 17 34
          29 70 13 36 27 67 30 70 4 3 18 31 30 63 13 31 26 57 30 57 3 0 17 27 30 60
          14 34 29 64 36 68 6 4 22 31 35 60 14 28 28 52 32 52 4 0 17 18 28 40 11 22
          25 40 30 40 6 0 10 4 10 10 0 18 51 86 113 150 15 15 27 32 27 36 0 4 32 38
          70 75 39 37 70 72 70 77 0 5 -54 15 -120 22 -66 7 -120 16 -120 20 0 4 -26 10
          -57 14 -260 28 -335 36 -385 36 -55 0 -57 -1 -79 -37z"/>
          <path id="12" d="M17597 6637 c-73 -74 -134 -144 -165 -188 -9 -13 -21 -28 -28 -34 -6
          -5 -20 -25 -30 -44 -11 -19 -29 -45 -41 -59 -13 -14 -23 -31 -23 -38 0 -7 -6
          -17 -14 -21 -7 -4 -26 -33 -41 -63 -15 -30 -33 -62 -40 -70 -7 -8 -21 -35 -31
          -60 -10 -25 -21 -47 -25 -50 -11 -7 -49 -98 -49 -116 0 -8 -4 -14 -9 -14 -5 0
          -22 -37 -37 -82 -15 -46 -30 -85 -34 -88 -4 -3 -17 -46 -30 -97 -14 -50 -27
          -90 -30 -88 -3 2 -16 -49 -29 -113 -13 -64 -28 -122 -32 -127 -10 -13 -49
          -275 -49 -327 0 -21 -4 -38 -9 -38 -8 0 -16 -96 -24 -302 -3 -69 -1 -78 14
          -78 10 0 24 7 33 15 8 8 19 15 24 15 13 0 106 41 112 50 3 3 33 17 67 29 34
          13 63 26 65 30 2 3 37 20 78 36 41 16 80 34 87 41 7 6 35 20 63 30 27 10 50
          21 50 25 0 4 29 17 65 30 36 12 65 26 65 30 0 4 32 20 70 35 39 15 70 30 70
          34 0 4 27 18 60 31 33 13 60 26 60 30 0 3 27 17 60 30 33 13 60 27 60 30 0 4
          32 20 70 35 39 15 70 30 70 34 0 4 20 15 45 25 25 9 51 22 58 29 13 13 88 49
          125 60 12 4 22 11 22 15 0 4 24 18 53 32 28 13 54 27 57 31 3 4 22 14 41 22
          34 14 36 18 48 85 7 38 16 71 20 73 3 2 20 50 36 108 15 58 31 107 35 110 4 3
          17 35 29 73 13 37 27 67 32 67 5 0 9 6 9 14 0 17 40 109 50 116 5 3 20 35 35
          73 15 37 31 67 35 67 5 0 18 21 31 47 12 25 31 55 41 65 10 10 18 24 18 32 0
          7 7 19 15 26 27 23 76 102 71 115 -2 8 -31 19 -63 26 -32 7 -61 16 -64 21 -6
          9 -211 58 -241 58 -10 0 -18 4 -18 9 0 5 -55 19 -122 32 -68 12 -125 25 -128
          29 -6 8 -225 50 -260 50 -13 0 -20 3 -16 7 8 8 -306 62 -362 63 -28 0 -44 -11
          -105 -73z"/>
          <path fill="black" d="M22938 6639 c2 -16 8 -29 12 -29 4 0 11 -21 15 -47 9 -62 37 -177 44
          -183 3 -3 19 -45 35 -95 16 -49 31 -92 35 -95 3 -3 17 -32 30 -65 14 -33 28
          -62 31 -65 4 -3 18 -30 31 -60 12 -30 26 -57 29 -60 4 -3 20 -30 36 -60 16
          -29 39 -65 52 -78 12 -14 22 -29 22 -35 0 -5 9 -19 20 -31 156 -163 190 -196
          199 -196 5 0 16 -8 23 -17 14 -19 37 -35 78 -56 14 -6 33 -19 42 -27 9 -8 44
          -24 77 -34 34 -11 61 -23 61 -27 0 -4 44 -18 98 -30 53 -12 99 -26 102 -30 3
          -4 27 -9 53 -10 l49 -4 15 45 c8 25 19 47 23 50 4 3 17 35 29 73 13 37 28 67
          34 67 7 0 3 6 -8 12 -11 6 -22 14 -25 18 -3 4 -29 19 -58 34 -29 15 -62 36
          -72 46 -11 11 -26 20 -33 20 -7 0 -23 9 -35 20 -11 11 -39 29 -61 41 -23 12
          -41 24 -41 28 0 4 -18 17 -40 30 -22 12 -45 27 -53 34 -25 24 -76 57 -87 57
          -6 0 -9 4 -6 8 3 5 -9 17 -27 27 -32 18 -45 28 -105 84 -19 17 -37 31 -40 31
          -9 0 -302 295 -302 303 0 4 -21 29 -47 56 -69 72 -104 112 -130 149 -13 18
          -34 45 -48 60 -14 15 -28 34 -30 44 -3 9 -11 18 -18 21 -10 4 -12 -3 -9 -24z"/>
          <path fill="black" d="M19113 6361 c-5 -3 -19 -23 -32 -43 -12 -21 -26 -38 -29 -38 -4 0
          -18 -23 -31 -50 -14 -28 -28 -50 -31 -50 -4 0 -19 -27 -35 -60 -16 -33 -32
          -60 -35 -60 -4 0 -18 -25 -31 -55 -13 -30 -27 -55 -30 -55 -4 0 -17 -26 -30
          -57 -12 -32 -26 -60 -30 -63 -4 -3 -19 -40 -34 -82 -15 -43 -31 -78 -34 -78
          -4 0 -18 -40 -31 -90 -13 -49 -28 -90 -32 -90 -4 0 -8 -7 -8 -15 0 -8 4 -15 9
          -15 19 0 110 43 106 50 -3 5 8 13 24 18 21 7 32 21 41 49 7 21 16 40 19 43 4
          3 18 30 30 60 13 30 26 57 30 60 3 3 19 32 35 65 16 33 35 64 43 68 7 4 13 13
          13 20 0 7 10 23 23 37 12 14 34 43 47 65 13 22 35 48 47 58 12 10 22 22 20 26
          -3 11 86 92 97 88 20 -7 17 -27 -8 -48 -14 -13 -26 -28 -26 -34 0 -7 -10 -23
          -22 -36 -13 -14 -34 -46 -48 -72 -14 -26 -28 -47 -31 -47 -4 0 -14 -21 -24
          -47 -10 -27 -25 -61 -35 -78 -10 -16 -26 -55 -35 -85 -10 -30 -21 -57 -25 -60
          -4 -3 -10 -15 -14 -27 -9 -30 12 -31 47 -3 14 12 48 30 74 42 26 11 50 24 53
          27 3 3 14 11 25 17 10 6 28 26 38 45 11 18 28 40 38 47 10 7 19 18 19 24 0 28
          198 198 230 198 22 0 11 -15 -70 -95 -22 -22 -40 -46 -40 -53 0 -6 -9 -17 -20
          -24 -11 -7 -20 -17 -20 -21 0 -5 -9 -22 -20 -37 l-21 -29 62 25 c34 14 67 31
          74 38 7 7 35 21 61 31 27 10 57 27 68 37 11 10 24 18 29 18 14 0 81 33 95 47
          7 7 19 13 25 13 12 0 -82 89 -94 90 -4 0 -21 14 -39 30 -18 17 -38 30 -46 30
          -7 0 -18 9 -24 20 -6 11 -16 20 -21 20 -6 0 -31 14 -56 30 -24 17 -58 37 -76
          46 -18 9 -34 20 -37 23 -3 4 -34 19 -70 35 -36 15 -67 31 -70 34 -9 12 -68 30
          -77 23z"/>
          <path id="13" d="M12204 5966 c-3 -9 -10 -16 -15 -16 -11 0 -93 -56 -109 -75 -7 -8
          -19 -15 -26 -15 -8 0 -19 -9 -26 -20 -7 -11 -17 -20 -21 -20 -5 0 -23 -11 -41
          -25 -18 -14 -38 -25 -43 -25 -6 0 -13 -6 -16 -14 -3 -8 -22 -22 -41 -31 -20
          -10 -36 -21 -36 -26 0 -5 -10 -14 -22 -19 -31 -13 -55 -30 -92 -62 -17 -16
          -36 -28 -43 -28 -6 0 -16 -6 -20 -13 -4 -7 -25 -23 -45 -36 -21 -12 -38 -27
          -38 -32 0 -5 -5 -9 -10 -9 -10 0 -69 -38 -80 -51 -3 -3 -25 -19 -50 -34 -25
          -15 -47 -31 -50 -34 -10 -13 -70 -51 -79 -51 -5 0 -11 -6 -14 -13 -3 -8 -21
          -22 -41 -31 -20 -9 -36 -21 -36 -26 0 -5 -15 -16 -32 -25 -18 -8 -46 -27 -63
          -41 -16 -14 -42 -32 -57 -40 -16 -8 -28 -18 -28 -23 0 -5 -15 -16 -32 -25 -44
          -22 -66 -37 -95 -63 -14 -12 -34 -25 -44 -28 -11 -4 -19 -10 -19 -15 0 -5 -18
          -19 -40 -30 -22 -11 -40 -24 -40 -29 0 -5 -19 -19 -42 -31 -24 -12 -51 -30
          -60 -41 -10 -10 -34 -25 -53 -35 -19 -9 -33 -19 -30 -23 2 -4 -20 -19 -48 -34
          -29 -15 -48 -27 -42 -27 5 0 -7 -11 -27 -25 -20 -14 -42 -25 -47 -25 -6 0 -15
          -9 -21 -20 -6 -11 -17 -20 -24 -20 -7 0 -24 -11 -38 -24 -14 -12 -43 -34 -64
          -47 -22 -13 -50 -34 -64 -46 -14 -13 -30 -23 -37 -23 -6 0 -18 -8 -25 -17 -7
          -10 -25 -24 -39 -31 -14 -8 -39 -25 -55 -39 -16 -14 -41 -31 -56 -39 -16 -8
          -28 -18 -28 -23 0 -5 -15 -16 -32 -25 -45 -23 -66 -37 -102 -68 -17 -16 -36
          -28 -43 -28 -7 0 -13 -4 -13 -9 0 -5 -20 -21 -45 -35 -25 -15 -45 -31 -45 -36
          0 -6 -5 -10 -10 -10 -12 0 -60 -33 -98 -67 -14 -13 -31 -23 -38 -23 -7 0 -18
          -9 -24 -20 -6 -11 -15 -20 -21 -20 -11 0 -61 -34 -99 -67 -13 -13 -30 -23 -37
          -23 -7 0 -16 -6 -20 -13 -4 -8 -21 -22 -38 -32 -16 -10 -34 -23 -40 -29 -5 -6
          -30 -24 -55 -40 -24 -16 -56 -40 -69 -53 -14 -12 -31 -23 -38 -23 -7 0 -16 -6
          -20 -13 -4 -7 -25 -23 -45 -35 -20 -13 -38 -26 -40 -30 -4 -10 -59 -47 -80
          -55 -10 -3 -18 -11 -18 -16 0 -6 -12 -17 -27 -25 -16 -8 -40 -25 -55 -37 -14
          -13 -52 -42 -83 -64 -31 -22 -68 -51 -83 -64 -15 -12 -39 -29 -54 -37 -16 -8
          -28 -18 -28 -23 0 -8 -54 -46 -83 -59 -5 -2 -19 -14 -32 -26 -34 -30 -77 -65
          -100 -81 -19 -14 -19 -14 8 -15 16 0 26 -4 23 -9 -3 -4 50 -11 117 -14 131 -6
          370 -43 375 -58 2 -5 11 -9 19 -9 22 0 157 -41 163 -49 11 -15 101 -42 120
          -36 11 4 28 17 36 31 9 13 21 24 28 24 6 0 31 18 56 40 25 22 50 40 57 40 6 0
          18 9 25 19 8 10 30 29 50 40 20 12 39 28 42 36 3 8 11 15 18 15 6 0 33 18 59
          40 26 22 51 40 56 40 4 0 21 14 36 30 16 17 35 30 42 30 8 0 20 8 27 18 15 19
          73 62 85 62 4 0 21 14 37 30 16 17 35 30 42 30 6 0 19 11 28 25 9 14 21 25 26
          25 8 0 115 77 215 154 11 9 34 26 50 38 17 12 32 25 35 28 3 3 25 19 50 35 48
          32 63 44 104 81 15 13 40 31 57 39 16 9 33 23 36 31 3 7 9 14 14 14 10 0 72
          42 94 64 9 9 29 23 45 31 15 8 30 21 33 30 4 8 12 15 19 15 7 0 25 11 39 23
          13 13 45 37 69 53 25 16 51 37 58 46 7 10 19 18 27 18 8 0 20 8 27 18 7 10 36
          32 64 50 28 18 59 41 69 52 10 11 24 20 31 20 7 0 17 6 21 13 4 7 24 24 43 37
          19 13 37 27 40 31 8 11 80 59 88 59 4 0 13 9 20 20 7 11 17 20 23 20 7 0 29
          15 50 33 21 17 48 40 61 49 13 10 35 27 50 38 14 11 31 20 37 20 7 0 14 7 17
          15 3 8 22 25 42 37 20 12 44 29 53 38 9 8 48 38 85 65 38 28 80 60 94 73 14
          12 30 22 37 22 7 0 17 9 23 20 6 11 16 20 23 20 7 0 23 10 37 23 32 28 97 77
          104 77 3 0 16 10 30 23 25 23 46 39 105 82 19 14 37 28 40 32 9 12 69 53 78
          53 5 0 14 9 21 20 7 11 19 20 27 20 8 0 20 9 27 20 7 12 32 32 56 46 23 14 42
          29 42 34 0 5 8 11 18 14 9 4 37 24 62 46 25 22 51 40 58 40 7 0 15 7 19 15 3
          9 17 22 32 30 14 7 44 30 66 49 22 20 43 36 46 36 3 0 17 10 31 23 38 34 69
          59 112 92 22 17 54 42 70 58 17 15 34 27 38 27 5 0 8 4 8 8 0 16 -50 32 -148
          49 -53 9 -100 22 -104 27 -5 7 -101 12 -268 15 -232 3 -260 1 -266 -13z"/>
          <path fill="black" d="M22966 5708 c2 -13 11 -79 20 -148 9 -69 20 -127 24 -130 4 -3 13
          -32 20 -65 20 -102 42 -190 50 -195 4 -3 17 -34 29 -70 13 -36 26 -67 30 -70
          4 -3 18 -27 31 -55 13 -27 27 -52 30 -55 4 -3 20 -29 35 -59 14 -29 35 -63 46
          -74 10 -12 19 -26 19 -31 1 -19 69 -85 110 -106 71 -36 216 -16 267 37 12 13
          26 23 31 23 14 0 102 84 102 98 0 6 9 16 20 22 29 16 24 30 -10 30 -16 0 -30
          5 -30 10 0 6 -9 10 -19 10 -26 0 -179 48 -183 58 -2 4 -23 18 -48 31 -25 14
          -49 30 -53 38 -4 7 -13 13 -19 13 -9 0 -138 129 -195 194 -31 37 -113 161
          -113 173 0 7 -6 16 -13 20 -7 4 -24 31 -37 58 -13 28 -27 52 -30 55 -4 3 -20
          32 -36 65 -16 33 -32 62 -36 65 -4 3 -14 22 -23 43 -17 42 -24 47 -19 15z"/>
          <path fill="black" d="M19229 5583 c-1 -2 -2 -6 -3 -10 -14 -51 -51 -159 -56 -163 -5 -3
          -18 -48 -31 -100 -12 -52 -26 -97 -30 -100 -18 -13 -41 -277 -36 -419 5 -148
          23 -265 38 -255 5 3 9 -2 9 -10 0 -9 8 -16 17 -16 24 0 177 42 183 50 3 4 37
          17 75 30 39 13 72 26 75 30 3 4 32 17 65 29 33 12 62 26 65 29 3 4 29 18 59
          32 30 14 59 30 65 36 6 5 32 20 59 33 26 12 47 26 47 31 0 4 16 15 35 24 19 9
          40 22 47 29 7 7 28 23 47 36 89 63 211 213 211 259 0 7 5 12 10 12 11 0 14
          117 3 122 -5 2 -18 23 -31 49 -18 35 -35 52 -77 75 -30 16 -55 32 -55 36 0 4
          -33 17 -72 30 -40 12 -75 26 -78 30 -7 9 -223 48 -266 48 -18 0 -34 4 -36 10
          -3 9 -336 22 -339 13z"/>
          <path d="M18641 4194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
          <path fill="black" d="M21400 5060 c-1 -35 32 -224 40 -230 7 -5 35 -196 46 -315 4 -38 11
          -72 15 -75 23 -16 21 -679 -2 -664 -5 3 -9 -9 -9 -27 0 -44 -40 -293 -49 -299
          -3 -3 -17 -49 -30 -102 -14 -54 -27 -98 -31 -98 -3 0 -15 -30 -25 -66 -10 -37
          -24 -72 -30 -78 -6 -6 -23 -46 -39 -88 -15 -42 -31 -78 -35 -80 -4 -2 -20 -28
          -35 -58 -35 -73 -76 -144 -86 -150 -4 -3 -20 -29 -35 -57 -15 -29 -31 -53 -36
          -53 -5 0 -13 -8 -17 -19 -4 -10 -25 -41 -47 -67 -56 -68 -288 -304 -299 -304
          -7 0 -25 -14 -108 -84 -9 -8 -36 -25 -60 -38 -23 -13 -47 -28 -53 -35 -5 -6
          -32 -22 -60 -34 -27 -13 -52 -26 -55 -30 -7 -11 -99 -49 -116 -49 -8 0 -14 -4
          -14 -10 0 -5 -33 -19 -72 -31 -40 -12 -75 -25 -78 -29 -5 -9 -208 -60 -234
          -60 -9 0 -16 -4 -16 -9 0 -4 -21 -11 -47 -15 -116 -17 -303 -36 -355 -36 -32
          0 -58 -4 -58 -10 0 -5 -18 -10 -41 -10 -22 0 -49 -5 -60 -10 -17 -10 -18 -13
          -4 -34 11 -17 38 -29 98 -46 45 -12 91 -26 102 -31 31 -14 189 -29 306 -29
          128 0 323 18 327 31 2 5 13 9 24 9 31 0 252 51 258 59 3 4 37 18 75 32 39 13
          71 27 73 30 2 4 30 17 62 29 33 12 60 26 60 31 0 5 26 21 58 35 31 15 59 31
          60 36 2 5 21 17 42 28 21 10 40 26 43 35 4 8 12 15 19 15 7 0 38 22 68 50 30
          27 61 49 68 50 7 0 11 4 8 8 -2 4 11 18 30 31 19 13 34 27 34 31 0 4 27 35 60
          70 33 35 60 66 60 69 0 3 21 29 45 56 25 28 45 55 45 61 0 6 10 22 23 35 13
          13 32 40 43 59 10 19 25 41 32 48 7 8 20 28 29 45 9 18 19 34 23 37 4 3 18 29
          30 58 12 28 26 52 30 52 8 0 50 96 50 116 0 7 6 19 13 26 8 7 22 38 32 68 10
          30 25 73 35 95 10 22 26 74 36 115 10 41 22 77 25 80 13 9 50 254 56 368 3 61
          10 112 15 112 4 0 8 52 8 116 0 63 -4 113 -8 110 -5 -3 -12 46 -15 109 -7 120
          -44 377 -56 385 -4 3 -18 50 -31 105 -12 55 -26 103 -31 106 -5 3 -18 36 -29
          74 -11 38 -24 71 -28 74 -5 3 -19 33 -31 66 -12 33 -27 65 -34 70 -7 6 -20 28
          -30 50 -11 22 -25 47 -33 55 -8 8 -21 24 -29 35 -14 19 -14 19 -15 -5z"/>
          </g>`,
        },
      ],
    ],
    listColorQuestion: [
      {
        color: 'red',
      },
      {
        color: 'Pink',
      },
      {
        color: 'yellow',
      },
      {
        color: 'blue',
      },
      {
        color: 'green',
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 1',
    id: 'LQTAMN-U1-P25-E2',
    audio: '',
    video: '',
    component: PaintColorType,
    titleQuestion: [
      {
        title: `1. COLOR YOUR FAVORITE TOY`,
      },
    ],
    recorder: true,
    isAllowSubmit: true,
    totalInput: 5,
    isHiddenCheck: true,

    questionSVG: [
      [
        {
          version: '1.0',
          viewBox: '0 0 2194 1372',
          X: 0,
          y: 0,
          style: {
            width: 900,
            height: 900,
            marginTop: 9,
            // stroke: 'black',
            // fill: 'transparent',
            // stroke: '#000',
            // strokeLinecap: 'round',
            // strokeLinejoin: 'round',
            // strokeWidth: '50px',
          },
          g: `
          <g transform="translate(0,1200) scale(0.100000,-0.100000)" 
          fill="transparent" style="cursor: pointer; stroke: black; stroke-width: 50px"
          >
<path fill="0" d="M0 6860 l0 -6860 10970 0 10970 0 0 6860 0 6860 -10970 0 -10970 0 0
-6860z m17793 6620 c95 -6 181 -15 192 -20 11 -4 74 -13 140 -19 178 -17 379
-43 385 -50 3 -4 73 -17 155 -30 83 -13 152 -27 155 -31 3 -3 57 -17 120 -30
63 -13 117 -27 120 -30 3 -4 54 -19 115 -34 60 -14 112 -30 115 -34 3 -5 43
-18 90 -31 47 -13 87 -26 90 -31 3 -4 37 -17 75 -30 39 -13 72 -26 75 -30 3
-3 40 -19 83 -34 42 -15 77 -31 77 -35 0 -3 27 -17 60 -30 33 -13 60 -27 60
-30 0 -4 27 -19 60 -34 33 -16 60 -31 60 -36 0 -4 16 -15 35 -24 20 -9 38 -24
41 -32 4 -8 10 -15 15 -15 15 0 70 -42 96 -72 13 -15 33 -30 44 -33 11 -4 18
-9 15 -12 -7 -7 14 -27 37 -36 9 -4 20 -14 24 -23 3 -9 18 -29 32 -44 42 -43
75 -83 101 -120 14 -19 35 -46 48 -60 12 -13 22 -30 22 -37 0 -7 8 -21 18 -31
10 -10 29 -40 41 -65 13 -26 26 -47 30 -47 4 0 18 -26 31 -57 13 -32 27 -60
31 -63 4 -3 17 -28 29 -55 12 -27 25 -52 29 -55 4 -3 20 -44 35 -92 15 -48 31
-88 35 -90 8 -3 51 -136 51 -156 0 -7 4 -12 9 -12 5 0 19 -42 31 -92 13 -51
27 -95 31 -98 9 -6 59 -239 59 -275 0 -14 4 -25 9 -25 10 0 18 -50 32 -201 13
-146 20 -189 30 -189 5 0 9 -20 9 -45 0 -25 -4 -45 -10 -45 -12 0 -14 -117 -1
-122 4 -1 19 -178 32 -393 12 -214 26 -392 29 -395 4 -3 19 -237 34 -520 16
-283 32 -524 36 -535 10 -24 39 -618 47 -950 4 -149 10 -245 16 -254 6 -7 12
-57 13 -110 1 -88 23 -228 44 -276 5 -11 11 -36 14 -55 12 -70 36 -323 36
-370 0 -30 5 -50 13 -53 16 -5 18 -1614 1 -1625 -6 -4 -14 -59 -17 -132 -12
-217 -40 -525 -49 -528 -4 -2 -8 -16 -8 -30 0 -49 -43 -427 -50 -432 -3 -3
-17 -88 -29 -190 -13 -102 -27 -187 -30 -190 -4 -3 -20 -88 -35 -190 -16 -102
-32 -187 -36 -190 -4 -3 -17 -63 -30 -135 -13 -71 -26 -132 -30 -135 -3 -3
-17 -57 -30 -120 -13 -63 -26 -117 -30 -120 -3 -3 -19 -61 -35 -130 -16 -69
-32 -127 -36 -130 -4 -3 -17 -43 -29 -90 -12 -47 -25 -87 -29 -90 -4 -3 -16
-32 -26 -65 -11 -33 -31 -85 -45 -115 -15 -30 -33 -71 -41 -90 -7 -19 -25 -45
-39 -56 -14 -12 -36 -32 -50 -44 -14 -12 -41 -28 -60 -36 -19 -8 -37 -18 -40
-23 -6 -9 -133 -61 -149 -61 -6 0 -11 -4 -11 -9 0 -4 -30 -19 -67 -31 -38 -12
-70 -26 -73 -29 -3 -4 -41 -18 -85 -31 -44 -13 -82 -27 -85 -31 -3 -4 -52 -20
-110 -35 -58 -15 -107 -31 -110 -35 -3 -4 -49 -17 -102 -30 -53 -12 -95 -25
-93 -29 2 -3 -49 -17 -113 -30 -64 -13 -119 -26 -122 -30 -3 -4 -72 -20 -155
-35 -82 -16 -152 -32 -155 -35 -3 -4 -72 -18 -155 -31 -82 -13 -152 -26 -155
-30 -6 -7 -289 -49 -332 -49 -14 0 -28 -4 -30 -9 -2 -8 -179 -29 -459 -57
l-66 -6 -13 -39 c-7 -21 -16 -39 -20 -39 -4 0 -15 -18 -25 -41 -10 -22 -27
-45 -37 -50 -10 -5 -18 -15 -18 -22 0 -14 -80 -90 -121 -114 -16 -10 -29 -20
-29 -24 0 -4 -21 -18 -47 -30 -27 -13 -54 -28 -60 -35 -7 -7 -34 -20 -60 -30
-27 -10 -54 -24 -60 -30 -7 -7 -45 -22 -85 -35 -40 -12 -75 -25 -78 -29 -6 -9
-178 -50 -209 -50 -11 0 -21 -4 -21 -9 0 -34 -702 -51 -922 -22 -60 8 -108 17
-108 21 0 4 -19 10 -42 14 -65 10 -192 39 -198 46 -3 3 -49 19 -102 34 -54 16
-98 32 -98 35 0 4 -33 17 -72 31 -40 13 -78 30 -85 36 -7 6 -35 20 -63 30 -27
10 -50 21 -50 24 0 4 -28 20 -62 36 -35 16 -67 34 -73 40 -5 7 -27 20 -47 29
-21 10 -38 21 -38 26 0 5 -10 14 -22 20 -13 5 -32 15 -43 22 -29 18 -124 119
-131 139 -3 10 -9 18 -14 18 -4 0 -18 20 -29 44 -24 48 -26 49 -156 62 -38 4
-72 10 -75 13 -3 4 -79 17 -170 30 -91 13 -167 27 -170 30 -3 4 -88 20 -190
36 -102 16 -187 32 -190 36 -3 3 -33 10 -67 14 l-62 7 -7 -29 c-4 -15 -10 -30
-14 -33 -3 -3 -17 -33 -29 -68 -13 -34 -29 -66 -35 -70 -6 -4 -17 -18 -24 -32
-22 -45 -27 -52 -125 -159 -21 -22 -42 -41 -46 -41 -4 0 -22 -15 -39 -32 -49
-51 -75 -71 -123 -97 -24 -13 -51 -31 -60 -40 -8 -9 -34 -25 -57 -35 -23 -11
-42 -22 -42 -26 0 -4 -25 -18 -55 -30 -30 -12 -52 -26 -49 -31 3 -5 -1 -9 -8
-9 -17 0 -142 -53 -146 -62 -2 -4 -33 -17 -70 -29 -37 -12 -69 -25 -72 -29 -3
-4 -39 -18 -80 -30 -41 -12 -77 -26 -80 -30 -5 -7 -145 -44 -215 -56 -22 -4
-42 -10 -45 -15 -6 -9 -262 -49 -313 -49 -20 0 -37 -6 -40 -12 -5 -17 -704
-19 -715 -2 -4 6 -43 14 -87 18 -115 9 -319 38 -325 46 -3 4 -23 10 -45 14
-92 15 -240 50 -243 58 -2 4 -11 8 -20 8 -21 0 -155 42 -162 50 -3 4 -36 17
-75 30 -38 13 -72 26 -75 31 -3 4 -35 19 -72 34 -38 15 -68 31 -68 35 0 4 -25
18 -55 31 -30 12 -55 26 -55 30 0 5 -16 16 -36 25 -20 9 -38 23 -41 31 -3 7
-9 13 -14 13 -15 0 -93 53 -107 72 -7 10 -18 18 -24 18 -29 0 -320 299 -356
366 -7 13 -17 24 -22 24 -6 0 -10 6 -10 13 0 7 -13 28 -29 48 -16 19 -40 55
-52 80 -13 24 -26 46 -29 49 -4 3 -20 32 -35 65 -16 33 -32 62 -35 65 -4 3
-17 35 -30 73 -13 37 -27 67 -30 67 -4 0 -17 35 -30 78 -14 42 -28 79 -32 82
-4 3 -20 62 -35 130 -15 69 -30 127 -34 130 -8 6 -39 195 -46 282 -3 37 -10
65 -14 62 -5 -3 -9 14 -10 38 0 24 -4 107 -8 184 -8 148 -16 174 -58 174 -32
-1 -338 53 -343 60 -3 4 -68 17 -145 29 -77 13 -142 26 -145 30 -3 4 -66 17
-140 30 -74 13 -137 26 -140 30 -3 4 -79 20 -170 36 -91 15 -167 31 -170 35
-3 3 -66 17 -140 30 -74 13 -137 27 -140 31 -3 3 -61 16 -130 28 -69 13 -127
26 -130 30 -3 4 -77 20 -165 36 -88 15 -162 31 -165 35 -3 4 -63 18 -135 31
-71 12 -132 26 -135 30 -3 4 -72 19 -155 35 -82 15 -152 30 -155 34 -3 4 -61
18 -130 30 -69 13 -127 26 -130 30 -3 4 -59 18 -125 30 -66 13 -122 26 -125
30 -3 4 -68 20 -145 35 -77 15 -142 30 -145 34 -3 4 -57 18 -120 31 -63 13
-117 26 -120 30 -6 7 -137 40 -160 40 -8 0 -35 -18 -59 -40 -25 -22 -48 -40
-52 -40 -5 0 -16 -8 -26 -18 -10 -10 -43 -29 -73 -41 -30 -13 -57 -27 -60 -32
-3 -4 -46 -18 -95 -31 -50 -13 -92 -26 -95 -30 -13 -16 -229 -38 -381 -38
-152 0 -417 24 -429 39 -3 4 -60 17 -127 30 -68 14 -123 28 -123 33 0 4 -7 8
-16 8 -21 0 -198 51 -204 59 -3 3 -36 17 -75 30 -38 13 -72 26 -75 30 -3 3
-32 17 -65 30 -33 14 -62 28 -65 32 -3 4 -35 21 -70 38 -36 17 -60 31 -55 31
6 1 -11 12 -37 26 -27 13 -48 29 -48 34 0 6 -5 10 -11 10 -27 0 -279 225 -279
249 0 5 -12 20 -27 33 -16 13 -39 43 -52 67 -13 24 -32 52 -42 62 -11 11 -19
25 -19 32 0 7 -8 22 -18 33 -19 21 -62 118 -62 141 0 8 -4 12 -9 9 -8 -5 -61
194 -61 228 0 9 -4 16 -9 16 -5 0 -12 19 -15 42 -6 34 -9 40 -17 26 -10 -18
-64 -48 -86 -48 -7 0 -13 -4 -13 -10 0 -5 -11 -10 -25 -10 -14 0 -25 4 -25 8
0 5 -34 19 -75 32 -41 13 -75 26 -75 30 0 3 -32 17 -71 30 -38 12 -76 28 -82
34 -7 6 -44 23 -84 36 -39 13 -70 27 -68 30 2 3 -27 17 -66 30 -38 13 -69 27
-69 31 0 3 -34 19 -75 34 -41 15 -75 31 -75 36 0 5 -6 9 -12 9 -20 0 -118 43
-118 52 0 4 -5 8 -11 8 -17 0 -143 52 -150 62 -5 8 -47 26 -102 45 -9 3 -17 9
-17 13 0 4 -28 17 -62 30 -34 12 -69 30 -79 39 -10 9 -39 25 -65 35 -46 19
-56 25 -106 70 -30 26 -34 72 -44 466 -4 140 -10 257 -14 260 -4 3 -13 284
-19 625 -15 784 -2 4412 15 4423 6 4 14 50 18 102 8 113 40 273 55 278 6 2 11
10 11 18 0 23 41 114 51 114 5 0 9 6 9 14 0 7 16 31 35 52 19 21 35 41 35 45
0 4 24 26 52 48 29 23 64 49 76 59 13 10 47 27 75 37 29 10 57 24 63 30 7 6
47 20 90 30 44 10 81 21 84 25 5 7 138 33 265 52 44 6 82 15 85 19 5 6 284 32
498 45 56 4 102 10 102 14 0 4 145 17 322 30 178 13 325 26 328 30 3 4 185 19
405 35 220 16 402 31 405 35 3 4 158 17 345 30 187 13 342 27 345 30 3 4 158
18 345 30 187 13 342 27 345 30 3 4 190 20 415 35 226 16 412 31 415 35 3 3
160 17 350 30 190 13 347 26 350 30 3 3 163 17 355 30 193 13 352 27 355 30 3
4 194 19 425 35 231 16 422 32 425 35 3 4 48 10 100 13 406 26 606 42 610 47
3 4 164 17 358 30 193 13 352 28 352 32 0 4 15 8 33 8 67 -1 822 54 827 60 5
6 619 51 688 50 17 0 32 4 32 8 0 5 82 14 183 20 516 34 672 46 677 52 3 4 84
12 180 18 96 6 258 17 360 23 102 7 187 15 190 19 4 6 308 29 623 47 53 3 97
9 97 13 0 4 44 10 98 12 270 15 788 52 792 58 5 6 144 17 545 42 105 6 192 14
195 18 3 4 172 17 375 30 204 13 372 26 375 30 4 5 406 33 815 57 61 3 112 9
115 13 17 24 868 38 1173 20z"/>
<path id="1" d="M16995 13403 c-60 -2 -112 -7 -115 -12 -3 -4 -169 -18 -370 -31 -201
-13 -367 -26 -370 -30 -4 -6 -274 -26 -610 -47 -58 -3 -107 -9 -110 -13 -4 -6
-458 -40 -757 -57 -51 -3 -93 -10 -93 -14 0 -5 -15 -9 -32 -9 -71 1 -673 -44
-678 -50 -3 -4 -52 -10 -110 -13 -296 -18 -596 -41 -600 -47 -3 -3 -194 -19
-425 -35 -231 -16 -422 -31 -425 -35 -3 -4 -158 -17 -345 -29 -187 -12 -349
-26 -360 -31 -11 -5 -202 -21 -425 -36 -223 -15 -407 -31 -410 -34 -3 -3 -160
-17 -350 -30 -190 -13 -347 -26 -350 -30 -3 -4 -158 -17 -345 -30 -187 -13
-342 -26 -345 -30 -5 -6 -244 -24 -695 -52 -99 -6 -182 -14 -185 -18 -3 -4
-185 -17 -405 -30 -220 -13 -402 -27 -405 -30 -3 -4 -57 -10 -120 -13 -338
-17 -696 -41 -700 -47 -3 -4 -54 -10 -115 -13 -241 -11 -780 -51 -785 -57 -3
-3 -153 -17 -335 -30 -181 -12 -332 -26 -335 -30 -3 -4 -162 -17 -355 -30
-192 -13 -352 -26 -355 -30 -3 -3 -200 -19 -437 -35 -238 -16 -433 -33 -433
-37 0 -4 -17 -8 -37 -8 -108 1 -669 -45 -675 -54 -6 -8 -11 -6 -18 4 -5 8 -10
10 -10 4 0 -7 -34 -13 -88 -17 -377 -25 -550 -40 -567 -47 -11 -5 -63 -11
-115 -14 -186 -12 -555 -48 -560 -55 -3 -4 -88 -17 -190 -30 -102 -12 -187
-26 -188 -32 -2 -5 -13 -9 -25 -9 -31 0 -230 -49 -237 -58 -3 -4 -25 -16 -50
-27 -25 -10 -56 -29 -69 -42 -13 -13 -29 -23 -35 -23 -12 0 -146 -139 -146
-152 0 -5 -13 -36 -30 -70 -17 -34 -37 -99 -47 -152 -9 -50 -19 -93 -23 -96
-9 -6 -30 -234 -30 -316 0 -55 0 -56 28 -50 44 9 664 26 974 26 165 0 288 4
290 9 2 6 1202 14 3003 20 1650 6 3223 16 3495 22 792 17 3893 -2 4328 -27 77
-4 102 -9 102 -20 0 -12 -84 -44 -117 -44 -7 0 -13 -4 -13 -10 0 -5 -27 -12
-60 -16 -33 -4 -60 -11 -60 -15 0 -5 6 -9 13 -9 7 0 23 -13 35 -30 13 -16 29
-30 37 -30 8 0 22 -12 32 -27 10 -16 26 -37 38 -49 57 -59 140 -187 157 -241
7 -24 16 -43 20 -43 7 0 39 -97 53 -160 4 -19 11 -37 16 -40 12 -8 38 -169 46
-282 3 -54 10 -98 15 -98 4 0 8 -67 8 -150 0 -93 -4 -150 -10 -150 -5 0 -10
-24 -10 -52 0 -74 -41 -368 -51 -368 -5 0 -9 -10 -9 -21 0 -32 -51 -253 -59
-259 -4 -3 -18 -38 -31 -77 -13 -40 -27 -73 -30 -73 -4 0 -18 -27 -31 -60 -13
-33 -27 -60 -31 -60 -4 0 -17 -18 -28 -39 -10 -22 -23 -41 -28 -43 -4 -1 -21
-25 -37 -53 -15 -27 -37 -56 -47 -63 -10 -7 -18 -19 -18 -27 0 -7 -13 -23 -30
-35 -16 -12 -30 -27 -30 -33 0 -7 -21 -30 -47 -52 -26 -22 -56 -49 -68 -60
-28 -27 -102 -75 -115 -75 -5 0 -10 -5 -10 -11 0 -5 -22 -19 -50 -31 -27 -11
-48 -23 -46 -27 3 -3 -29 -17 -70 -31 -41 -13 -74 -27 -74 -31 0 -15 -230 -59
-312 -59 -27 0 -48 -4 -48 -10 0 -6 -28 -10 -65 -10 -37 0 -65 4 -65 10 0 6
-48 10 -122 10 -68 0 -316 9 -553 20 -236 10 -500 22 -585 26 -85 4 -157 10
-160 14 -4 6 -381 28 -802 47 -71 3 -128 10 -128 15 0 4 -19 8 -42 8 -87 -1
-753 43 -758 50 -3 4 -201 19 -440 35 -239 16 -437 31 -440 35 -3 4 -165 17
-360 30 -195 13 -357 26 -360 30 -3 4 -156 17 -340 29 -184 12 -344 26 -355
31 -17 7 -388 36 -725 57 -58 3 -107 9 -110 13 -4 6 -255 27 -575 47 -49 3
-92 9 -95 13 -3 4 -151 17 -330 30 -179 13 -327 26 -330 30 -3 3 -171 19 -375
35 -203 15 -372 31 -375 35 -3 4 -126 17 -275 30 -148 13 -272 26 -275 30 -3
4 -36 10 -75 14 -214 20 -471 50 -475 56 -3 3 -86 17 -185 30 -99 13 -187 27
-195 31 -8 4 -71 18 -140 30 -69 12 -127 25 -130 28 -3 4 -43 18 -88 31 -46
13 -89 28 -95 34 -7 6 -25 20 -41 30 -41 26 -106 89 -106 102 0 7 -11 19 -25
28 -14 9 -25 23 -25 31 0 8 -8 23 -17 33 -21 20 -53 86 -53 107 0 7 -6 20 -14
27 -13 14 -66 203 -66 238 0 11 -4 20 -9 20 -11 0 -31 205 -31 329 0 117 19
344 29 351 4 3 13 41 20 85 15 93 40 198 50 205 3 3 17 38 30 78 13 39 27 72
31 72 3 0 17 23 29 50 12 28 26 50 31 50 5 0 11 9 15 19 3 11 28 41 55 67 28
27 50 53 50 59 0 5 -4 4 -8 -2 -6 -10 -87 -13 -328 -15 l-319 -3 -1 -1885 c-2
-1758 7 -3036 22 -3347 5 -92 10 -133 18 -133 7 0 22 -9 34 -21 13 -11 44 -30
70 -41 26 -12 52 -27 57 -33 6 -7 33 -21 60 -31 28 -10 54 -24 58 -31 4 -7 16
-13 25 -13 10 0 22 -4 27 -9 6 -5 27 -14 48 -21 20 -6 37 -15 37 -18 0 -4 27
-18 60 -31 33 -13 60 -28 60 -33 0 -4 6 -8 14 -8 16 0 109 -40 116 -50 4 -6
94 -42 142 -57 10 -3 18 -9 18 -13 0 -4 29 -18 65 -31 36 -12 65 -26 65 -30 0
-4 10 -11 23 -14 38 -11 132 -49 137 -56 3 -4 28 -14 55 -23 28 -10 59 -23 70
-30 11 -7 39 -20 61 -29 l41 -16 7 137 c3 75 9 257 12 405 4 147 11 267 15
267 5 0 9 33 9 74 0 167 48 874 60 891 4 6 10 39 14 75 12 119 38 280 46 280
4 0 10 18 14 40 7 43 34 107 54 128 7 8 19 27 27 43 9 16 19 29 24 29 4 0 20
11 35 25 25 23 110 55 148 55 10 0 18 5 18 10 0 6 43 10 110 10 67 0 110 -4
110 -10 0 -5 10 -10 23 -10 38 0 382 -54 387 -61 3 -4 70 -17 150 -29 80 -13
147 -26 150 -30 3 -4 61 -17 130 -30 69 -13 127 -26 130 -30 3 -4 61 -19 130
-34 69 -16 127 -31 130 -35 3 -4 46 -18 95 -31 50 -13 92 -27 95 -31 3 -4 34
-17 70 -29 36 -12 67 -25 70 -30 3 -4 30 -18 60 -31 56 -23 74 -36 141 -101
36 -36 79 -114 79 -144 0 -8 4 -14 8 -14 11 0 28 -67 43 -170 7 -47 18 -88 23
-92 17 -11 14 -1090 -2 -1096 -7 -2 -12 -24 -12 -55 0 -29 -7 -128 -15 -222
-8 -93 -17 -206 -21 -250 -3 -44 -9 -89 -14 -100 -9 -20 -50 -460 -50 -527 0
-21 -4 -38 -10 -38 -5 0 -10 -15 -10 -34 0 -26 5 -36 20 -41 11 -3 20 -10 20
-15 0 -5 30 -18 68 -31 37 -12 69 -25 72 -29 3 -4 55 -19 115 -35 61 -15 112
-31 115 -35 3 -4 51 -17 107 -30 57 -13 103 -27 103 -30 0 -4 51 -17 112 -30
62 -13 115 -27 118 -30 3 -4 16 -9 30 -12 14 -3 72 -16 130 -28 58 -12 107
-25 110 -29 3 -3 59 -17 125 -30 66 -14 122 -27 125 -31 3 -4 59 -17 125 -30
66 -13 122 -26 125 -30 3 -3 73 -19 155 -35 83 -15 152 -31 155 -34 3 -4 61
-17 130 -31 69 -13 127 -27 130 -30 3 -4 66 -18 142 -31 75 -13 135 -26 133
-30 -2 -3 69 -18 158 -34 89 -16 164 -31 167 -35 3 -3 64 -17 135 -30 72 -13
132 -26 135 -30 3 -3 79 -19 170 -35 91 -16 167 -32 170 -36 3 -3 66 -17 140
-29 74 -12 137 -25 140 -29 3 -4 70 -18 150 -31 80 -13 147 -27 150 -31 3 -3
82 -19 175 -35 94 -15 172 -30 175 -34 6 -9 151 -32 158 -26 5 6 27 559 38
951 4 143 10 262 14 265 4 3 12 185 18 405 13 423 28 677 42 710 5 11 11 49
15 85 15 160 48 395 56 398 5 2 9 13 9 24 0 33 42 252 50 258 3 3 17 45 30 95
13 49 29 96 37 103 7 7 13 20 13 28 0 48 81 149 148 183 22 11 43 25 46 30 3
5 38 17 78 26 40 10 75 22 79 26 3 5 74 14 158 21 120 9 205 9 399 -1 136 -7
249 -16 252 -20 3 -5 48 -11 100 -15 146 -10 389 -39 385 -45 -2 -4 67 -17
153 -30 86 -13 163 -27 172 -32 8 -4 80 -20 160 -35 80 -15 147 -30 150 -34 3
-3 50 -16 105 -29 55 -12 102 -26 105 -30 3 -4 47 -18 98 -31 50 -12 92 -26
92 -31 0 -5 6 -9 13 -9 19 0 191 -53 197 -61 3 -4 32 -18 65 -30 33 -12 66
-28 74 -36 7 -8 31 -21 52 -30 22 -8 39 -18 39 -22 0 -3 18 -17 40 -31 23 -14
53 -43 68 -65 15 -22 35 -49 44 -59 10 -11 23 -35 29 -55 7 -20 15 -38 19 -41
10 -6 50 -128 50 -151 0 -9 4 -19 9 -21 5 -1 17 -50 26 -108 27 -171 29 -651
6 -1720 -12 -531 -21 -1033 -21 -1117 0 -90 -4 -154 -10 -158 -6 -4 -10 -117
-10 -306 0 -193 4 -299 10 -299 6 0 10 -6 10 -12 1 -20 52 -73 90 -94 19 -10
39 -24 45 -31 5 -6 35 -21 65 -34 30 -13 57 -26 60 -30 3 -3 37 -17 75 -30 39
-12 72 -26 75 -31 4 -4 53 -20 110 -35 58 -15 107 -30 110 -34 3 -5 50 -18
105 -30 55 -11 102 -24 105 -28 3 -4 59 -18 125 -32 66 -13 122 -26 125 -30 3
-3 79 -18 170 -34 91 -16 167 -32 170 -36 3 -3 75 -17 160 -30 85 -13 157 -26
160 -29 3 -3 86 -17 185 -30 99 -13 182 -27 185 -30 5 -6 327 -46 465 -57 44
-3 82 -9 85 -13 5 -7 150 -20 399 -36 l104 -7 -7 24 c-4 13 -10 374 -13 801
-3 428 -10 775 -14 772 -5 -3 -9 445 -9 995 0 661 3 1001 10 1001 7 0 10 294
10 863 0 1420 20 4626 30 4707 5 42 15 88 23 104 14 25 15 14 21 -136 22 -638
44 -7526 27 -8485 -6 -343 -12 -628 -13 -635 -2 -9 35 -13 142 -16 80 -2 147
-8 150 -12 3 -5 224 -13 492 -20 372 -9 576 -9 860 0 205 6 377 15 383 19 5 5
60 11 120 15 186 10 450 38 453 47 2 5 17 9 33 9 55 0 404 52 407 61 2 5 15 9
29 9 37 0 277 43 283 50 3 4 60 17 128 29 67 13 122 26 122 31 0 4 19 10 43
14 70 11 232 49 237 56 3 4 48 17 100 30 52 14 97 27 100 31 3 3 40 16 83 29
42 13 77 27 77 31 0 5 7 9 14 9 22 0 180 51 186 60 3 4 34 18 70 31 36 12 67
26 70 30 3 3 30 17 60 30 52 22 76 38 124 85 40 39 54 62 77 126 13 34 26 65
30 68 8 6 59 181 59 204 0 9 4 16 8 16 8 0 52 164 52 195 0 8 3 15 8 15 4 0
18 53 31 118 14 64 27 119 31 122 8 6 60 274 60 308 0 12 4 22 9 22 4 0 19 66
32 148 13 81 26 149 30 152 3 3 17 77 29 165 13 88 27 162 31 165 3 3 19 109
34 235 16 127 31 232 35 235 7 6 23 143 42 357 6 78 15 140 18 138 32 -20 50
1589 22 1975 -8 102 -18 189 -23 194 -5 6 -9 23 -9 39 0 54 -42 322 -51 325
-5 2 -9 14 -9 27 0 13 -3 26 -7 28 -12 7 -287 -15 -293 -23 -3 -4 -140 -17
-305 -30 -165 -13 -302 -26 -305 -30 -3 -4 -106 -14 -230 -24 -124 -10 -263
-21 -310 -26 -47 -5 -107 -10 -135 -11 -27 -2 -52 -6 -55 -10 -3 -4 -138 -17
-300 -29 -162 -13 -297 -26 -300 -30 -3 -3 -147 -17 -320 -30 -173 -13 -317
-27 -320 -30 -6 -9 -610 -48 -872 -57 -103 -3 -185 -10 -182 -15 3 -4 -123 -8
-280 -8 -157 0 -286 4 -286 9 0 4 -41 11 -92 15 -97 6 -268 43 -268 57 0 5
-16 16 -37 24 -20 8 -62 42 -94 75 -58 60 -89 128 -89 195 0 15 -4 24 -10 20
-6 -3 -10 4 -10 17 0 13 -7 107 -14 209 -8 103 -20 323 -26 490 -7 167 -16
313 -20 324 -29 68 -47 1767 -21 1963 7 56 17 102 22 102 5 0 9 12 9 26 0 43
49 173 75 202 14 15 25 32 25 38 0 6 16 22 35 35 19 13 35 26 35 29 0 3 21 17
48 30 26 14 48 28 50 32 2 5 36 18 75 30 40 11 74 24 77 28 11 16 204 41 390
51 107 6 204 15 215 19 21 9 517 35 878 47 114 3 207 10 207 15 0 4 293 8 650
8 358 0 650 -4 650 -8 0 -5 109 -12 243 -15 287 -8 760 -36 767 -46 3 -4 64
-13 135 -19 177 -17 355 -44 355 -54 0 -4 5 -8 10 -8 6 0 10 15 10 33 0 55
-23 197 -32 197 -4 0 -8 9 -8 21 0 30 -43 213 -51 219 -4 3 -17 46 -30 97 -12
51 -25 90 -29 88 -3 -2 -19 39 -34 91 -16 52 -32 94 -35 94 -4 0 -17 34 -30
75 -13 41 -28 75 -32 75 -4 0 -18 29 -31 65 -12 36 -26 65 -29 65 -4 0 -17 28
-30 63 -12 34 -28 65 -34 69 -7 4 -20 29 -30 55 -10 26 -26 56 -36 66 -11 10
-19 24 -19 31 0 7 -10 24 -22 38 -13 14 -28 34 -34 44 -6 10 -17 30 -25 44 -8
13 -24 33 -36 43 -13 10 -23 23 -23 29 0 16 -176 184 -240 228 -19 14 -43 32
-54 41 -10 10 -31 23 -47 29 -16 7 -29 16 -29 20 0 3 -24 17 -52 31 -29 13
-58 29 -65 35 -6 6 -39 21 -72 34 -34 12 -61 26 -61 30 0 5 -30 18 -67 31 -38
12 -70 25 -73 29 -3 5 -45 20 -95 36 -49 15 -92 30 -95 34 -3 3 -43 17 -90 30
-47 13 -87 27 -90 31 -3 3 -50 17 -105 29 -55 13 -102 26 -105 30 -3 4 -72 20
-155 35 -82 16 -152 32 -155 36 -5 7 -280 49 -324 49 -14 0 -26 4 -26 8 0 10
-269 40 -439 49 -64 3 -119 10 -122 15 -6 9 -595 18 -774 11z m-85 -201 c0 -5
21 -13 46 -18 80 -16 55 -29 -63 -32 -62 -1 -113 -6 -113 -11 0 -4 -27 -11
-60 -14 -77 -8 -190 -37 -190 -48 0 -5 -6 -9 -13 -9 -24 0 -142 -52 -150 -66
-4 -8 -14 -14 -22 -14 -7 0 -26 -12 -42 -28 -15 -15 -45 -38 -66 -52 -20 -14
-39 -32 -42 -40 -3 -8 -19 -31 -36 -50 -17 -19 -37 -49 -44 -67 -8 -18 -18
-33 -23 -33 -5 0 -22 -39 -38 -87 -16 -49 -32 -90 -36 -94 -4 -3 -18 -50 -30
-105 -12 -54 -25 -101 -29 -104 -7 -5 -49 -329 -49 -378 0 -17 -4 -32 -9 -34
-8 -3 -61 -494 -61 -565 0 -18 -4 -33 -9 -33 -5 0 -14 -81 -21 -180 -7 -99
-16 -180 -19 -180 -43 1 -55 117 -47 453 8 339 16 478 27 471 5 -3 9 14 9 37
0 62 41 373 50 379 4 3 10 28 14 55 14 90 47 250 55 255 3 3 14 31 24 62 10
32 23 63 30 70 7 7 22 34 35 60 12 25 29 49 37 52 8 3 15 11 15 18 0 7 28 39
63 71 95 88 99 92 142 113 22 10 42 22 45 26 8 10 99 48 115 48 7 0 15 5 17
10 4 13 177 50 231 50 20 0 37 5 37 10 0 6 43 10 110 10 61 0 110 -4 110 -8z
m-1885 -2142 c99 -7 182 -16 185 -20 3 -4 37 -10 75 -14 39 -4 75 -12 80 -17
6 -6 11 -88 13 -190 2 -98 7 -177 11 -175 13 9 27 -7726 14 -7937 -5 -95 -8
-107 -25 -107 -10 0 -18 -4 -18 -10 0 -5 -22 -10 -50 -10 -27 0 -50 5 -50 10
0 6 -21 10 -47 10 -71 0 -457 41 -463 50 -3 3 -88 17 -190 30 -102 13 -187 26
-190 30 -3 3 -93 19 -200 34 -107 16 -197 32 -200 36 -3 3 -68 17 -144 29 -77
13 -147 27 -155 32 -9 4 -61 17 -115 28 -55 12 -102 24 -105 29 -3 5 -13 11
-22 14 -14 6 -17 56 -21 459 -3 249 -3 1245 0 2213 5 1719 -11 4144 -28 4156
-4 3 -10 292 -14 643 l-8 638 21 6 c12 3 21 9 21 13 0 9 182 29 305 34 189 8
1154 -3 1320 -14z m5030 -4320 c38 -6 72 -14 75 -18 3 -4 42 -19 87 -34 45
-15 94 -39 109 -53 15 -14 31 -25 36 -25 15 0 85 -59 159 -135 110 -112 119
-122 119 -132 0 -6 11 -22 24 -36 13 -14 34 -47 47 -74 12 -26 28 -54 35 -60
17 -18 28 -73 16 -85 -9 -9 -72 35 -72 52 0 9 -179 187 -222 219 -14 12 -30
21 -35 21 -4 0 -19 12 -32 25 -13 14 -29 25 -35 25 -7 0 -21 8 -32 18 -21 19
-127 72 -144 72 -6 0 -17 6 -24 13 -7 7 -40 22 -72 32 -33 11 -62 23 -65 28
-3 4 -39 17 -80 28 -41 12 -76 25 -79 28 -3 4 -13 11 -23 14 -22 8 -18 26 9
41 10 6 22 15 25 20 6 7 58 21 94 25 6 0 41 -4 80 -9z m-2492 -70 c15 -7 27
-16 27 -19 0 -4 17 -15 37 -26 40 -21 113 -89 113 -105 0 -25 -39 -29 -121
-12 -46 9 -85 20 -87 25 -5 11 -82 8 -82 -3 0 -5 -9 -10 -20 -10 -31 0 -103
-31 -122 -51 -9 -11 -20 -19 -25 -19 -8 0 -53 -46 -195 -199 -27 -28 -48 -56
-48 -63 0 -6 -3 -9 -6 -5 -6 6 -56 -51 -99 -115 -14 -21 -29 -38 -34 -38 -5 0
-12 -10 -16 -22 l-8 -23 -4 24 c-5 25 14 112 26 121 4 3 20 37 36 75 16 39 33
72 37 75 4 3 14 22 22 42 9 20 21 42 29 50 7 7 22 29 34 50 39 66 170 202 206
214 12 4 26 13 32 20 5 7 26 17 45 23 45 13 186 7 223 -9z m145 -460 c2 -6 9
-10 15 -10 23 0 147 -43 147 -51 0 -5 15 -16 33 -25 34 -17 49 -29 112 -91 59
-57 104 -113 122 -150 8 -18 19 -33 23 -33 4 0 20 -28 35 -62 15 -35 31 -65
36 -68 4 -3 17 -35 29 -73 12 -37 25 -67 29 -67 7 0 32 -100 47 -185 4 -22 11
-42 15 -45 4 -3 14 -68 20 -144 10 -108 10 -168 0 -279 -6 -78 -15 -144 -19
-147 -4 -3 -18 -45 -31 -95 -12 -49 -26 -92 -31 -95 -4 -3 -18 -30 -31 -60
-13 -30 -29 -61 -37 -69 -7 -8 -18 -24 -23 -35 -15 -35 -33 -59 -63 -85 -17
-14 -43 -41 -60 -58 -16 -18 -35 -33 -42 -33 -6 0 -14 -7 -18 -15 -3 -9 -26
-24 -51 -35 -25 -11 -45 -23 -45 -28 0 -9 -126 -52 -152 -52 -9 0 -18 -4 -20
-10 -5 -16 -198 -29 -288 -19 -47 5 -87 13 -90 17 -3 4 -40 18 -82 32 -43 13
-78 27 -78 32 0 5 -19 17 -43 27 -24 10 -50 25 -58 33 -8 7 -29 24 -47 37 -45
31 -152 142 -152 157 0 6 -7 14 -15 18 -8 3 -23 21 -32 41 -9 19 -20 35 -24
35 -9 0 -49 86 -49 105 0 8 -5 15 -11 17 -11 4 -49 146 -49 185 0 12 -5 23
-11 25 -13 4 -15 308 -1 317 5 3 12 33 16 66 11 88 36 195 47 195 5 0 9 5 9
12 0 24 43 148 51 148 5 0 9 5 9 10 0 6 9 30 20 52 11 23 20 48 20 55 0 7 5
13 10 13 6 0 16 13 23 28 45 105 228 313 322 367 28 16 52 33 55 37 3 4 35 17
70 28 36 11 74 24 85 30 11 5 36 11 55 14 54 6 195 -4 198 -14z m2539 -31 c35
-6 69 -17 76 -24 7 -7 34 -22 61 -33 26 -12 59 -32 73 -44 63 -56 183 -178
183 -185 0 -5 11 -20 24 -34 13 -14 34 -46 46 -70 13 -24 26 -46 30 -49 5 -3
20 -39 34 -79 14 -41 30 -77 34 -80 10 -7 52 -170 52 -200 0 -11 4 -21 9 -23
11 -4 31 -172 31 -264 0 -88 -20 -244 -31 -244 -5 0 -9 -7 -9 -16 0 -27 -31
-123 -44 -136 -7 -7 -19 -30 -27 -51 -19 -47 -48 -86 -119 -155 -74 -72 -79
-76 -125 -98 -22 -10 -42 -21 -45 -24 -11 -15 -78 -31 -154 -36 -79 -6 -253
17 -266 35 -3 4 -31 15 -63 25 -32 10 -63 23 -70 30 -7 6 -33 21 -59 34 -27
13 -48 27 -48 31 0 3 -15 15 -32 26 -34 20 -104 88 -148 143 -14 18 -34 43
-46 57 -11 13 -29 44 -40 67 -10 24 -21 45 -25 48 -4 3 -17 39 -30 80 -12 41
-25 77 -29 80 -15 11 -34 182 -34 305 1 128 20 305 34 305 4 0 10 15 14 33 11
56 40 147 47 147 4 0 17 26 30 58 13 31 33 68 46 82 13 14 23 28 23 33 0 4 30
37 67 73 62 62 139 114 169 114 7 0 14 5 16 11 10 31 229 48 345 28z m701
-2120 c-3 -56 -9 -104 -14 -107 -5 -4 -18 -27 -29 -52 -11 -25 -28 -55 -40
-66 -11 -12 -29 -32 -41 -45 -32 -36 -70 -65 -117 -91 -23 -13 -45 -27 -48
-31 -3 -4 -46 -21 -95 -37 -49 -16 -84 -30 -79 -31 16 -2 -168 -48 -192 -49
-13 0 -23 -4 -23 -8 0 -5 -53 -19 -117 -32 -65 -13 -120 -27 -123 -30 -3 -4
-66 -19 -140 -35 -74 -15 -137 -31 -140 -35 -3 -4 -60 -18 -127 -30 -67 -13
-120 -26 -118 -29 2 -3 -65 -19 -148 -35 -84 -16 -154 -32 -157 -36 -4 -6
-106 -27 -225 -46 -22 -4 -44 -10 -50 -14 -5 -4 -82 -18 -170 -31 -88 -12
-162 -26 -165 -29 -9 -12 -475 -60 -576 -60 -33 0 -54 -5 -56 -12 -4 -10 -94
-13 -408 -13 -314 0 -404 3 -407 13 -3 6 -21 12 -41 12 -72 0 -352 46 -362 60
-3 3 -42 17 -87 30 -46 12 -83 26 -83 30 0 3 -25 17 -56 30 -31 12 -67 33 -81
46 -14 13 -30 24 -36 24 -9 0 -69 60 -122 121 -12 13 -29 44 -40 69 -11 25
-23 47 -27 50 -15 11 -28 99 -28 193 0 100 17 237 30 237 4 0 13 18 20 40 16
50 43 51 48 2 2 -18 -1 -32 -7 -32 -6 0 -11 -13 -11 -30 0 -16 4 -30 8 -30 5
0 12 -44 15 -97 6 -93 39 -242 56 -253 5 -3 16 -21 26 -40 16 -30 64 -81 128
-135 9 -9 41 -25 70 -36 28 -11 54 -24 57 -29 6 -9 159 -48 225 -57 25 -3 45
-9 45 -13 0 -4 69 -16 153 -26 217 -27 854 -27 1177 0 124 10 227 22 230 26 3
4 34 10 70 13 147 15 388 51 384 59 -3 4 5 8 18 8 35 0 282 43 288 50 3 4 73
19 155 35 83 15 152 31 155 34 3 3 59 17 125 30 66 13 122 27 125 31 3 3 57
17 120 30 63 13 117 27 120 31 3 3 70 19 150 35 80 15 147 31 150 35 3 4 55
17 115 29 61 12 112 25 115 29 3 4 38 17 78 31 39 13 72 27 72 30 0 4 15 14
33 21 38 16 122 97 131 127 4 12 11 22 15 22 11 0 38 91 46 153 4 33 11 47 22
47 14 0 15 -14 11 -101z m-1288 -1091 c0 -18 -43 -77 -60 -83 -8 -3 -34 -22
-57 -42 -77 -69 -98 -85 -135 -104 -21 -11 -38 -23 -38 -27 0 -4 -17 -14 -37
-21 -21 -7 -51 -23 -68 -35 -16 -12 -55 -30 -85 -40 -30 -11 -57 -22 -60 -26
-6 -8 -167 -60 -186 -60 -7 0 -14 -4 -16 -9 -1 -5 -50 -19 -108 -32 -58 -13
-107 -26 -110 -30 -10 -14 -247 -39 -365 -39 -118 0 -294 22 -305 39 -3 4 -27
16 -53 26 -27 10 -51 24 -54 32 -3 7 -10 13 -17 13 -19 1 -75 55 -77 76 -5 47
0 54 36 54 19 0 35 -4 35 -8 0 -9 189 -52 227 -52 12 0 23 -5 25 -11 2 -7 62
-13 168 -16 182 -6 372 4 349 18 -10 6 -5 9 16 9 52 0 246 43 232 52 -8 5 -6
8 7 8 27 0 177 42 171 48 -3 3 36 18 85 34 50 16 90 33 90 38 0 4 24 16 53 25
28 10 57 22 62 29 6 6 35 22 65 36 30 14 57 28 60 32 3 4 31 19 63 33 31 15
57 31 57 36 0 5 7 9 15 9 8 0 15 -5 15 -12z"/>
<path id="2" d="M13810 10980 c0 -6 -31 -10 -72 -10 -74 -1 -206 -15 -241 -26 -19 -6
-19 -20 -12 -648 4 -352 10 -643 14 -646 16 -11 34 -1555 38 -3240 l5 -1785
316 24 c175 13 319 27 322 31 3 3 73 12 155 19 150 13 276 27 328 37 l27 6 0
344 c0 223 4 344 10 344 6 0 10 58 10 155 0 97 -4 155 -10 155 -7 0 -10 299
-10 878 0 483 -3 1662 -7 2619 -6 1731 -6 1740 -26 1735 -14 -3 -18 -1 -13 6
5 9 -97 12 -413 12 -274 0 -421 -3 -421 -10z"/>
<path id="3" d="M14784 10139 c18 -2641 13 -5397 -9 -5451 -7 -18 -70 -32 -235 -50
-58 -7 -107 -15 -110 -19 -5 -7 -468 -49 -545 -49 -25 0 -45 -4 -45 -8 0 -5
-66 -12 -147 -16 -81 -4 -149 -9 -152 -12 -7 -7 -19 -678 -20 -1136 l-1 -397
38 -10 c20 -6 43 -11 50 -11 6 0 12 -4 12 -8 0 -5 71 -21 158 -37 86 -15 159
-31 162 -35 3 -4 77 -18 165 -30 88 -13 162 -26 165 -30 3 -4 84 -17 180 -30
96 -13 177 -27 180 -30 13 -16 562 -64 626 -55 l41 6 7 194 c8 253 8 6759 0
7458 -5 377 -11 540 -18 547 -21 16 -136 29 -323 36 l-184 7 5 -834z"/>
<path fill="black" d="M17520 6093 c-14 -3 -27 -8 -30 -12 -3 -4 -31 -14 -62 -24 -32 -10
-63 -23 -70 -30 -16 -15 -71 -51 -78 -52 -6 0 -144 -142 -177 -181 -24 -28
-53 -75 -60 -96 -3 -10 -9 -18 -15 -18 -5 0 -18 -21 -29 -47 -11 -26 -24 -50
-29 -53 -4 -3 -20 -45 -36 -95 -15 -49 -31 -92 -34 -95 -10 -7 -34 -140 -26
-147 3 -4 6 0 6 9 0 8 11 25 25 38 14 13 25 28 25 33 0 15 93 107 107 107 7 0
16 6 20 14 10 16 98 56 125 56 10 0 16 5 13 10 -4 6 13 11 42 12 82 2 158 -4
161 -13 2 -5 9 -9 16 -9 25 0 146 -42 146 -51 0 -5 15 -16 33 -25 32 -16 167
-146 167 -161 0 -4 11 -21 24 -36 13 -15 32 -58 42 -95 10 -37 23 -70 28 -74
15 -10 15 -360 0 -379 -6 -8 -22 -49 -34 -91 -13 -43 -26 -78 -30 -78 -4 0
-15 -19 -25 -42 -10 -24 -26 -52 -36 -63 -56 -66 -125 -126 -164 -142 -25 -10
-45 -21 -45 -25 0 -5 34 -8 75 -8 43 0 75 4 75 10 0 6 9 10 21 10 32 0 133 31
144 44 6 6 30 20 54 31 45 20 181 142 181 162 0 7 8 18 18 25 10 7 29 36 43
63 13 28 29 56 36 63 13 14 43 110 50 164 3 21 9 35 13 33 14 -9 23 142 18
308 -3 110 -8 162 -16 165 -7 2 -12 13 -12 25 0 35 -49 240 -58 246 -5 3 -19
35 -32 71 -13 36 -26 67 -30 70 -3 3 -17 26 -29 53 -13 26 -26 47 -31 47 -4 0
-11 8 -14 18 -25 68 -153 188 -235 219 -25 9 -49 21 -52 26 -8 11 -180 19
-219 10z"/>
<path fill="black" d="M17063 5273 c-13 -2 -23 -9 -23 -14 0 -5 -8 -9 -19 -9 -10 0 -24 -7
-31 -15 -7 -8 -18 -15 -26 -15 -7 0 -23 -21 -36 -47 -13 -27 -27 -51 -33 -55
-13 -10 -12 -148 2 -156 6 -4 13 -16 17 -27 10 -34 76 -94 114 -105 54 -15
147 -12 190 6 44 18 99 74 108 109 3 14 10 25 15 25 5 0 9 34 9 76 0 41 -4 73
-9 70 -5 -3 -11 7 -14 23 -7 32 -67 97 -101 107 -11 4 -23 10 -26 15 -7 10
-106 19 -137 12z"/>
<path fill="black" d="M17362 4888 c-7 -7 -12 -18 -12 -25 0 -7 -4 -13 -10 -13 -22 0 -9
-56 21 -87 38 -42 69 -42 110 -2 28 27 31 35 26 72 -3 23 -14 47 -23 54 -22
16 -96 17 -112 1z"/>
<path fill="black" d="M20045 6064 c-48 -12 -120 -37 -125 -43 -3 -3 -25 -19 -49 -35 -87
-56 -191 -181 -191 -230 0 -8 -5 -16 -11 -18 -13 -4 -36 -105 -45 -200 -4 -38
-11 -68 -15 -68 -5 0 -9 -9 -9 -20 0 -11 2 -20 5 -20 3 0 32 27 64 60 33 33
66 60 74 60 9 0 23 7 32 16 23 23 117 44 200 44 68 0 172 -21 184 -37 3 -5 24
-17 46 -28 48 -24 143 -119 151 -152 4 -13 10 -23 14 -23 10 0 50 -103 50
-130 0 -10 5 -20 11 -22 7 -2 11 -47 11 -123 0 -76 -4 -121 -11 -123 -6 -2
-11 -11 -11 -19 0 -30 -41 -154 -58 -174 -46 -56 -120 -120 -157 -136 -22 -9
-42 -21 -45 -25 -10 -13 -93 -28 -157 -28 -62 0 -63 0 -44 -19 19 -19 151 -71
182 -71 9 0 19 -5 21 -12 6 -16 235 -18 246 -2 4 6 27 16 52 23 47 13 150 93
150 116 0 7 7 15 15 19 8 3 22 23 31 46 9 22 19 40 24 40 8 0 50 139 50 168 0
11 6 22 13 25 9 3 12 53 12 207 0 154 -3 204 -12 208 -7 2 -13 12 -13 22 0 29
-41 194 -49 200 -5 3 -16 29 -25 58 -10 29 -23 58 -29 65 -7 6 -23 35 -36 62
-14 28 -33 56 -43 63 -10 7 -18 19 -18 25 0 18 -132 142 -172 163 -18 9 -35
20 -38 24 -19 28 -215 59 -275 44z"/>
<path fill="black" d="M19702 5419 c-23 -12 -49 -33 -57 -48 -39 -76 -25 -175 29 -213 17
-12 34 -26 38 -32 3 -5 31 -11 60 -14 41 -3 58 0 70 12 9 9 21 16 26 16 10 0
52 43 52 54 0 4 7 14 15 22 16 17 19 95 5 129 -5 11 -9 16 -9 10 -1 -5 -10 5
-21 22 -37 61 -131 80 -208 42z"/>
<path fill="black" d="M19957 5163 c-20 -23 -22 -64 -5 -81 31 -31 108 0 108 44 0 42 -74
68 -103 37z"/>
<path fill="black" d="M8426 11053 c-179 -2 -322 -7 -326 -12 -7 -13 -1615 -28 -4390 -42
-1262 -7 -2266 -15 -2268 -20 -2 -5 -12 -9 -22 -9 -11 0 -45 -10 -76 -21 -64
-24 -124 -78 -156 -142 -12 -23 -27 -45 -34 -49 -7 -4 -20 -30 -30 -58 -10
-27 -21 -50 -25 -50 -4 0 -17 -33 -30 -72 -13 -40 -27 -75 -30 -78 -4 -3 -16
-46 -26 -95 -26 -132 -26 -649 1 -812 10 -62 22 -113 27 -113 5 0 9 -5 9 -11
0 -22 32 -119 43 -131 7 -7 22 -33 33 -58 33 -69 134 -182 174 -195 11 -3 20
-10 20 -15 0 -5 33 -19 73 -31 39 -12 74 -25 77 -29 3 -4 75 -20 160 -35 85
-14 162 -30 172 -35 9 -5 99 -19 200 -32 100 -12 185 -25 188 -29 5 -6 422
-49 478 -49 17 0 32 -4 32 -8 0 -8 191 -25 590 -54 91 -6 167 -15 170 -18 3
-3 149 -17 325 -29 176 -13 329 -27 340 -31 19 -8 755 -60 843 -60 23 0 42 -4
42 -8 0 -4 172 -19 383 -32 210 -13 384 -26 387 -30 3 -4 163 -17 355 -30 193
-13 352 -27 355 -30 3 -4 106 -15 230 -24 124 -9 293 -22 375 -28 83 -6 152
-14 155 -18 3 -4 140 -17 305 -29 165 -12 307 -26 315 -30 9 -5 166 -19 350
-32 184 -12 337 -26 340 -29 5 -7 430 -33 839 -52 141 -6 258 -14 261 -18 5
-6 521 -31 970 -47 96 -3 177 -9 180 -13 9 -13 805 -29 944 -19 72 5 132 12
135 17 4 4 46 17 95 30 50 12 92 26 95 31 4 5 31 19 61 32 30 12 66 33 79 46
13 13 30 23 37 23 8 0 21 11 30 25 9 14 22 25 28 25 7 0 24 12 38 28 13 15 40
41 59 58 34 31 112 115 157 170 13 15 28 38 33 51 5 13 12 23 17 23 5 0 21 29
37 65 16 36 32 65 36 65 4 0 17 27 29 60 12 33 26 60 30 60 4 0 17 35 30 78
13 42 26 79 30 82 9 6 50 168 50 197 0 12 4 23 9 25 22 8 44 263 44 508 0 240
-19 455 -42 469 -4 3 -11 24 -15 46 -12 68 -37 145 -47 145 -5 0 -9 6 -9 14 0
17 -38 109 -49 116 -4 3 -16 23 -27 45 -10 22 -27 50 -38 61 -10 12 -30 35
-45 52 -14 16 -31 38 -37 48 -6 11 -21 24 -32 30 -12 6 -31 23 -42 37 -12 15
-27 27 -34 27 -7 0 -25 11 -39 25 -17 17 -43 29 -78 34 -30 5 -353 12 -719 16
-366 4 -667 11 -670 15 -5 8 -2047 19 -2564 13z m3944 -163 l25 -8 -32 -13
c-18 -7 -33 -17 -33 -21 0 -4 -12 -8 -27 -8 -27 -1 -27 -1 9 -19 21 -11 44
-26 52 -34 9 -8 31 -25 50 -37 19 -11 38 -29 41 -38 3 -9 21 -35 40 -57 19
-22 35 -45 35 -52 0 -7 10 -23 21 -34 12 -12 30 -47 41 -78 11 -31 23 -58 27
-61 11 -8 39 -104 53 -176 6 -35 15 -64 18 -64 16 0 35 -206 35 -380 0 -170
-18 -357 -34 -370 -4 -3 -19 -54 -34 -115 -15 -60 -31 -112 -36 -115 -4 -3
-18 -36 -32 -75 -13 -38 -27 -72 -31 -75 -4 -3 -16 -28 -28 -55 -12 -27 -25
-52 -29 -55 -5 -3 -20 -29 -35 -57 -14 -29 -30 -53 -35 -53 -6 0 -12 -8 -16
-19 -3 -10 -23 -38 -45 -62 -22 -24 -40 -47 -40 -51 0 -4 -16 -22 -36 -40 -20
-18 -48 -44 -63 -58 -60 -55 -106 -90 -118 -90 -6 0 -16 -6 -20 -13 -10 -16
-88 -57 -108 -57 -7 0 -20 -6 -28 -14 -17 -18 -110 -43 -192 -52 -33 -4 -63
-12 -67 -18 -9 -14 -657 -13 -667 1 -5 8 -329 34 -626 50 -66 3 -122 9 -125
13 -5 6 -804 61 -877 60 -17 0 -33 4 -35 9 -3 7 -295 24 -848 48 -74 3 -137 9
-140 13 -3 3 -216 17 -475 30 -258 13 -472 26 -475 30 -3 3 -214 19 -470 34
-256 16 -467 32 -470 36 -3 4 -174 18 -380 31 -206 12 -377 25 -380 29 -3 3
-214 19 -470 34 -256 15 -474 31 -485 36 -11 4 -177 18 -370 30 -192 12 -359
25 -370 30 -17 7 -296 28 -625 47 -58 3 -107 9 -110 12 -3 4 -176 20 -385 35
-209 16 -382 32 -385 36 -3 4 -39 10 -80 14 -165 15 -355 39 -360 46 -3 4 -79
18 -170 30 -91 13 -167 26 -170 30 -3 4 -39 12 -80 19 -94 15 -203 41 -210 51
-3 4 -22 13 -43 19 -21 7 -43 21 -50 32 -6 10 -17 19 -23 19 -14 0 -79 77 -95
114 -7 14 -16 26 -20 26 -9 0 -59 158 -59 187 0 12 -4 23 -9 25 -14 5 -31 195
-31 342 0 147 18 398 30 406 4 3 12 34 19 69 14 71 42 163 52 171 3 3 14 21
24 40 17 32 71 90 123 132 52 42 76 43 1042 43 509 0 1325 -6 1815 -14 545 -9
1136 -12 1525 -8 728 6 1885 35 1915 47 61 25 4619 64 4695 40z"/>
<path id="4" d="M10648 10833 l-238 -3 -2 -1160 -3 -1160 42 0 c23 0 43 -4 45 -9 12
-32 1042 -51 1198 -22 41 7 77 16 80 20 3 3 38 17 78 29 39 13 72 27 72 31 0
4 23 15 50 25 28 10 54 24 58 31 4 7 21 20 37 29 52 28 65 39 139 112 75 74
121 128 146 171 8 14 25 37 38 51 12 14 22 31 22 38 0 7 6 19 13 26 13 15 39
66 57 116 7 17 16 32 20 32 4 0 10 10 13 23 17 71 40 143 47 147 5 3 19 57 32
120 13 63 27 118 33 122 15 10 14 526 -1 536 -6 4 -14 29 -18 55 -10 63 -35
160 -44 167 -4 3 -18 32 -32 65 -13 33 -32 69 -42 79 -9 10 -15 22 -13 26 3 5
-9 22 -25 40 -17 18 -30 35 -30 39 0 16 -116 124 -158 147 -26 15 -50 30 -53
35 -3 5 -31 18 -62 29 -54 19 -80 20 -659 18 -332 -2 -710 -4 -840 -5z"/>
<path id="5" d="M9295 10814 c-478 -6 -919 -12 -980 -13 l-110 -1 -2 -1069 -2 -1068
77 -6 c42 -4 178 -11 302 -18 124 -6 234 -14 245 -19 11 -5 214 -18 450 -31
237 -12 432 -25 435 -29 5 -8 479 -36 483 -29 2 2 1 520 -1 1152 -3 858 -7
1147 -15 1145 -7 -1 -403 -7 -882 -14z"/>
<path fill="black" d="M10259 10038 c-1 -436 -4 -955 -8 -1153 -3 -198 -6 -361 -6 -361 0
-1 29 -4 65 -8 l65 -6 0 1160 0 1160 -57 0 -58 0 -1 -792z"/>
<path fill="black" d="M8015 9738 l0 -1063 35 -7 c19 -4 53 -7 75 -7 l40 -1 1 1070 1 1070
-76 0 -76 0 0 -1062z"/>
<path id="6" d="M7255 10765 c-236 -7 -650 -18 -920 -25 -269 -7 -492 -15 -495 -17
-6 -5 0 -1876 6 -1882 2 -2 50 -6 106 -9 57 -3 105 -8 108 -11 5 -6 929 -62
1012 -61 20 0 38 -4 40 -8 2 -7 723 -53 811 -52 l27 0 0 1040 0 1040 -132 -1
c-73 -1 -326 -7 -563 -14z"/>
<path id="7" d="M1487 10746 c-43 -12 -81 -42 -135 -110 -16 -20 -52 -103 -52 -121 0
-7 -4 -15 -9 -17 -14 -4 -41 -166 -48 -285 -3 -57 -10 -103 -15 -103 -4 0 -8
-63 -8 -140 0 -77 4 -140 9 -140 4 0 11 -35 14 -77 8 -89 33 -214 45 -222 4
-3 14 -25 22 -49 7 -24 24 -53 37 -65 12 -12 23 -27 23 -33 0 -11 42 -54 53
-54 4 0 15 -8 25 -18 9 -9 37 -24 62 -32 25 -8 47 -18 50 -22 7 -9 192 -48
230 -48 16 0 30 -4 32 -9 3 -8 315 -37 323 -30 1 2 5 360 6 794 l4 790 -310 2
c-227 1 -323 -2 -358 -11z"/>
<path fill="black" d="M2215 9957 l0 -802 48 -3 47 -3 0 799 0 800 -22 6 c-13 3 -34 6 -48
6 l-25 0 0 -803z"/>
<path id="8" d="M2348 9947 l-3 -807 22 0 c12 0 23 -4 25 -9 2 -5 158 -19 348 -32
190 -12 347 -26 350 -29 5 -6 310 -30 378 -30 l32 0 0 844 c0 764 -2 844 -16
850 -9 3 -267 9 -575 13 l-559 6 -2 -806z"/>
<path fill="black" d="M3577 9958 c1 -431 2 -818 2 -860 l1 -78 34 0 c19 0 37 -6 39 -12 3
-7 6 377 6 855 l1 867 -29 0 c-16 0 -35 2 -43 5 -11 4 -13 -129 -11 -777z"/>
<path id="9" d="M3712 9877 c-1 -468 1 -857 5 -864 6 -8 140 -21 418 -39 226 -15 412
-30 415 -34 5 -6 646 -51 722 -50 20 0 38 -4 40 -9 2 -4 63 -12 136 -16 l132
-7 0 506 c0 338 -3 506 -10 506 -7 0 -10 139 -8 420 l3 420 -847 0 c-559 0
-848 3 -848 10 0 6 -32 10 -77 10 l-78 0 -3 -853z"/>
<path d="M5720 10720 c0 -5 -17 -10 -37 -10 l-38 0 3 -927 c2 -510 5 -929 6
-931 2 -1 32 -5 67 -8 l64 -6 0 946 0 946 -32 0 c-18 0 -33 -4 -33 -10z"/>
<path d="M18640 10982 c0 -5 -159 -12 -352 -15 -315 -7 -788 -25 -1120 -43
-65 -4 -118 -11 -118 -16 0 -4 -24 -8 -52 -8 -48 0 -252 -19 -385 -36 -29 -4
-50 -11 -47 -15 3 -5 -4 -9 -16 -9 -26 0 -173 -39 -180 -48 -3 -4 -31 -20 -62
-35 -32 -16 -58 -32 -58 -36 0 -4 -12 -14 -26 -21 -14 -8 -32 -26 -40 -41 -7
-15 -20 -33 -27 -41 -25 -24 -45 -72 -52 -123 -4 -28 -11 -52 -15 -55 -4 -3
-14 -63 -21 -135 -27 -253 -9 -1940 21 -1980 4 -5 10 -86 14 -180 10 -270 37
-640 47 -643 5 -2 9 -20 9 -40 0 -48 26 -206 37 -225 23 -42 87 -97 130 -113
26 -10 51 -22 54 -26 26 -38 674 -60 1169 -39 448 18 710 34 710 43 0 4 15 8
33 8 62 -1 596 44 591 50 -5 5 123 16 551 51 83 6 152 15 155 19 3 4 136 17
295 30 160 13 292 26 295 30 4 6 236 26 525 47 50 3 92 9 95 13 3 4 92 12 198
17 106 6 195 13 199 17 8 8 8 94 0 102 -10 10 -222 4 -225 -6 -2 -6 -22 -10
-45 -10 -82 0 -672 -43 -677 -50 -3 -4 -52 -10 -110 -13 -290 -18 -706 -51
-710 -57 -3 -4 -151 -17 -330 -30 -179 -13 -327 -27 -330 -30 -3 -4 -160 -18
-350 -30 -190 -13 -347 -27 -350 -31 -3 -3 -108 -12 -235 -18 -126 -6 -329
-16 -450 -22 -372 -19 -842 3 -865 41 -3 4 -19 12 -35 16 -40 9 -82 31 -104
53 -10 10 -25 23 -32 27 -23 15 -47 77 -60 160 -7 43 -16 81 -20 84 -8 7 -26
151 -41 340 -6 85 -15 157 -19 160 -3 3 -12 88 -18 190 -7 102 -16 248 -21
325 -5 77 -10 179 -10 227 0 55 -4 88 -11 90 -15 5 -15 1128 -1 1137 5 3 12
58 15 123 8 129 32 271 47 276 5 2 10 10 10 18 0 55 101 150 194 180 28 9 53
21 56 25 7 10 155 49 187 49 12 0 23 4 25 9 5 15 238 38 513 52 138 7 252 16
255 19 9 12 827 42 1280 47 417 5 734 -2 1235 -29 121 -6 222 -14 225 -18 3
-3 79 -12 170 -18 225 -17 474 -43 480 -52 3 -3 35 -11 73 -16 l67 -10 0 57
c0 76 -14 106 -54 113 -69 12 -283 36 -328 36 -26 0 -48 4 -48 8 0 16 -618 48
-1117 58 -183 4 -333 11 -333 16 0 4 -103 8 -230 8 -126 0 -230 -4 -230 -8z"/>
<path id="10" d="M18690 10763 c-36 -1 -317 -10 -625 -19 -308 -9 -561 -21 -563 -25
-2 -5 -47 -9 -100 -9 -205 0 -630 -33 -642 -49 -3 -4 -60 -17 -127 -30 -68
-13 -123 -26 -123 -30 0 -4 -28 -16 -62 -26 -33 -10 -69 -26 -78 -35 -9 -9
-24 -19 -34 -23 -18 -7 -43 -58 -53 -110 -3 -18 -10 -34 -14 -37 -25 -17 -49
-465 -49 -910 0 -413 33 -1237 50 -1250 3 -3 12 -81 18 -175 17 -228 42 -449
53 -453 5 -2 9 -16 9 -31 0 -63 30 -144 68 -181 33 -34 110 -70 147 -70 7 0
15 -4 17 -9 7 -20 215 -31 595 -31 411 0 885 17 891 32 2 4 22 8 45 8 88 -1
726 44 722 51 -3 3 49 10 115 14 l120 7 0 271 c0 346 -21 1140 -30 1147 -4 3
-10 88 -13 190 -11 306 -39 815 -47 820 -3 3 -12 91 -18 195 -18 277 -35 455
-44 455 -4 0 -8 17 -8 38 0 20 -7 92 -16 160 l-17 122 -61 -2 c-33 -1 -90 -3
-126 -5z"/>
<path  fill="black"  d="M18965 10703 c4 -38 12 -71 19 -75 8 -6 7 -11 -4 -18 -11 -7 -12 -12
-3 -17 10 -8 53 -468 53 -577 0 -24 4 -46 8 -48 8 -3 30 -339 54 -813 6 -132
15 -242 18 -245 13 -10 35 -699 35 -1112 l0 -418 26 0 c15 0 41 3 58 6 l31 7
0 421 c0 482 -19 1219 -31 1224 -5 2 -9 43 -9 90 0 48 -7 206 -15 352 -8 146
-18 322 -21 392 -4 70 -11 125 -15 122 -5 -3 -9 14 -9 38 0 77 -42 553 -50
558 -4 3 -10 41 -14 84 -4 44 -9 83 -12 88 -3 4 -32 8 -66 8 l-60 0 7 -67z"/>
<path id="11" d="M19151 10723 c4 -27 8 -68 9 -93 0 -27 4 -40 8 -32 8 12 34 -267 49
-520 3 -57 9 -105 13 -108 10 -7 60 -936 60 -1117 0 -61 4 -93 12 -95 17 -6
18 -1280 1 -1285 -7 -3 -13 -21 -13 -40 l0 -35 118 7 c64 4 119 10 122 14 3 4
149 18 325 30 176 12 329 26 340 31 20 9 624 50 723 50 28 0 52 5 52 10 0 6
42 10 105 10 l105 0 0 63 c0 34 -9 226 -20 427 -11 201 -23 425 -26 498 -4 72
-10 132 -14 132 -4 0 -10 57 -13 128 -17 346 -41 718 -47 722 -4 3 -10 57 -13
120 -17 320 -41 656 -47 660 -4 3 -10 57 -14 120 -3 63 -9 141 -13 173 -5 55
-7 57 -34 57 -16 0 -29 4 -29 9 0 4 -39 11 -87 15 -316 25 -465 36 -510 36
-29 0 -53 4 -53 8 0 11 -793 51 -1011 52 l-106 0 8 -47z"/>
<path fill="black"  d="M2401 7308 c-24 -5 -53 -18 -64 -29 -12 -10 -26 -19 -33 -19 -7 0
-23 -15 -35 -32 -13 -18 -29 -37 -35 -43 -15 -12 -41 -90 -50 -148 -4 -23 -10
-44 -14 -47 -9 -6 -37 -245 -46 -392 -4 -57 -10 -105 -14 -108 -10 -7 -40
-639 -47 -977 -3 -156 -10 -283 -15 -283 -4 0 -8 -67 -8 -150 0 -93 4 -150 10
-150 6 0 18 7 28 16 9 8 27 23 40 32 l23 17 3 390 c3 380 11 549 26 540 4 -2
10 28 14 68 8 90 46 310 55 317 4 3 17 45 30 95 12 49 26 92 30 95 4 3 17 40
31 83 13 42 27 77 31 77 4 0 15 21 25 46 10 25 22 51 28 58 6 6 24 35 40 65
35 65 39 70 146 177 115 116 124 124 138 124 7 0 20 8 31 19 20 20 139 71 165
71 9 0 16 4 16 9 0 5 17 13 38 18 35 9 32 10 -55 22 -51 8 -93 17 -93 21 0 23
-352 38 -439 18z"/>
<path id="12" d="M3057 7184 c-43 -8 -85 -21 -93 -29 -9 -8 -22 -15 -31 -15 -14 0 -73
-29 -83 -41 -3 -3 -20 -14 -39 -24 -43 -22 -213 -190 -235 -233 -9 -18 -20
-32 -24 -32 -4 0 -18 -24 -32 -52 -14 -29 -32 -61 -41 -71 -9 -10 -22 -39 -29
-64 -7 -24 -18 -50 -24 -56 -12 -12 -56 -142 -56 -164 0 -6 -4 -13 -10 -15 -5
-1 -19 -50 -31 -108 -12 -58 -26 -107 -29 -110 -9 -6 -37 -202 -47 -315 -3
-44 -11 -83 -16 -87 -16 -10 -15 -630 1 -636 7 -2 12 -22 12 -47 0 -54 39
-288 50 -295 4 -3 17 -40 30 -82 13 -43 26 -78 30 -78 4 0 15 -25 25 -56 9
-30 23 -58 29 -60 7 -3 21 -27 31 -55 11 -28 27 -58 35 -67 9 -9 30 -37 47
-62 17 -25 40 -54 52 -64 11 -11 21 -22 21 -26 0 -4 18 -26 40 -49 22 -23 40
-39 40 -35 0 4 13 -6 28 -22 35 -36 88 -74 102 -74 5 0 10 -4 10 -9 0 -11 95
-61 116 -61 8 0 14 -4 14 -10 0 -11 121 -50 157 -50 13 0 22 -4 19 -9 -14 -23
357 -26 374 -3 3 4 45 17 94 30 50 12 92 26 95 30 4 5 31 20 61 34 30 14 68
36 83 49 38 32 177 170 177 176 0 5 42 64 59 82 8 9 20 30 28 48 7 18 17 33
22 33 6 0 13 10 16 23 4 12 24 58 44 102 21 44 44 101 51 128 7 26 16 47 20
47 4 0 18 44 31 98 12 53 26 99 29 102 9 6 47 201 56 283 4 31 10 57 14 57 4
0 10 26 13 58 37 371 39 413 34 712 -4 299 -28 591 -48 598 -5 2 -9 14 -9 26
0 12 -9 55 -20 95 -17 59 -29 82 -61 113 -21 21 -43 38 -47 38 -5 0 -17 8 -28
18 -21 20 -104 62 -122 62 -7 0 -12 4 -12 8 0 5 -30 19 -67 31 -38 13 -86 31
-108 41 -22 10 -74 26 -115 36 -41 10 -77 21 -80 25 -3 4 -57 17 -120 30 -63
12 -117 25 -120 29 -8 11 -214 40 -277 39 -32 0 -93 -7 -136 -15z m382 -325
c36 -7 68 -15 71 -18 3 -3 32 -17 65 -31 33 -13 69 -32 79 -42 11 -10 24 -18
28 -18 13 0 158 -146 158 -158 0 -6 9 -17 19 -24 11 -8 30 -36 43 -63 12 -28
28 -56 35 -62 6 -7 21 -45 34 -85 12 -40 26 -75 30 -78 5 -3 18 -57 30 -120
11 -63 26 -126 33 -139 17 -36 16 -593 -1 -599 -7 -2 -13 -14 -13 -27 0 -35
-40 -239 -48 -245 -4 -3 -20 -44 -35 -92 -16 -49 -32 -88 -36 -88 -4 0 -18
-26 -31 -57 -13 -32 -32 -64 -42 -71 -10 -7 -18 -19 -18 -27 0 -8 -12 -28 -27
-45 -52 -59 -184 -190 -192 -190 -4 0 -18 -9 -31 -20 -13 -11 -49 -30 -82 -41
-32 -12 -58 -25 -58 -29 0 -11 -115 -30 -177 -30 -65 0 -164 20 -181 37 -7 7
-20 13 -28 13 -17 0 -70 28 -103 55 -69 56 -169 165 -197 215 -10 19 -27 43
-36 54 -10 11 -18 27 -18 35 -1 18 -29 71 -30 56 -1 -14 -25 56 -46 133 -10
37 -21 69 -25 72 -4 3 -15 50 -25 105 -26 145 -27 535 0 693 10 61 22 112 27
112 5 0 9 6 9 14 0 22 50 190 59 196 4 3 18 31 31 63 13 31 27 57 31 57 4 0
18 21 30 48 13 26 29 53 36 60 7 8 18 23 25 35 11 22 30 46 81 99 15 16 27 32
27 36 0 10 43 52 53 52 5 0 22 12 37 28 52 49 70 62 118 85 26 12 49 25 52 28
9 11 84 27 129 28 24 1 74 -4 110 -10z"/>
<path id="13" d="M3265 6799 c-21 -5 -42 -13 -45 -17 -3 -5 -26 -19 -52 -31 -48 -23
-89 -53 -137 -102 -14 -14 -34 -29 -44 -32 -9 -4 -20 -15 -24 -24 -3 -10 -20
-32 -37 -49 -17 -17 -38 -43 -46 -59 -8 -16 -25 -40 -37 -53 -13 -14 -23 -31
-23 -38 0 -7 -8 -21 -18 -31 -11 -10 -27 -39 -38 -65 -10 -27 -22 -48 -26 -48
-3 0 -16 -33 -28 -72 -13 -40 -26 -77 -30 -83 -4 -5 -16 -55 -26 -110 -27
-142 -27 -541 0 -700 10 -60 21 -112 25 -115 4 -3 17 -43 29 -90 13 -46 26
-87 31 -90 4 -3 16 -27 26 -53 10 -26 23 -51 29 -55 6 -4 20 -23 30 -42 43
-78 149 -179 211 -202 19 -6 35 -15 35 -18 0 -34 218 -52 318 -26 35 9 70 23
78 31 9 8 21 15 28 15 7 0 28 14 46 30 18 17 37 30 40 30 8 0 106 94 112 107
11 26 51 83 57 83 4 0 18 23 31 50 13 28 26 50 30 50 4 0 17 33 30 73 14 39
28 74 32 77 9 7 48 166 48 198 0 12 4 22 9 22 15 0 34 221 34 395 -1 180 -19
398 -34 403 -5 2 -9 12 -9 23 0 34 -42 189 -51 189 -5 0 -9 6 -9 14 0 16 -40
109 -49 116 -4 3 -17 24 -28 46 -25 49 -68 99 -136 159 -27 24 -106 65 -125
65 -7 0 -12 4 -12 9 0 11 -112 31 -166 30 -21 -1 -57 -5 -79 -10z m144 -322
c14 -7 32 -17 39 -24 7 -7 18 -13 23 -13 16 0 93 -84 118 -131 14 -24 28 -47
33 -50 5 -3 18 -39 29 -80 12 -41 25 -78 29 -84 17 -22 31 -169 31 -330 0
-167 -16 -355 -31 -365 -4 -3 -10 -23 -14 -45 -12 -69 -36 -145 -46 -145 -6 0
-10 -7 -10 -15 0 -22 -40 -98 -56 -108 -8 -4 -14 -14 -14 -21 0 -16 -92 -102
-119 -111 -12 -3 -21 -10 -21 -15 0 -15 -93 -40 -149 -40 -99 0 -139 20 -230
111 -39 40 -55 61 -73 99 -7 14 -16 28 -21 31 -12 9 -45 107 -53 155 -3 24
-10 44 -14 44 -4 0 -11 28 -15 63 -7 62 -32 137 -47 137 -4 0 -17 33 -29 73
-12 39 -27 75 -33 79 -14 10 -15 186 0 196 6 4 17 23 25 43 27 68 116 173 165
195 25 10 34 23 44 61 8 26 16 50 20 53 4 3 17 27 31 54 22 45 70 100 130 151
24 20 64 39 104 49 29 7 124 -4 154 -17z"/>
<path id="14" d="M3209 6408 c-8 -7 -27 -20 -42 -28 -40 -20 -77 -59 -94 -97 -7 -18
-17 -33 -22 -33 -9 0 -34 -60 -28 -66 6 -6 77 20 77 28 0 4 14 8 30 8 17 0 30
-4 30 -9 0 -5 13 -11 28 -14 38 -8 131 -101 155 -157 11 -25 24 -48 29 -51 17
-11 41 -156 42 -254 1 -108 -18 -228 -40 -250 -8 -7 -14 -20 -14 -28 0 -8 -13
-29 -30 -47 -16 -18 -30 -34 -30 -37 0 -15 -69 -65 -108 -79 -63 -21 -166 -8
-192 25 -12 15 -60 41 -60 32 0 -19 41 -141 48 -141 4 0 18 -21 31 -46 53 -99
124 -147 220 -147 93 0 151 34 244 143 21 25 67 119 67 138 0 6 4 12 8 12 10
0 52 175 52 214 0 13 6 26 13 28 16 6 18 435 1 446 -6 4 -14 30 -18 57 -10 67
-38 165 -47 165 -4 0 -14 18 -23 41 -16 40 -85 119 -105 119 -5 0 -17 8 -27
18 -23 23 -173 31 -195 10z"/>
<path id="15" d="M3080 6150 c0 -5 -6 -10 -14 -10 -20 0 -93 -34 -114 -53 -24 -21 -80
-78 -102 -102 -10 -11 -21 -30 -25 -42 -3 -13 -14 -30 -23 -40 -14 -13 -17
-36 -17 -116 0 -71 4 -102 13 -105 6 -2 12 -11 12 -20 0 -18 37 -98 56 -121
74 -90 155 -151 199 -151 8 0 15 -4 15 -10 0 -5 18 -10 40 -10 22 0 40 5 40
10 0 6 8 10 18 10 45 0 122 95 122 152 0 9 5 18 10 20 19 6 33 220 21 310 -6
45 -15 84 -19 87 -5 3 -17 28 -27 54 -19 51 -85 127 -110 127 -8 0 -15 5 -15
10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z"/>
<path fill="black" d="M9790 6549 c-86 -4 -146 -12 -148 -18 -2 -6 -15 -11 -28 -11 -50 0
-146 -40 -178 -75 -35 -38 -66 -90 -66 -110 0 -8 -7 -21 -16 -29 -8 -9 -25
-51 -36 -94 -12 -42 -25 -79 -29 -82 -8 -5 -45 -184 -55 -267 -4 -29 -10 -53
-14 -53 -7 0 -33 -183 -46 -325 -4 -38 -10 -72 -14 -75 -11 -8 -31 -319 -36
-570 -4 -226 -4 -233 12 -191 9 24 22 51 30 58 8 8 14 21 14 28 0 16 31 87 41
95 4 3 19 34 34 70 15 36 31 67 35 70 4 3 18 29 30 58 12 28 26 52 30 52 3 0
17 23 30 50 13 28 26 50 29 50 3 0 17 21 30 48 14 26 30 52 36 57 6 6 21 27
34 48 12 20 27 37 32 37 5 0 9 5 9 10 0 11 31 58 60 91 8 9 29 34 45 54 39 47
263 271 310 310 20 17 46 38 56 48 11 10 38 27 59 38 22 12 40 24 40 28 0 4
27 20 60 36 33 17 60 33 60 36 0 4 25 17 55 29 30 12 55 26 55 30 0 6 77 33
165 57 11 3 22 9 25 13 6 9 191 50 224 50 14 0 26 6 29 13 5 16 574 18 585 1
4 -6 32 -14 62 -18 76 -9 214 -38 220 -46 3 -4 45 -18 94 -30 48 -13 94 -29
100 -35 7 -7 23 -16 36 -20 31 -10 213 -105 220 -115 3 -4 21 -15 40 -25 19
-10 48 -31 64 -46 17 -16 34 -29 39 -29 10 0 25 -13 133 -119 40 -38 80 -83
90 -100 10 -17 22 -31 26 -31 5 0 13 -12 19 -26 20 -53 21 -12 4 96 -10 60
-21 110 -25 110 -5 0 -17 30 -29 68 -11 37 -28 78 -38 91 -38 51 -108 121
-120 121 -6 0 -16 6 -20 13 -4 8 -35 27 -68 43 -33 16 -65 34 -71 40 -6 5 -39
18 -73 29 -33 10 -61 21 -61 25 0 4 -37 18 -82 30 -46 13 -85 27 -88 32 -5 7
-52 19 -200 50 -30 6 -57 15 -60 18 -3 4 -63 18 -135 31 -71 12 -132 26 -135
30 -3 3 -75 17 -160 30 -85 13 -157 26 -160 30 -6 8 -446 59 -508 59 -23 0
-42 4 -42 9 0 8 -109 15 -405 25 -60 2 -175 0 -255 -5z"/>
<path id="16" d="M10978 6093 c-38 -2 -68 -8 -68 -13 0 -6 -13 -10 -28 -10 -43 0 -256
-40 -262 -49 -3 -4 -18 -11 -35 -15 -60 -14 -170 -50 -175 -57 -3 -4 -28 -17
-55 -29 -27 -12 -52 -25 -55 -29 -3 -4 -26 -18 -52 -30 -27 -13 -48 -26 -48
-29 0 -4 -23 -20 -50 -36 -27 -16 -47 -33 -44 -38 3 -4 -1 -8 -9 -8 -8 0 -22
-9 -32 -20 -10 -11 -21 -20 -26 -20 -10 0 -269 -259 -269 -270 0 -4 -10 -17
-22 -27 -28 -22 -68 -78 -68 -93 0 -6 -6 -13 -13 -16 -7 -2 -24 -22 -37 -44
-14 -22 -28 -40 -32 -40 -4 0 -17 -22 -30 -50 -13 -27 -26 -50 -30 -50 -4 0
-17 -19 -29 -42 -12 -24 -25 -45 -29 -48 -4 -3 -20 -33 -35 -67 -15 -35 -31
-63 -35 -63 -5 0 -19 -29 -31 -64 -13 -36 -26 -63 -29 -61 -3 2 -17 -27 -30
-66 -13 -38 -27 -69 -31 -69 -4 0 -9 -10 -12 -22 -14 -61 -50 -174 -57 -178
-4 -3 -18 -57 -31 -120 -13 -63 -27 -117 -30 -120 -14 -11 -41 -332 -46 -557
-3 -134 -10 -243 -15 -243 -4 0 -8 -34 -8 -75 0 -43 4 -75 10 -75 6 0 10 -73
10 -197 0 -254 32 -639 51 -627 5 3 9 -7 9 -21 0 -34 41 -238 49 -245 3 -3 19
-58 35 -123 16 -64 33 -117 37 -117 4 0 17 -31 29 -70 12 -38 25 -70 29 -70 4
0 15 -25 26 -56 11 -31 24 -62 30 -68 5 -6 23 -39 39 -73 16 -35 32 -63 35
-63 3 0 17 -21 31 -47 13 -27 33 -54 42 -61 10 -7 18 -18 18 -23 0 -25 216
-255 280 -299 19 -13 41 -32 48 -42 7 -10 19 -18 26 -18 7 0 22 -9 34 -20 12
-12 46 -33 75 -47 28 -14 54 -29 57 -32 3 -4 25 -15 50 -25 25 -10 63 -26 85
-36 46 -21 148 -47 220 -56 28 -4 52 -11 55 -15 3 -5 80 -14 171 -20 176 -13
513 1 533 21 6 6 20 10 32 10 37 0 298 50 304 58 3 5 43 18 90 30 46 13 87 26
90 30 3 4 23 13 45 20 53 17 119 45 125 52 3 4 28 17 55 30 28 13 55 29 61 34
6 6 27 20 47 31 20 11 37 23 37 27 0 4 15 14 33 23 18 8 53 33 78 55 24 22 50
40 57 40 7 0 10 5 7 10 -3 6 -2 10 4 10 11 0 251 237 251 248 0 4 16 24 34 46
19 21 40 50 47 65 8 14 21 33 30 41 9 8 24 35 35 60 10 25 22 48 27 51 5 3 -6
12 -23 19 -17 7 -34 18 -38 24 -4 6 -26 24 -49 40 -24 16 -43 33 -43 38 0 5
-11 19 -23 32 -13 13 -32 43 -41 66 -16 39 -16 82 0 683 10 353 20 644 24 647
6 5 51 1541 50 1738 0 61 4 102 10 102 6 0 10 32 10 75 0 41 -3 75 -7 75 -5 0
-16 20 -26 43 -10 24 -24 52 -32 63 -27 34 -65 95 -65 102 0 4 -9 13 -20 20
-11 7 -20 17 -20 23 0 22 -226 231 -272 252 -16 6 -28 15 -28 19 0 4 -19 17
-42 29 -24 12 -45 25 -48 28 -3 4 -30 18 -60 30 -30 13 -57 27 -60 30 -6 9
-81 36 -140 51 -25 7 -47 16 -50 20 -6 10 -211 50 -255 50 -18 0 -35 5 -37 10
-3 11 -313 21 -430 13z m222 -712 c0 -4 20 -11 44 -14 68 -10 166 -39 160 -49
-3 -4 1 -8 9 -8 20 0 107 -39 107 -49 0 -4 15 -15 33 -24 50 -26 63 -37 140
-114 84 -83 125 -132 149 -175 10 -17 22 -34 27 -37 11 -7 51 -76 87 -151 14
-30 29 -57 33 -60 4 -3 20 -44 35 -92 15 -48 31 -88 35 -90 3 -2 17 -46 30
-98 14 -52 28 -97 32 -100 8 -5 49 -237 49 -275 0 -13 4 -25 9 -27 15 -6 33
-276 34 -508 0 -217 -18 -520 -32 -530 -3 -3 -17 -68 -30 -145 -12 -77 -26
-142 -30 -145 -5 -3 -11 -18 -15 -35 -12 -54 -40 -140 -46 -145 -4 -3 -19 -40
-35 -82 -15 -43 -31 -78 -34 -78 -3 0 -17 -27 -30 -60 -13 -33 -27 -60 -32
-60 -4 0 -17 -21 -29 -46 -13 -25 -33 -57 -46 -71 -13 -14 -24 -31 -24 -37 0
-5 -9 -19 -19 -31 -10 -11 -35 -37 -54 -57 -19 -21 -32 -38 -29 -38 3 0 -17
-22 -44 -50 -27 -27 -54 -50 -61 -50 -6 0 -21 -13 -33 -30 -12 -16 -28 -30
-35 -30 -8 0 -20 -8 -27 -19 -7 -10 -40 -31 -73 -46 -33 -16 -65 -34 -72 -40
-6 -7 -37 -20 -70 -30 -32 -10 -60 -21 -63 -25 -9 -13 -135 -30 -223 -30 -99
0 -237 18 -237 31 0 5 -6 9 -14 9 -20 0 -139 39 -146 48 -3 5 -30 18 -60 31
-30 13 -59 28 -65 34 -5 7 -22 18 -37 26 -15 8 -36 21 -45 30 -10 9 -35 29
-56 45 -56 43 -107 91 -107 102 0 5 -12 21 -27 35 -41 38 -70 75 -100 128 -15
25 -30 48 -33 51 -4 3 -17 28 -30 55 -13 28 -27 52 -31 55 -4 3 -18 32 -30 65
-12 33 -25 62 -29 65 -4 3 -17 39 -30 80 -13 41 -26 77 -30 80 -7 6 -34 115
-51 215 -7 39 -16 72 -20 75 -33 24 -62 658 -40 900 16 180 33 310 42 310 4 0
10 21 14 48 10 71 46 216 54 222 4 3 19 44 32 93 13 48 27 87 30 87 4 0 17 29
29 65 12 36 25 65 29 65 5 0 19 27 32 60 13 33 27 60 32 60 4 0 15 15 23 33
18 41 33 65 62 96 12 14 22 31 22 37 0 7 11 19 25 28 14 9 25 21 25 27 0 10
119 129 186 186 29 25 74 52 107 64 15 6 27 15 27 19 0 5 10 12 23 16 12 3 50
19 84 35 61 28 180 59 229 59 13 0 24 5 24 10 0 6 50 10 130 10 72 0 130 -4
130 -9z"/>
<path id="17"  d="M10813 5277 c-29 -5 -53 -14 -53 -18 0 -5 -7 -9 -16 -9 -23 0 -146
-51 -162 -67 -8 -7 -23 -18 -35 -24 -12 -7 -29 -18 -37 -25 -34 -31 -78 -64
-86 -64 -5 -1 -29 -26 -54 -56 -25 -31 -62 -74 -82 -97 -21 -23 -38 -47 -38
-54 0 -7 -6 -16 -13 -20 -8 -4 -24 -28 -38 -53 -13 -25 -31 -53 -40 -63 -9
-10 -25 -39 -35 -65 -9 -26 -21 -49 -25 -52 -10 -7 -49 -98 -49 -115 0 -7 -5
-15 -10 -17 -6 -2 -20 -37 -31 -78 -12 -41 -25 -77 -29 -80 -8 -6 -34 -111
-45 -180 -4 -25 -11 -47 -15 -50 -4 -3 -16 -68 -26 -145 -27 -196 -27 -694 -1
-870 10 -66 22 -122 26 -125 4 -3 17 -52 29 -110 13 -58 27 -106 32 -108 6 -2
10 -9 10 -15 0 -21 44 -152 55 -163 6 -6 19 -37 30 -68 11 -31 23 -56 27 -56
5 0 19 -25 32 -55 14 -30 34 -64 45 -77 12 -12 21 -27 21 -33 0 -6 13 -26 30
-45 42 -50 67 -81 93 -115 25 -34 107 -115 116 -115 3 0 24 -16 45 -35 22 -19
52 -39 68 -46 15 -6 28 -15 28 -20 0 -9 85 -49 106 -49 7 0 19 -6 26 -14 24
-24 151 -46 265 -46 104 0 273 25 273 40 0 4 28 18 63 30 34 12 73 33 86 46
13 13 29 24 35 24 6 0 29 16 51 35 22 19 43 35 46 35 10 0 139 133 139 143 0
5 11 18 25 29 14 11 25 26 25 33 0 8 9 19 20 25 11 6 22 21 26 35 3 13 15 31
25 41 19 17 33 44 59 112 7 17 16 32 20 32 3 0 17 31 30 70 13 39 26 70 30 70
3 0 17 46 30 103 13 56 27 107 31 112 15 20 49 290 56 433 3 78 10 142 15 142
4 0 8 50 8 110 0 61 -4 110 -9 110 -4 0 -11 71 -14 158 -6 155 -41 452 -56
462 -4 3 -18 55 -30 115 -13 61 -27 112 -31 115 -4 3 -17 38 -29 78 -13 39
-26 72 -29 72 -4 0 -18 29 -31 65 -12 36 -28 71 -35 78 -7 7 -22 33 -35 60
-12 26 -26 47 -30 47 -4 0 -15 16 -24 35 -9 20 -24 38 -32 41 -8 4 -15 11 -15
16 0 32 -159 188 -191 188 -6 0 -8 4 -5 9 3 5 -10 16 -29 25 -19 9 -41 23 -49
32 -17 16 -141 64 -168 64 -9 0 -18 4 -20 9 -9 27 -284 40 -395 18z m207 -467
c0 -5 11 -10 25 -10 29 0 147 -38 155 -50 3 -4 23 -17 46 -28 36 -18 164 -137
164 -152 0 -4 14 -22 30 -40 17 -18 30 -39 30 -46 0 -7 8 -20 17 -30 10 -9 25
-40 35 -68 10 -28 22 -52 27 -54 5 -2 21 -49 37 -105 15 -56 31 -104 35 -107
9 -6 49 -241 49 -285 0 -19 6 -35 13 -37 16 -6 18 -515 1 -526 -6 -4 -14 -32
-18 -62 -12 -92 -37 -205 -47 -208 -5 -2 -9 -11 -9 -20 0 -23 -49 -175 -59
-182 -4 -3 -18 -30 -30 -60 -13 -30 -27 -57 -31 -60 -3 -3 -17 -25 -31 -49
-13 -24 -37 -58 -52 -75 -105 -115 -112 -122 -164 -145 -13 -6 -23 -14 -23
-19 0 -9 -97 -52 -117 -52 -6 0 -13 -4 -15 -10 -4 -12 -97 -30 -159 -30 -63 0
-149 19 -167 36 -7 8 -20 14 -29 14 -16 0 -64 25 -96 50 -52 41 -186 183 -197
210 -7 16 -16 30 -20 30 -4 0 -18 24 -30 53 -12 28 -26 54 -31 57 -8 6 -59
173 -59 195 0 7 -4 15 -9 17 -5 2 -14 48 -19 102 -8 79 -14 103 -29 116 -10 8
-30 36 -43 63 -13 26 -27 47 -30 47 -9 0 -50 97 -50 116 0 7 -7 19 -15 28 -23
22 -45 146 -45 249 0 86 22 214 38 227 4 3 17 26 29 51 13 25 33 58 45 72 64
77 113 127 122 127 6 0 17 9 23 19 7 11 25 26 40 33 19 9 33 27 43 58 8 25 17
47 20 50 3 3 16 37 30 77 13 40 26 70 28 68 3 -2 16 19 31 48 34 68 104 148
196 225 29 24 134 62 172 62 12 0 23 5 23 10 0 6 27 10 60 10 33 0 60 -4 60
-10z"/>
<path id="18" d="M10860 4714 c-47 -9 -90 -26 -90 -34 0 -5 -10 -12 -22 -15 -13 -4
-47 -33 -76 -64 -76 -80 -78 -82 -97 -121 -10 -19 -21 -37 -25 -40 -10 -7 -63
-150 -58 -156 3 -2 15 4 26 15 26 22 122 42 198 42 56 0 164 -26 164 -39 0 -4
15 -16 33 -27 36 -21 82 -67 106 -106 9 -14 26 -41 39 -60 12 -19 27 -51 33
-71 5 -21 13 -38 18 -38 15 0 61 -227 61 -302 0 -21 5 -38 10 -38 12 0 14
-147 1 -154 -4 -3 -11 -36 -14 -74 -7 -89 -46 -254 -58 -246 -5 3 -9 -1 -9 -9
0 -20 -39 -107 -48 -107 -4 0 -16 -15 -27 -32 -24 -42 -78 -94 -118 -115 -18
-9 -34 -20 -37 -24 -3 -5 -34 -16 -69 -24 -57 -15 -69 -15 -139 -1 -41 8 -78
19 -81 24 -3 5 -34 22 -68 38 -34 15 -60 30 -58 33 3 2 -4 11 -16 19 -11 8
-23 11 -27 7 -4 -4 -1 -10 6 -12 6 -3 12 -12 12 -21 0 -25 42 -163 48 -157 3
2 18 -23 34 -57 16 -34 38 -68 48 -75 11 -8 20 -19 20 -25 0 -12 74 -88 86
-88 5 0 20 -10 34 -23 49 -45 99 -61 205 -65 110 -4 192 15 236 57 13 12 28
21 35 21 7 0 21 11 32 25 11 14 25 25 31 25 7 0 21 16 32 35 12 19 25 35 29
35 5 0 19 18 31 40 13 22 26 40 29 40 4 0 19 32 34 70 15 39 31 70 35 70 4 0
18 42 31 93 13 50 27 94 30 97 11 8 40 214 47 331 3 60 10 109 15 109 4 0 8
45 8 100 0 55 -4 100 -9 100 -4 0 -11 46 -14 103 -7 127 -36 319 -48 311 -5
-3 -9 2 -9 12 0 24 -41 158 -50 164 -4 3 -22 34 -39 70 -17 36 -41 81 -54 100
-31 49 -135 151 -177 174 -19 10 -37 22 -40 26 -17 24 -192 47 -260 34z"/>
<path id="19" d="M10581 4270 c-30 -11 -57 -23 -60 -28 -3 -4 -29 -19 -58 -34 -58 -29
-65 -35 -168 -137 -41 -40 -85 -92 -97 -115 -13 -22 -26 -43 -30 -47 -18 -12
-38 -109 -38 -179 0 -75 23 -189 40 -200 5 -3 18 -28 30 -55 12 -27 25 -52 29
-55 5 -3 17 -21 28 -40 10 -19 30 -48 43 -63 52 -60 179 -187 187 -187 4 0 20
-11 34 -24 46 -42 139 -69 221 -64 40 3 88 12 105 22 48 24 95 76 118 126 11
25 23 48 28 51 17 12 38 118 44 221 3 59 10 105 14 102 5 -3 9 13 9 35 0 22
-4 43 -10 46 -5 3 -10 36 -10 72 0 67 -35 264 -49 273 -5 3 -18 34 -31 70 -12
36 -31 73 -41 83 -11 10 -19 22 -19 27 0 19 -76 88 -114 104 -51 21 -139 20
-205 -4z"/>
<path  fill="black" d="M4370 4813 c0 -23 -52 -207 -60 -213 -4 -3 -17 -35 -29 -72 -13 -38
-26 -68 -30 -68 -5 0 -16 -21 -26 -47 -10 -27 -23 -52 -29 -58 -7 -5 -26 -34
-42 -64 -28 -54 -40 -69 -110 -141 -19 -20 -34 -40 -34 -46 0 -5 -13 -18 -30
-27 -16 -10 -30 -22 -30 -26 0 -5 -19 -21 -42 -37 -23 -16 -53 -39 -66 -51
-14 -13 -31 -23 -38 -23 -7 0 -21 -8 -31 -19 -10 -10 -40 -26 -68 -37 -27 -10
-52 -21 -55 -26 -10 -13 -213 -58 -262 -58 -51 0 -66 -17 -20 -22 49 -5 152 3
146 13 -3 5 7 9 21 9 53 0 172 34 253 71 45 22 87 39 93 39 6 0 8 4 5 9 -3 5
9 14 27 21 37 15 45 21 153 126 44 44 89 93 99 109 10 17 24 34 32 38 7 4 13
14 13 21 0 7 10 24 23 38 13 14 34 49 46 79 13 30 26 56 30 59 21 17 33 67 47
193 8 78 19 150 24 159 13 23 13 68 0 68 -5 0 -10 -8 -10 -17z"/>
<path  fill="black" d="M4280 4240 c-13 -27 -26 -50 -29 -50 -3 0 -12 -12 -19 -27 -30 -68
-187 -228 -271 -276 -20 -12 -38 -24 -41 -28 -5 -8 -115 -59 -125 -59 -4 0
-13 -6 -20 -14 -7 -7 -50 -21 -94 -31 -45 -11 -81 -22 -81 -26 0 -4 -15 -10
-32 -12 -18 -3 9 -3 61 -1 52 2 97 8 100 12 3 5 24 12 46 16 55 10 135 36 135
45 0 3 23 15 51 26 28 11 58 27 68 35 31 28 80 60 91 60 5 0 10 4 10 8 0 5 25
33 55 63 30 30 55 58 55 62 0 4 12 18 26 31 21 17 28 34 32 74 2 29 9 55 13
58 11 7 12 84 1 84 -5 0 -19 -22 -32 -50z"/>
<path fill="black" d="M4290 3990 c0 -22 -151 -176 -182 -186 -10 -3 -18 -10 -18 -15 0 -5
-10 -13 -22 -18 -13 -5 -41 -21 -63 -36 -22 -15 -56 -34 -75 -41 -27 -10 -30
-13 -12 -13 12 -1 22 -5 22 -11 0 -5 12 -10 26 -10 14 0 23 4 19 10 -4 6 6 10
23 10 35 0 162 38 162 48 0 4 21 16 46 26 26 11 56 30 67 43 11 12 25 23 31
23 15 0 118 109 112 119 -3 4 -26 14 -50 21 -25 7 -47 18 -50 26 -6 16 -36 19
-36 4z"/>
<path fill="black" d="M4479 3883 c-21 -42 -157 -171 -201 -191 -45 -21 -48 -32 -8 -32 17
0 30 5 30 10 0 6 9 10 19 10 28 0 151 40 151 48 0 5 17 16 38 25 36 16 132
100 132 116 0 6 -116 41 -137 41 -5 0 -16 -12 -24 -27z"/>
<path fill="black" d="M4694 3827 c-11 -12 -23 -30 -27 -39 -3 -9 -19 -25 -34 -35 -15 -9
-32 -23 -36 -30 -4 -7 -23 -20 -42 -29 -36 -17 -46 -34 -20 -34 8 0 15 3 15 8
0 4 37 16 82 26 47 11 88 27 97 38 8 10 20 18 26 18 11 0 75 55 75 64 0 3 -19
10 -42 16 -24 6 -50 13 -58 15 -8 3 -24 -6 -36 -18z"/>
<path fill="black" d="M12775 1660 c-10 -22 -21 -40 -25 -40 -5 0 -15 -17 -23 -39 -9 -21
-22 -45 -30 -52 -7 -8 -26 -36 -42 -64 -16 -27 -37 -56 -47 -63 -10 -7 -18
-19 -18 -27 0 -7 -13 -23 -30 -35 -16 -12 -30 -26 -30 -31 0 -13 -152 -169
-163 -169 -5 0 -23 -14 -40 -30 -63 -60 -74 -70 -84 -70 -6 0 -21 -10 -35 -22
-13 -13 -44 -34 -69 -47 -24 -13 -51 -30 -59 -37 -9 -7 -38 -23 -65 -35 -28
-12 -52 -26 -55 -30 -3 -5 -37 -20 -75 -34 -38 -13 -71 -28 -74 -33 -3 -4 -42
-19 -86 -32 -44 -12 -82 -26 -85 -29 -5 -7 -122 -37 -185 -47 -22 -4 -43 -11
-46 -16 -3 -5 -80 -18 -170 -29 -141 -18 -196 -20 -399 -15 -129 3 -253 8
-275 11 -31 3 -26 1 20 -12 33 -9 62 -19 65 -23 3 -4 55 -18 115 -31 61 -12
112 -26 115 -30 3 -4 76 -17 162 -30 l158 -23 132 23 c73 13 135 26 138 30 3
4 43 16 89 26 46 11 89 25 95 30 6 6 52 23 101 39 50 16 92 32 95 36 3 4 29
15 58 24 29 10 58 23 65 30 6 6 37 22 67 35 30 13 57 27 60 31 3 4 29 20 58
35 28 15 52 30 52 34 0 4 12 14 28 22 36 19 121 82 152 113 14 14 29 26 33 26
10 0 227 218 227 229 0 5 10 18 23 28 25 21 67 78 67 92 0 5 7 11 15 15 8 3
23 21 32 41 9 19 20 35 25 35 4 0 14 15 21 33 8 18 21 39 29 47 8 8 22 34 31
58 13 33 14 44 5 47 -7 2 -31 9 -53 16 -22 6 -43 16 -46 21 -3 5 -14 -9 -24
-32z"/>
<path  fill="black" d="M12953 1600 c-3 -11 -9 -20 -13 -20 -4 0 -12 -18 -19 -39 -14 -48
-50 -130 -51 -116 0 6 -13 -11 -28 -36 -43 -73 -82 -129 -90 -129 -4 0 -16
-14 -26 -31 -10 -16 -33 -44 -52 -61 -19 -17 -34 -35 -34 -40 0 -11 -78 -88
-89 -88 -5 0 -18 -10 -28 -22 -16 -21 -31 -34 -116 -101 -15 -12 -38 -31 -50
-43 -12 -12 -36 -29 -54 -38 -18 -9 -36 -22 -40 -29 -4 -7 -29 -24 -55 -37
-27 -14 -48 -28 -48 -31 0 -3 -24 -16 -52 -30 -29 -13 -61 -32 -72 -41 -10 -9
-43 -25 -72 -34 -30 -9 -54 -20 -54 -24 0 -4 -28 -18 -62 -31 -35 -13 -68 -28
-74 -34 -6 -6 -46 -20 -88 -30 -42 -10 -76 -22 -76 -26 0 -4 -10 -10 -22 -13
-13 -2 37 -3 110 -2 74 2 137 7 140 12 4 5 45 20 92 34 47 13 87 27 90 30 3 4
35 17 73 29 37 13 67 27 67 32 0 5 6 9 13 9 15 0 140 52 147 61 3 3 25 17 50
29 25 12 47 25 50 29 8 10 63 42 83 48 9 3 17 9 17 14 0 5 13 15 29 23 29 15
62 41 111 86 15 14 43 36 61 49 19 14 50 43 69 65 19 21 47 52 63 68 15 16 27
33 27 37 0 4 9 16 19 25 11 10 22 28 25 42 4 13 10 24 15 24 5 0 20 21 33 48
13 26 28 52 33 58 6 6 20 39 30 73 11 33 23 61 26 61 11 0 32 121 23 129 -9 8
-93 31 -114 31 -7 0 -14 -9 -17 -20z"/>
<path  fill="black" d="M13145 1500 c-4 -33 -11 -60 -16 -60 -5 0 -9 -6 -9 -13 0 -23 -42
-147 -54 -160 -6 -7 -22 -34 -35 -62 -14 -27 -33 -56 -43 -63 -10 -7 -18 -18
-18 -24 0 -23 -193 -238 -213 -238 -5 0 -20 -14 -34 -30 -13 -17 -29 -30 -35
-30 -5 0 -23 -12 -38 -27 -48 -47 -67 -61 -112 -85 -24 -13 -45 -27 -46 -31
-2 -4 -27 -19 -55 -34 -29 -14 -54 -29 -57 -32 -3 -4 -31 -18 -63 -30 -61 -25
-80 -41 -48 -41 10 0 21 4 23 8 2 4 49 18 106 29 56 12 109 29 118 37 8 9 38
26 65 37 27 12 49 25 49 29 0 3 23 17 50 30 28 13 50 27 50 31 0 4 17 16 38
27 22 11 51 32 67 46 15 14 34 26 41 26 8 0 21 11 30 25 9 14 22 25 30 25 7 0
23 13 34 28 11 15 38 39 60 52 22 14 43 32 45 41 3 9 20 32 38 50 18 19 42 49
52 67 11 17 23 32 27 32 4 0 17 18 28 40 11 22 24 40 28 40 4 0 14 17 21 38 8
20 25 60 38 87 27 56 53 124 53 141 0 10 -162 54 -197 54 -6 0 -14 -25 -18
-60z"/>
<path fill="black" d="M13400 1444 c-6 -30 -14 -54 -18 -54 -4 0 -18 -34 -31 -75 -14 -41
-29 -75 -33 -75 -5 0 -19 -25 -33 -55 -13 -30 -29 -55 -34 -55 -6 0 -11 -5
-11 -10 0 -15 -41 -72 -62 -88 -10 -7 -18 -18 -18 -24 0 -6 -17 -28 -37 -49
-96 -96 -117 -122 -98 -114 11 4 28 10 38 12 9 3 17 9 17 14 0 5 20 18 45 30
25 12 45 25 45 29 0 4 18 17 40 29 43 22 160 138 160 157 0 7 6 14 14 17 8 3
22 24 32 46 10 23 21 41 25 41 4 0 17 30 30 68 12 37 25 69 29 72 4 3 11 29
17 58 10 52 10 52 -16 58 -14 3 -40 10 -58 14 l-32 8 -11 -54z"/>
<path fill="black" d="M13556 1413 c-9 -56 -45 -186 -55 -193 -4 -3 -16 -24 -28 -47 l-20
-42 28 22 c15 12 28 25 28 29 1 8 41 70 66 102 8 11 23 40 32 65 9 25 23 52
30 59 17 18 17 42 -1 42 -7 0 -27 3 -43 6 -30 6 -31 5 -37 -43z"/>
<path id="20" d="M15007 1204 c4 -10 20 -30 37 -44 16 -14 38 -33 47 -44 10 -10 29
-26 42 -35 13 -9 44 -29 68 -45 24 -16 46 -32 49 -36 3 -4 32 -19 65 -35 33
-15 62 -31 65 -35 3 -3 31 -17 63 -30 31 -13 57 -27 57 -30 0 -4 39 -20 88
-36 48 -16 89 -32 92 -35 3 -3 46 -17 95 -29 50 -13 92 -27 95 -30 6 -9 225
-50 262 -50 14 0 28 -4 30 -9 5 -13 217 -31 376 -31 142 0 161 4 202 40 10 8
34 29 56 45 63 50 119 111 146 162 11 19 28 44 39 56 10 11 19 28 19 37 0 9 8
25 19 36 10 11 22 30 25 42 l7 22 -356 0 c-369 0 -846 16 -855 29 -3 4 -75 13
-160 20 -288 22 -495 43 -500 51 -3 4 -30 10 -61 13 -30 4 -70 10 -87 13 -29
6 -32 4 -25 -12z"/>
<path  fill="black" d="M17680 1068 c-7 -24 -15 -45 -19 -48 -10 -7 -50 -104 -45 -109 2 -2
15 8 28 22 14 15 29 27 34 27 15 0 70 60 86 95 9 19 21 35 26 35 6 0 10 5 10
10 0 6 -24 10 -53 10 l-54 0 -13 -42z"/>
<path  fill="black" d="M17373 1092 c-69 -3 -73 -4 -73 -27 0 -13 -6 -30 -12 -37 -7 -7 -24
-36 -38 -63 -14 -28 -33 -56 -42 -63 -10 -7 -18 -18 -18 -25 0 -6 -11 -23 -25
-37 -104 -107 -142 -151 -127 -146 9 2 48 10 86 17 37 7 70 19 73 26 3 7 11
13 17 13 7 0 26 15 42 33 17 17 43 43 60 57 16 14 42 50 58 80 16 30 32 57 36
60 8 6 44 110 39 113 -2 1 -37 1 -76 -1z"/>
<path fill="black" d="M17509 1043 c-7 -32 -21 -65 -32 -76 -10 -10 -22 -28 -27 -40 -16
-35 -32 -56 -84 -108 -28 -27 -45 -49 -39 -49 20 0 73 22 73 31 0 5 6 9 14 9
29 0 106 40 106 55 0 8 4 15 8 15 9 0 62 116 62 133 0 5 7 15 15 23 8 9 15 26
15 40 0 22 -4 24 -48 24 l-49 0 -14 -57z"/>
<path fill="black" d="M17134 1073 c-18 -55 -29 -80 -44 -98 -9 -11 -23 -31 -32 -45 -31
-50 -153 -180 -229 -244 l-34 -28 62 6 c35 3 71 13 80 21 77 66 182 177 198
209 11 22 28 48 37 57 9 10 22 36 29 58 7 23 15 41 19 41 4 0 10 9 13 20 5 18
0 20 -44 20 -38 0 -51 -4 -55 -17z"/>
</g>
          `,
        },
      ],
    ],
    listColorQuestion: [
      {
        color: 'red',
      },
      {
        color: 'Pink',
      },
      {
        color: 'yellow',
      },
      {
        color: 'blue',
      },
      {
        color: 'green',
      },
    ],
  },
}

export default json
