import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB1-U6-P44-E1",
    audio: "https://cdn.sachso.edu.vn/mn3/Audios/Track 11.mp3",
    video: "",
    component: UI,
    recorder: true,
    WidthContent: "80%",

    isAllowSubmit: false,
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page44/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page44/E1/3.jpg",
          audioMainUrl: "https://cdn.sachso.edu.vn/mn3/Audios/Track 11.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E1/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E1/5.jpg",
        },
      ],
    ],
  },
};

export default json;
