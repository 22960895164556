import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "SB1-U5-P41-E1",
    audio: "",
    video: "",
    component: D1,
    recorder: false,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E1/1.jpg",
        },
      ],
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E1/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E1/4.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "SB1-U5-P41-E2",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E2/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E2/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh1.e3.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh2.e3.mp3",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E2/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh3.e3.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E2/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh4.e3.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 5",
    id: "SB1-U5-P41-E3",
    audio:
      "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/audio.e1.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E3/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E3/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/tieude.e3.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E3/3.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E3/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh1.e3.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E3/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh2.e3.mp3",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E3/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh3.e3.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/E3/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn3/img/FriendsPlus/Page41/Audio/anh4.e3.mp3",
        },
      ],
    ],
  },
};

export default json;
